import { useSelector } from 'reduxx';

export default function(serialNum: string) {
  // Grab access level data
  const accessLevelData = useSelector((state) => state.app.accessLevelData);

  return (accessLevelData[serialNum] || {
    accessLevel: 0,
    accessLevelStr: 'View Only',
  });
}
