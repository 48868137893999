import { createTheme } from '@material-ui/core/styles';
import { red, grey } from '@material-ui/core/colors';

export default createTheme({
  palette: {
    primary: {
      main: grey[700],
    },
    secondary: red,
    type: 'light',
  },
  typography: {
    fontFamily: '"Roboto", "Calibri", "Arial", sans-serif',
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiContainer: {
      maxWidthLg: {
        '@media (min-width: 1280px)': {
          maxWidth: 'inherit',
        },
      },
      root: {
        '@media (min-width: 960px)': {
          padding: 24,
        },
        '@media (min-width: 600px)': {
          padding: 24,
        },
      },
    },
    MuiGrid: {
      item: {
        padding: 'inherit',
      },
    },
    MuiIconButton: {
      root: {
        padding: 'inherit',
        cursor: 'pointer',
      },
    },
    MuiTabs: {
      scrollButtons: {
        width: 25,
      },
    },
    MuiTab: {
      fullWidth: {
        maxWidth: '30%',
      },
    },
  },
});
