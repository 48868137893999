import axios_, { AxiosResponse } from 'axios';

import { auth } from 'utils/firebase';
import { NodeType } from 'utils/serialUtils';

import settings from 'settings';
import { SiteStatus } from 'types';

const apiUrl = settings.devBuild
  ? 'https://api-srv1.feedlink.live/devEZData'
  : 'https://api-srv1.feedlink.live/EZData';
const axios = axios_.create({ baseURL: apiUrl, withCredentials: true });

export interface SiteInfo {
  customerName: string;
  licenseExpirationDate: string;
  location: string;
  programVersion: number;
  site: string;
  systemType: number;
  timestamp: string;
}

export interface SiteInfoM {
  customerName: string;
  licenseExpirationDate: string;
  location: string;
  programVersion: number;
  site: string;
  systemType: number;
  timestamp: moment.Moment;
}

export interface ValidateAccessCodeResponse {
  accessLevel: number;
  accessLevelStr: string;
}

export interface GetNodesResponse {
  values: GetNodesResponseItem[];
}

export interface GetNodesResponseItem {
  id: number;
  installed: boolean;
  installedCode: number;
  name: string;
  version: string;
}

export interface ControllerConfigResponseItem {
  groups?: string[];
  nodeType: NodeType;
  title: string;
  values: (ControllerConfigCell[] | ControllerConfigRow)[];
}

export interface ControllerConfigCell {
  groups?: string[];
  value: any;

  code?: number;
  datatype?: [string, string?];
  units?: string;
  precision?: number;

  settable?: boolean;
}

export interface ControllerConfigRow {
  nodeID: number;
  values: ControllerConfigCell[];
}

export interface ControllerConfigCard {
  title: string;
  nodeType: NodeType;
  groups?: string[];
  headers: ControllerConfigCell[];
  rows: ControllerConfigRow[];
}

export async function status(serialNums: string[]): Promise<AxiosResponse<SiteStatus[]>> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();

  return axios.post('/status', serialNums, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function setValue(serialNum: string, code: number, value: string,
  accessCode?: string): Promise<AxiosResponse> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();

  const items = [{
    code,
    value,
  }];

  return axios.put(`/${serialNum}/setValue`, {
    accessCode,
    items,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function siteInfo(serialNum: string): Promise<AxiosResponse<SiteInfo>> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();

  return axios.post(`/${serialNum}/siteInfo`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function validateAccessCode(serialNum: string, accessCode: string):
  Promise<AxiosResponse<ValidateAccessCodeResponse>> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();

  return axios.post(`/${serialNum}/validateAccessCode`, {
    accessCode,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getInstalledNodes(serialNum: string, includeNodeTypes: NodeType[]):
  Promise<AxiosResponse<GetNodesResponse>> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();

  return axios.put(`/${serialNum}/nodes`, {
    includeNodeTypes,
  },
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getControllerConfig(serialNum: string): Promise<AxiosResponse<ControllerConfigResponseItem[]>> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();

  return axios.post(`/${serialNum}/configuration`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
}
