import React from 'react';
import queryString from 'query-string';

import ResetPassword from 'components/resetPassword';
import VerifyEmail from 'components/verifyEmail';
import RecoverEmail from 'components/recoverEmail';
import RevertSecondFactorEnroll from 'components/revertSecondFactorEnroll';

// http://localhost:3000/action?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy
//
// mode - The user management action to be completed
// oobCode - A one-time code, used to identify and verify a request
// apiKey - Your Firebase project's API key, provided for convenience

function ActionContainer(props: any) {
  // https://stackoverflow.com/questions/43216569/how-to-get-query-parameters-in-react-router-v4
  const parsed = queryString.parse(props.location.search);

  // Get the action to complete.
  const { mode } = parsed;

  // Get the one-time code from the query parameter.
  const actionCode = parsed.oobCode as string;

  // Handle the user management action.
  switch (mode) {
    case 'revertSecondFactorAddition':
      // Display reset MFA enroll handler and UI.
      return <RevertSecondFactorEnroll actionCode={actionCode} />;

    case 'recoverEmail':
      // Display email recovery handler and UI.
      return <RecoverEmail actionCode={actionCode} />;

    case 'resetPassword':
      // Display reset password handler and UI.
      return <ResetPassword actionCode={actionCode} />;

    case 'verifyEmail':
      // Display email verification handler and UI.
      return <VerifyEmail actionCode={actionCode} />;

    default:
      // Error: invalid mode.
      return (
        <div className="Action">
          <h1>Error encountered</h1>
          <p>The selected page mode is invalid.</p>
        </div>
      );
  }
}

export default ActionContainer;
