import React from 'react';
import { useSelector, useDispatch } from 'reduxx';
import { useHistory } from 'react-router';

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { TextField } from 'final-form-material-ui';

import {
  Dialog, DialogContent, DialogActions, DialogTitle, Button, Grid, makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { newZone, closeDialog } from 'reduxx/actions/app';

import styles from 'styles';

const useStyles = makeStyles(styles);

function NewZone() {
  const classes = useStyles();
  const history = useHistory();

  const data = useSelector((state) => state.app.newZoneDialog);
  const { open, siteID } = data;
  const siteTree = useSelector((state) => state.app.siteTree);
  const dispatch = useDispatch();

  function onClose() {
    dispatch(closeDialog({ dialog: 'newZoneDialog' }));
  }

  async function onSubmit(values: any) {
    try {
      const newZone_ = await dispatch(newZone({
        parent: siteID,
        order: siteTree.filter((site) => site.parent === siteID).length,
        nickname: values.nickname,
      }));

      history.push(`/app/site/${newZone_.id}`);
      onClose();
      return undefined;
    } catch (error: any) {
      return {
        [FORM_ERROR]: error?.response?.data?.message || 'Something went wrong. Please try again later',
      };
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      scroll="body"
    >
      <DialogTitle>New Zone</DialogTitle>
      <Form
        onSubmit={onSubmit}
        render={({ submitError, handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            {/* DialogContent has additional padding if first child, trick it by putting fake div */}
            <div />
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    fullWidth
                    required
                    autoFocus
                    name="nickname"
                    component={TextField}
                    type="text"
                    label="Zone Name"
                  />
                </Grid>
                {submitError && (
                  <Grid item xs={12}>
                    <Alert className={classes.slimAlert} severity="error">
                      {submitError}
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} disabled={submitting}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || pristine}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}

export default NewZone;
