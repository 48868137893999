import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'reduxx';
import { getInstalledNodes } from 'reduxx/actions/app';

import { makeStyles } from '@material-ui/core';

import NetworkConfigCard from 'components/networkConfigCard';

import { NodeType, nodeIDMap } from 'utils/serialUtils';

import styles from 'styles';

const useStyles = makeStyles((theme) => ({
  ...styles,
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}));

type NetworkConfigProps = {
  siteID: string;
};

function NetworkConfig(props: NetworkConfigProps) {
  const classes = useStyles();

  const { siteID } = props;

  const nodes = useSelector((state) => state.app.installedNodes);
  const siteInfo = useSelector((state) => state.app.siteInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInstalledNodes(siteID, [NodeType.FBW, NodeType.SF, NodeType.PLM, NodeType.TW, NodeType.SG]));
  }, [dispatch, siteID]);

  const networkConfig = useMemo(() => {
    // Create editable copy of nodes
    const nodesCopy = [...nodes];

    const fbwMinIndex = nodesCopy.findIndex((node) => node.id === nodeIDMap[NodeType.FBW].minID);
    const sfMinIndex = nodesCopy.findIndex((node) => node.id === nodeIDMap[NodeType.SF].minID);
    const twMinIndex = nodesCopy.findIndex((node) => node.id === nodeIDMap[NodeType.TW].minID);

    const fbwArr = nodesCopy.slice(fbwMinIndex, fbwMinIndex + nodeIDMap[NodeType.FBW].numNodes);
    const sfArr = nodesCopy.slice(sfMinIndex, sfMinIndex + nodeIDMap[NodeType.SF].numNodes);
    const twArr = nodesCopy.slice(twMinIndex, twMinIndex + nodeIDMap[NodeType.TW].numNodes);

    // Create cards
    const fbwCard = {
      header: 'FBW Display Units',
      nodeType: NodeType.FBW,
      values: fbwArr,
    };

    const sfCard = {
      header: 'SmartFlex Controllers',
      nodeType: NodeType.SF,
      values: sfArr,
    };

    const twCard = {
      header: 'Temperature and Water Meters',
      nodeType: NodeType.TW,
      values: twArr,
    };

    // Check if Network Master supports Pit Level Monitor
    if (siteInfo.programVersion && siteInfo.programVersion >= 310) {
      const plmMinIndex = nodesCopy.findIndex((node) => node.id === nodeIDMap[NodeType.PLM].minID);
      const plmArr = nodesCopy.slice(plmMinIndex, plmMinIndex + nodeIDMap[NodeType.PLM].numNodes);

      const plmCard = {
        header: 'Pit Level Monitors',
        nodeType: NodeType.PLM,
        values: plmArr,
      };

      const sgMinIndex = nodesCopy.findIndex((node) => node.id === nodeIDMap[NodeType.SG].minID);
      const sgArr = nodesCopy.slice(sgMinIndex, sgMinIndex + nodeIDMap[NodeType.SG].numNodes);

      const sgCard = {
        header: 'Slide Gate Controllers',
        nodeType: NodeType.SG,
        values: sgArr,
      };

      return [fbwCard, sfCard, plmCard, twCard, sgCard];
    } else {
      return [fbwCard, sfCard, twCard];
    }
  }, [nodes, siteInfo]);

  return (
    <div className={classes.root}>
      {networkConfig.map(
        (card) => card.values.length > 0 && (
        <NetworkConfigCard
          key={card.header}
          header={card.header}
          nodeType={card.nodeType}
          values={card.values}
          siteID={siteID}
        />
        ),
      )}
    </div>
  );
}

export default NetworkConfig;
