// Utility functions that create some basic margin & header spacings
// This is meant for easy uses of classes
// Anything more complicated should have a custom class created
//
// Naming scheme matches Bootstrap
//
//    m               Margin
//    p               Padding
//
//    t               Top
//    b               Bottom
//    l               Left
//    r               Right
//    x               X-axis
//    y               Y-axis
//
//    0-5, 0p5, 1p5   Amount of spacing to apply, 0p5 is 0.5. Increments of 8px
function m(a: number) {
  return {
    margin: a * 8,
  };
}

function mt(a: number) {
  return {
    marginTop: a * 8,
  };
}

function mb(a: number) {
  return {
    marginBottom: a * 8,
  };
}

function ml(a: number) {
  return {
    marginLeft: a * 8,
  };
}

function mr(a: number) {
  return {
    marginRight: a * 8,
  };
}

function mx(a: number) {
  return {
    marginLeft: a * 8,
    marginRight: a * 8,
  };
}

function my(a: number) {
  return {
    marginTop: a * 8,
    marginBottom: a * 8,
  };
}

function p(a: number) {
  return {
    padding: a * 8,
  };
}

function pt(a: number) {
  return {
    paddingTop: a * 8,
  };
}

function pb(a: number) {
  return {
    paddingBottom: a * 8,
  };
}

function pl(a: number) {
  return {
    paddingLeft: a * 8,
  };
}

function pr(a: number) {
  return {
    paddingRight: a * 8,
  };
}

function px(a: number) {
  return {
    paddingLeft: a * 8,
    paddingRight: a * 8,
  };
}

function py(a: number) {
  return {
    paddingTop: a * 8,
    paddingBottom: a * 8,
  };
}

const spacings = [0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0, 5.5, 6.0, 6.5, 7.0];

// Have to hardcode the valid keys so that Typescript knows what is valid
type m0 = 'm0' | 'mt0' | 'mb0' | 'ml0' | 'mr0' | 'mx0' | 'my0';
type m0p5 = 'm0p5' | 'mt0p5' | 'mb0p5' | 'ml0p5' | 'mr0p5' | 'mx0p5' | 'my0p5';
type m1 = 'm1' | 'mt1' | 'mb1' | 'ml1' | 'mr1' | 'mx1' | 'my1';
type m1p5 = 'm1p5' | 'mt1p5' | 'mb1p5' | 'ml1p5' | 'mr1p5' | 'mx1p5' | 'my1p5';
type m2 = 'm2' | 'mt2' | 'mb2' | 'ml2' | 'mr2' | 'mx2' | 'my2';
type m2p5 = 'm2p5' | 'mt2p5' | 'mb2p5' | 'ml2p5' | 'mr2p5' | 'mx2p5' | 'my2p5';
type m3 = 'm3' | 'mt3' | 'mb3' | 'ml3' | 'mr3' | 'mx3' | 'my3';
type m3p5 = 'm3p5' | 'mt3p5' | 'mb3p5' | 'ml3p5' | 'mr3p5' | 'mx3p5' | 'my3p5';
type m4 = 'm4' | 'mt4' | 'mb4' | 'ml4' | 'mr4' | 'mx4' | 'my4';
type m4p5 = 'm4p5' | 'mt4p5' | 'mb4p5' | 'ml4p5' | 'mr4p5' | 'mx4p5' | 'my4p5';
type m5 = 'm5' | 'mt5' | 'mb5' | 'ml5' | 'mr5' | 'mx5' | 'my5';
type m5p5 = 'm5p5' | 'mt5p5' | 'mb5p5' | 'ml5p5' | 'mr5p5' | 'mx5p5' | 'my5p5';
type m6 = 'm6' | 'mt6' | 'mb6' | 'ml6' | 'mr6' | 'mx6' | 'my6';
type m6p5 = 'm6p5' | 'mt6p5' | 'mb6p5' | 'ml6p5' | 'mr6p5' | 'mx6p5' | 'my6p5';
type m7 = 'm7' | 'mt7' | 'mb7' | 'ml7' | 'mr7' | 'mx7' | 'my7';
type m7p5 = 'm7p5' | 'mt7p5' | 'mb7p5' | 'ml7p5' | 'mr7p5' | 'mx7p5' | 'my7p5';
type mAll = m0 | m0p5 | m1 | m1p5 | m2 | m2p5 | m3 | m3p5 | m4 | m4p5 | m5 | m5p5 | m6 | m6p5 | m7 | m7p5;
type Margins = { [key in mAll]: any; };

type p0 = 'p0' | 'pt0' | 'pb0' | 'pl0' | 'pr0' | 'px0' | 'py0';
type p0p5 = 'p0p5' | 'pt0p5' | 'pb0p5' | 'pl0p5' | 'pr0p5' | 'px0p5' | 'py0p5';
type p1 = 'p1' | 'pt1' | 'pb1' | 'pl1' | 'pr1' | 'px1' | 'py1';
type p1p5 = 'p1p5' | 'pt1p5' | 'pb1p5' | 'pl1p5' | 'pr1p5' | 'px1p5' | 'py1p5';
type p2 = 'p2' | 'pt2' | 'pb2' | 'pl2' | 'pr2' | 'px2' | 'py2';
type p2p5 = 'p2p5' | 'pt2p5' | 'pb2p5' | 'pl2p5' | 'pr2p5' | 'px2p5' | 'py2p5';
type p3 = 'p3' | 'pt3' | 'pb3' | 'pl3' | 'pr3' | 'px3' | 'py3';
type p3p5 = 'p3p5' | 'pt3p5' | 'pb3p5' | 'pl3p5' | 'pr3p5' | 'px3p5' | 'py3p5';
type p4 = 'p4' | 'pt4' | 'pb4' | 'pl4' | 'pr4' | 'px4' | 'py4';
type p4p5 = 'p4p5' | 'pt4p5' | 'pb4p5' | 'pl4p5' | 'pr4p5' | 'px4p5' | 'py4p5';
type p5 = 'p5' | 'pt5' | 'pb5' | 'pl5' | 'pr5' | 'px5' | 'py5';
type p5p5 = 'p5p5' | 'pt5p5' | 'pb5p5' | 'pl5p5' | 'pr5p5' | 'px5p5' | 'py5p5';
type p6 = 'p6' | 'pt6' | 'pb6' | 'pl6' | 'pr6' | 'px6' | 'py6';
type p6p5 = 'p6p5' | 'pt6p5' | 'pb6p5' | 'pl6p5' | 'pr6p5' | 'px6p5' | 'py6p5';
type p7 = 'p7' | 'pt7' | 'pb7' | 'pl7' | 'pr7' | 'px7' | 'py7';
type p7p5 = 'p7p5' | 'pt7p5' | 'pb7p5' | 'pl7p5' | 'pr7p5' | 'px7p5' | 'py7p5';
type pAll = p0 | p0p5 | p1 | p1p5 | p2 | p2p5 | p3 | p3p5 | p4 | p4p5 | p5 | p5p5 | p6 | p6p5 | p7 | p7p5;
type Paddings = { [key in pAll]: any; };

export function margins(): Margins {
  const ret: { [key: string]: any} = {};

  for (const spacing of spacings) {
    const suffix = spacing.toString().replace('.', 'p');
    ret[`m${suffix}`] = m(spacing);
    ret[`mt${suffix}`] = mt(spacing);
    ret[`mb${suffix}`] = mb(spacing);
    ret[`ml${suffix}`] = ml(spacing);
    ret[`mr${suffix}`] = mr(spacing);
    ret[`mx${suffix}`] = mx(spacing);
    ret[`my${suffix}`] = my(spacing);
  }

  return ret as Margins;
}

export function paddings(): Paddings {
  const ret: { [key: string]: any} = {};

  for (const spacing of spacings) {
    const suffix = spacing.toString().replace('.', 'p');
    ret[`p${suffix}`] = p(spacing);
    ret[`pt${suffix}`] = pt(spacing);
    ret[`pb${suffix}`] = pb(spacing);
    ret[`pl${suffix}`] = pl(spacing);
    ret[`pr${suffix}`] = pr(spacing);
    ret[`px${suffix}`] = px(spacing);
    ret[`py${suffix}`] = py(spacing);
  }

  return ret as Paddings;
}
