// redux
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, Middleware } from 'redux';
import rootReducer from 'reduxx/reducer';

// redux store
const middleware: Middleware[] = [thunk];
if (process.env.NODE_ENV !== 'production') {
  // collapses redux actions in console during development
  middleware.push(createLogger({ collapsed: true }));
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
