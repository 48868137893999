function removeChildren(node: HTMLElement) {
  // Note: Iterations over live collections can be slow, this is the fastest
  // we could find. The double parenthesis are used to prevent JsCompiler and
  // strict warnings.
  let child;
  // eslint-disable-next-line no-cond-assign
  while (child = node.firstChild) {
    node.removeChild(child);
  }
}

function removeNode(node: HTMLElement) {
  return node && node.parentNode ? node.parentNode.removeChild(node) : null;
}

export default { removeChildren, removeNode };
