import { reenterPassword } from 'components/reenterPasswordDialog';
import { verify2FACode } from 'components/mfaVerifyDialog';

import { ReduxDispatch } from 'reduxx/helpers';

import firebase, { auth } from 'utils/firebase';
import dom from 'utils/dom';

type Options = {
  // Necessary for dispatching event
  dispatch: ReduxDispatch;

  container: HTMLElement;
  createDiv: boolean;
  signIn: boolean;

  // Required if signIn = true
  phoneInfo?: firebase.auth.PhoneMultiFactorInfo;
  resolver?: firebase.auth.MultiFactorResolver;

  // Required if signIn = false
  phoneNumber?: string;
  session?: firebase.auth.MultiFactorSession;

  // Function to call after verifying phone number
  onClose?: () => void;
};

export default async function process2FAuth(options: Options): Promise<any> {
  let recaptchaContainer;
  let recaptchaOpts;
  if (options.createDiv) {
    // Create new div to place reCAPTCHA inside
    // This allows us to delete the div later and still be able to recreate the reCAPTCHA
    recaptchaContainer = document.createElement('div');
    options.container.appendChild(recaptchaContainer);

    recaptchaOpts = {
      size: 'invisible',
    };
  } else {
    recaptchaContainer = options.container;
    recaptchaOpts = undefined;
  }

  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(recaptchaContainer, recaptchaOpts);
  } else if (window.recaptchaWidgetId) {
    // Note: Type definitions don't include reset function
    (window.recaptchaVerifier as any).reset();
    window.recaptchaVerifier.clear();
  }

  // Render the reCAPTCHA
  const widgetId = await window.recaptchaVerifier.render();
  window.recaptchaWidgetId = widgetId;

  let ret;
  try {
    const provider = new firebase.auth.PhoneAuthProvider(auth);
    // Send verification code to phone
    let verificationId;
    if (options.signIn) {
      verificationId = await provider.verifyPhoneNumber({
        multiFactorHint: options.phoneInfo,
        session: options.resolver!.session,
      }, window.recaptchaVerifier);
    } else {
      verificationId = await provider.verifyPhoneNumber({
        phoneNumber: options.phoneNumber!,
        session: options.session!,
      }, window.recaptchaVerifier);
    }

    // Call close function after code is sent, if specified
    // Normally, this is used to close a previous dialog before the verification code dialog appears
    if (options.onClose) {
      options.onClose();
    }

    // Display verification code dialog and ask user to enter code
    if (options.signIn) {
      await verify2FACode(options.dispatch, verificationId, options.resolver, options.phoneInfo!.phoneNumber!);
    } else {
      await verify2FACode(options.dispatch, verificationId);
    }

    ret = { success: true };
  } catch (error: any) {
    const { code, message } = error;
    switch (code) {
      case 'app/user-cancelled':
      {
        ret = { success: false, cancelled: true, message: 'User cancelled' };
        break;
      }

      case 'auth/requires-recent-login':
      {
        try {
          // Ask user to reenter their password
          await reenterPassword(options.dispatch);
          // If enrolling in 2FA, call this function again after entering password
          if (!options.signIn) {
            // Update session since a new ID token was generated
            const multiFactorSession = await auth.currentUser!.multiFactor.getSession();
            options.session = multiFactorSession;
            return await process2FAuth(options);
          } else {
            ret = { success: true };
          }
        } catch (error_: any) {
          ret = { success: false, message: error_.message };
        }

        break;
      }

      default:
      {
        ret = { success: false, message };
        break;
      }
    }
  }

  // Cleanup recaptcha widget
  if (options.createDiv) {
    dom.removeNode(document.querySelector('.grecaptcha-badge')!.parentElement!);
  }
  (window.recaptchaVerifier as any).reset();
  window.recaptchaVerifier.clear();
  window.recaptchaVerifier = undefined;
  window.recaptchaWidgetId = undefined;

  return ret;
}
