import React, { Fragment, useState, useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'reduxx';
import { Flipper } from 'react-flip-toolkit';
import Sortly from 'react-sortly';

import clsx from 'clsx';

import {
  Drawer, TextField, InputAdornment, IconButton, makeStyles, useTheme, useMediaQuery,
} from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';

import SiteTreeComponent from 'components/siteTreeComponent';

import { SiteTree } from 'types';

import styles from 'styles';
import settings from 'settings';

import {
  handleSearchSiteTreeOn,
  handleSearchSiteTreeOff,
  toggleCollapseSiteTree,
  setDrawerExists,
  handleDrawerClose,
  updateSiteTree,
  getSiteTreeStatus,
} from 'reduxx/actions/app';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  drawer: {
    flexShrink: 0,

    // Only set width for temporary drawer
    [theme.breakpoints.down('sm')]: {
      width: 256,
    },
  },
  drawerPaper: {
    position: 'relative',
    boxShadow: theme.shadows[5],
    background: 'radial-gradient(at top right, #253B49, #16222A)',

    // Allow scrolling but hide scrollbar
    overflowY: 'scroll',
    scrollbarWidth: 'none', // Firefox
    '-ms-overflow-style': 'none', // IE 10+
    '&::-webkit-scrollbar': {
      // Chrome, iOS, Edge, etc
      display: 'none',
    },
  },
  drawerPaperOpen: {
    width: 256,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    // Transitioning to hide the site tree while sliding the drawer out
    // This is done because when the width of the drawer is 0px, a lot of the site tree is wrapped and you can see
    // a clunky shifting
    //
    // This transition begins showing the site tree at a certain point and slowly fades the tree in
    '& div': {
      visibility: 'visible',
      opacity: 1,
      transition: [
        theme.transitions.create('opacity', {
          easing: 'linear',
          duration: theme.transitions.duration.enteringScreen * 0.35,
          delay: theme.transitions.duration.enteringScreen * 0.65,
        }),
        theme.transitions.create('visibility', {
          easing: 'linear',
          duration: 0,
          delay: theme.transitions.duration.enteringScreen * 0.65,
        }),
      ].join(','),
    },
  },
  drawerPaperClose: {
    // Setting width fixes odd bug in Safari where the drawer wouldn't disappear
    width: 1,
    // Turn off border when drawer is not being shown
    border: 'none',
    background: 'transparent',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    // No transition on closing drawer, just hide the site tree
    '& div': {
      visibility: 'hidden',
      opacity: 0,
    },
  },
  search: {
    borderRadius: 5,
    backgroundColor: '#FFFFFF',
    margin: '5px 5px 10px 5px',
    flexShrink: 0,

    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 6,
    },
  },
  expandCollapseIcon: {
    padding: 8,
    flex: 'initial',
  },
  flipper: {
    paddingBottom: 256,
  },
}));

function SiteTreeContainer() {
  const classes = useStyles();

  const siteTree = useSelector((state) => state.app.siteTree);
  const sites = useSelector((state) => state.app.sites);
  const masterID = useSelector((state) => state.app.masterID);
  const siteTreeStatus = useSelector((state) => state.app.siteTreeStatus);
  const siteTreeForSearch = useSelector((state) => state.app.siteTreeForSearch);
  const searchSiteTree = useSelector((state) => state.app.searchSiteTree);
  const searchSiteTreeValue = useSelector((state) => state.app.searchSiteTreeValue);
  const open = useSelector((state) => state.app.drawer.open);
  const dispatch = useDispatch();

  const [items, setItems] = useState<any[]>([]);
  // Store copy of items on drag start to check if its changed
  const [startDragItems, setStartDragItems] = useState<any[]>([]);
  // Whether or not dragging is occurring in the site tree
  const [isDragging, setIsDragging] = useState(false);

  const theme = useTheme();
  const mediumUpDevice = useMediaQuery(theme.breakpoints.up('md'));

  // Create react-sortly compatible list from site tree
  function getItemsFromTree(siteTree_: SiteTree): any[] {
    const depthMap: { [key: string]: number } = {};

    // Deep copy site tree
    const newItems: any[] = siteTree_.map((site) => ({ ...site }));

    for (const site of newItems) {
      if (site.parent === null) {
        depthMap[site.id] = 0;
        site.depth = 0;
      } else {
        const parentDepth = depthMap[site.parent];
        depthMap[site.id] = parentDepth + 1;
        site.depth = parentDepth + 1;
      }
    }

    return newItems;
  }

  // Create site tree from react-sortly items
  function getTreeFromItems(items_: any[]): SiteTree {
    // Deep copy site tree
    const newTree = items_.map((site) => ({ ...site }));

    let lastItem = null;
    let lastDepth = 0;
    const parents = [null];
    const orders = [0];
    for (const treeItem of newTree) {
      if (treeItem.depth > lastDepth) {
        // Child
        parents.push(lastItem.id);
        orders.push(0);
      } else if (treeItem.depth < lastDepth) {
        // Sibling of parent
        parents.pop();
        orders.pop();
      }

      // Update parent & order
      treeItem.parent = parents[parents.length - 1];
      treeItem.order = orders[orders.length - 1]++;

      lastDepth = treeItem.depth;
      lastItem = treeItem;

      // Delete depth
      delete treeItem.depth;
    }

    return newTree;
  }

  useEffect(() => {
    setItems(getItemsFromTree(siteTree));
  }, [siteTree]);

  // drag and drop
  function handleChange(newItems: any[]) {
    // disable drag and drop if search site tree has values
    if (searchSiteTree) {
      return;
    }

    // First item has to be master list item
    if (newItems[0].id !== masterID) {
      return;
    }

    let lastItem = newItems[0];
    let lastItemSite = sites[lastItem.id];

    // Loop through all sites excluding master list
    for (const item of newItems.slice(1)) {
      const itemSite = sites[item.id];

      // Has to have depth between 1 & 2
      if (item.depth === 0) {
        // Depth of 0 is reserved only for master list
        return;
      } else if (item.depth > (itemSite.serialNum ? 2 : 1)) {
        // Zones can only have a depth of 1
        // Sites can only have a depth up to 2
        return;
      }

      // If depth increases, then last item is a parent
      // Make sure items with children are only zones
      if (item.depth > lastItem.depth && lastItemSite.serialNum) {
        return;
      }
      lastItem = item;
      lastItemSite = itemSite;
    }

    setItems(newItems);
  }

  function onDragBegin() {
    setStartDragItems(items);
    setIsDragging(true);
  }

  function onDragEnd() {
    // Check if the site tree has changed
    // Length of items & startDragItems should be the same
    let hasTreeChanged = false;
    for (let i = 0; i < items.length; ++i) {
      const newItem = items[i];
      const oldItem = startDragItems[i];

      if (newItem.id !== oldItem.id || newItem.depth !== oldItem.depth) {
        hasTreeChanged = true;
        break;
      }
    }

    if (hasTreeChanged) {
      const newSiteTree = getTreeFromItems(items);
      dispatch(updateSiteTree(newSiteTree));
    }

    // Clear the items to allow freeing up memory
    setStartDragItems([]);

    setIsDragging(false);
  }

  function updateSearch(event: ChangeEvent<HTMLInputElement>) {
    let { value } = event.target;
    const keywordValue = value;

    if (value) {
      const newItems = items
        .filter((item) => {
          const { nickname } = sites[item.id];

          item = `${nickname.toUpperCase()}`;
          value = `${value.toUpperCase()}`;
          return item.includes(value);
        })
        // Set depth to 0 when searching, meaning the nested information is removed
        .map((item) => Object.assign({}, item, { depth: 0 }));

      // set search site tree on/off; needed so site tree doesnt reload when site tree item is clicked
      dispatch(handleSearchSiteTreeOn({ siteTree: newItems, keywordValue }));
    } else {
      // set search site tree on/off; needed so site tree doesnt reload when site tree item is clicked
      dispatch(handleSearchSiteTreeOff({ value }));
    }
  }

  function clearSearch() {
    // Clear site tree search
    dispatch(handleSearchSiteTreeOff({ value: '' }));
  }

  function handleToggleCollapse(id: string) {
    dispatch(toggleCollapseSiteTree({ id }));
  }

  function onCloseDrawer() {
    dispatch(handleDrawerClose());
  }

  // Keep track of whether a sidebar exists
  // Only component unmount, set it doesnt exist
  useEffect(() => {
    dispatch(setDrawerExists(true));
    return () => {
      dispatch(setDrawerExists(false));
    };
  }, [dispatch]);

  useEffect(() => {
    // Periodically update site tree status
    const timer = setInterval(() => {
      dispatch(getSiteTreeStatus());
    }, settings.timers.siteTreeIndicators);

    return () => clearInterval(timer);
  }, [dispatch]);

  return (
    <Drawer
      variant={mediumUpDevice ? 'permanent' : 'temporary'}
      className={classes.drawer}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerPaperOpen]: mediumUpDevice && open,
          [classes.drawerPaperClose]: mediumUpDevice && !open,
        }),
      }}
      anchor="left"
      open={open} // Used for temporary drawer to close on click-away
      onClose={onCloseDrawer}
      key="app-drawer"
    >
      <Fragment>
        <TextField variant="outlined"
          margin="dense"
          onChange={updateSearch}
          placeholder="Search"
          type="text"
          value={searchSiteTreeValue}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={clearSearch}
                  size="small"
                  className={clsx({
                    [classes.hiddenD]: !searchSiteTreeValue,
                  })}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          className={clsx(classes.search, {
            [classes.m0]: !open,
          })}
        />
        <Flipper flipKey={items.map(({ id }) => id).join('.')} className={classes.flipper}>
          <Sortly items={siteTreeForSearch || items} onChange={handleChange}>
            {(item) => {
              const site = sites[item.id];

              // Do nothing if site not found
              // This may occur briefly while deleting a site
              if (!site) {
                // Hack so that Typescript compiler will be happy
                // Sortly type definitions dont allow null as a valid value
                return (null as any) as React.ReactElement;
              }

              return (
                <SiteTreeComponent
                  id={item.id}
                  depth={item.depth}
                  site={site}
                  onDragBegin={onDragBegin}
                  onDragEnd={onDragEnd}
                  isDragging={isDragging}
                  onToggleCollapse={handleToggleCollapse}
                  searchSiteTree={searchSiteTree}
                  siteTreeStatus={siteTreeStatus}
                />
              );
            }}
          </Sortly>
        </Flipper>
      </Fragment>
    </Drawer>
  );
}

export default SiteTreeContainer;
