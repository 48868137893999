export enum ASCII {
  NUL,
  SOH,
  STX,
  ETX,
  EOT,
  ENQ,
  ACK,
  BEL,
  BS,
  Tab,
  LF,
  VT,
  FF,
  CR,
  SO,
  SI,
  DLE,
  DC1,
  DC2,
  DC3,
  DC4,
  NAK,
  SYN,
  ETB,
  CAN,
  EM,
  SUB,
  ESC,
  FS,
  GS,
  RS,
  US,
  Space,
  ExclPoint,
  LeftDQuote,
  Pound,
  DollarSign,
  Percent,
  And,
  LeftSQuote,
  LeftParen,
  RightParen,
  Star,
  Plus,
  Comma,
  Dash,
  Period,
  Slash,
  D0,
  D1,
  D2,
  D3,
  D4,
  D5,
  D6,
  D7,
  D8,
  D9,
  Colon,
  Semicolon,
  LessThan,
  Equal,
  GreaterThan,
  QuestionMark,
  At,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J,
  K,
  L,
  M,
  N,
  O,
  P,
  Q,
  R,
  S,
  T,
  U,
  V,
  W,
  X,
  Y,
  Z,
  LeftBracket,
  Backslash,
  RightBracket,
  Hat,
  Underscore,
  Backtick,
  a,
  b,
  c,
  d,
  e,
  f,
  g,
  h,
  i,
  j,
  k,
  l,
  m,
  n,
  o,
  p,
  q,
  r,
  s,
  t,
  u,
  v,
  w,
  x,
  y,
  z,
  LeftBrace,
  Or,
  RightBrace,
  Tilde,
}

export function isDigit(c: number): boolean {
  return (c >= ASCII.D0 && c <= ASCII.D9);
}

export function isUpper(c: number): boolean {
  return (c >= ASCII.A && c <= ASCII.Z);
}

export function isLower(c: number): boolean {
  return (c >= ASCII.a && c <= ASCII.z);
}

export function isSpace(c: number): boolean {
  switch (c) {
    case ASCII.CR:
    case ASCII.LF:
    case ASCII.Space:
    case ASCII.Tab:
      return true;

    default:
      return false;
  }
}
