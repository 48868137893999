import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Grid, makeStyles, Theme } from '@material-ui/core';

import styles from 'styles';

type RevertSecondFactorEnrollProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  actionCode: string;
};

const useStyles = makeStyles<Theme, RevertSecondFactorEnrollProps>(styles);

function RevertSecondFactorEnroll(props: RevertSecondFactorEnrollProps) {
  const classes = useStyles(props);

  return (
    <div className={classes.centerHV1}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Button color="primary" variant="contained" component={Link} to="/app/settings">
            Go to Settings
          </Button>
          <br />
          <br />
          <Button color="primary" component={Link} to="/" variant="contained">
            Go to Dashboard
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default RevertSecondFactorEnroll;
