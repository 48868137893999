import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'reduxx';

import { makeStyles } from '@material-ui/core/styles';

import Footer from 'components/footer';
import SiteContent from 'components/siteContent';
import ZoneContent from 'components/zoneContent';
import Notice1 from 'components/extras/notice1';

import styles from 'styles';

const useStyles = makeStyles(styles);

function SiteMain() {
  const classes = useStyles();

  const { siteID } = useParams<{ siteID: string }>();

  const sites = useSelector((state) => state.app.sites);
  const site = sites[siteID];

  if (!site) {
    return (
      <div className={classes.container1}>
        <Notice1
          title="Site not found"
          logoType="main-colored"
          linkTo="/"
          buttonText="Go Home"
        />
        <Footer dashboard />
      </div>
    );
  } else if (site.serialNum) {
    return <SiteContent siteID={siteID} />;
  } else {
    return <ZoneContent siteID={siteID} />;
  }
}

export default SiteMain;
