import React, { useState, useEffect, ReactNode } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'reduxx';

import clsx from 'clsx';
import moment from 'moment';

import {
  IconButton, Grid, Paper, Tab, Tabs, Typography, Box, Tooltip, useTheme, useMediaQuery, makeStyles, Theme,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Home as HomeIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { FORM_ERROR } from 'final-form';
import { useConfirm } from 'material-ui-confirm';

import EditSiteDashboardDialog from 'components/editSiteDashboardDialog';
import UnauthorizedDialog from 'components/unauthorizedDialog';
import Footer from 'components/footer';
import Dashboard from 'components/dashboard';
import SettingsDashboard from 'components/settingsDashboard';
import AccessLevel from 'components/accessLevel';

import { deleteSite, getSiteInfo, getSiteStatus, updateSite } from 'reduxx/actions/app';

import useCallbackRef from 'utils/useCallbackRef';
import useAccessLevel from 'utils/useAccessLevel';
import settings from 'settings';
import styles from 'styles';
import { AnyRecord } from 'types';

type SiteContentProps = {
  siteID: string;
};

const useStyles = makeStyles<Theme, SiteContentProps>((theme) => ({
  ...styles(theme),
  scrollbar: {
    // Takes remaining space
    flexGrow: 1,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    position: 'relative',

    padding: theme.spacing(2),
  },
  tabParent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tab: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    borderRadius: 8,
  },
  alarmText: {
    fontSize: 14,
    fontWeight: 500,
  },
  grey1: {
    color: '#404040',
  },
}));

function SiteContent(props: SiteContentProps) {
  const classes = useStyles(props);
  const history = useHistory();
  const confirm = useConfirm();

  const [editSiteDialog, setEditSiteDialog] = useState({ open: false });
  const [rootRef, setRootRef] = useCallbackRef<ReactNode>();
  const dispatch = useDispatch();

  const sites = useSelector((state) => state.app.sites);
  const siteInfo = useSelector((state) => state.app.siteInfo);
  const siteTreeStatus = useSelector((state) => state.app.siteTreeStatus);

  const { siteID } = props;
  const site = sites[siteID];
  const siteStatus = siteTreeStatus[siteID] || 'offline';
  const accessLevelDataObject = useAccessLevel(site.serialNum!);

  // Match page from URL
  const match = useRouteMatch<{ siteID: string; page: string }>('/app/site/:siteID/:page');

  const theme = useTheme();
  const smallUpDevice = useMediaQuery(theme.breakpoints.up('sm'));

  const baseURL = `/app/site/${siteID}`;
  const tabs = [
    {
      url: 'dashboard',
      label: 'Dashboard',
      icon: <HomeIcon />,
    },
    {
      url: 'settings',
      label: 'Settings',
      icon: <SettingsIcon />,
    },
  ];

  async function onOpenEditSiteDialog() {
    setEditSiteDialog({
      open: true,
    });
  }

  function onCloseEditSiteDialog() {
    setEditSiteDialog({
      open: false,
    });
  }

  async function onSubmitEditSiteDialog(newValues: any) {
    const changesObj: AnyRecord = {};

    // Check if value changed
    if (newValues.nickname !== site.nickname) {
      changesObj.nickname = newValues.nickname;
    }

    // Check if value changed
    if (newValues.authCode !== site.authCode) {
      changesObj.authCode = newValues.authCode;
    }

    // Only update zone if something changed
    if (Object.keys(changesObj).length > 0) {
      try {
        await dispatch(updateSite(siteID, changesObj));
      } catch (error: any) {
        // Get message from response
        const responseMessage = error?.response?.data?.message;

        let message;
        if (responseMessage === 'Invalid authentication') {
          message = 'Incorrect auth code';
        } else {
          message = 'Something went wrong. Please try again later';
        }

        return { [FORM_ERROR]: message };
      }
    }

    onCloseEditSiteDialog();
    return undefined;
  }

  async function onDeleteSite() {
    await confirm({ title: 'Are you sure you want to delete this site?' });

    dispatch(deleteSite(siteID));
    history.push('/');
  }

  async function onSubmitUnauthorizedDialog(values: any) {
    try {
      await dispatch(updateSite(siteID, values));
      await dispatch(getSiteStatus(site.serialNum!, siteID));
    } catch (error) {
      return {
        authCode: 'Invalid auth code',
      };
    }

    return undefined;
  }

  function onCancelUnauthorizedDialog() {
    // Take user back to last place they were
    history.goBack();
  }

  // Get site info & alarms
  useEffect(() => {
    dispatch(getSiteInfo(site.serialNum!, true));
  }, [dispatch, site.serialNum, siteID]);

  // Periodically update site info
  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(getSiteInfo(site.serialNum!));
    }, settings.timers.siteInfo);
    return () => clearInterval(timer);
  }, [dispatch, site.serialNum, siteID]);

  // Handle finding selected tab based on URL
  // If root URL is used (i.e. !match), then we redirect to appropriate tab
  //
  // Note: Logic down here b/c all React hooks must unconditionally be used, requirement for hooks
  let selectedTab;
  if (!match) {
    return <Redirect to={`/app/site/${siteID}/dashboard`} />;
  } else {
    // Search tab for matching URL
    for (const [index, tab] of tabs.entries()) {
      if (tab.url === match.params.page) {
        selectedTab = index;
        break;
      }
    }
  }

  return (
    <OverlayScrollbarsComponent
      ref={setRootRef}
      className={clsx(classes.scrollbar, 'os-host-flexbox')}
      options={{
        sizeAutoCapable: false,
        overflowBehavior: {
          x: 'hidden',
          y: 'scroll',
        },
      }}
    >
      <div className={classes.root}>
        <EditSiteDashboardDialog
          open={editSiteDialog.open}
          onSubmit={onSubmitEditSiteDialog}
          onClose={onCloseEditSiteDialog}
          initialValues={{
            nickname: site.nickname,
            authCode: site.authCode,
          }}
          container={rootRef}
        />
        <UnauthorizedDialog
          open={siteStatus === 'unauthorized'}
          onSubmit={onSubmitUnauthorizedDialog}
          onCancel={onCancelUnauthorizedDialog}
          initialValues={{
            authCode: site.authCode,
          }}
          container={rootRef}
        />
        <Box display="flex" flexShrink={0} flexWrap="wrap">
          <Box flexGrow={1}>
            <Grid container direction="row" alignItems="center">
              <Grid item className={clsx(classes.mr0p5, classes.headingGrey1)}>
                {site.nickname}
              </Grid>
              <Grid item>
                <Tooltip title="Edit site">
                  <IconButton className={classes.p0p5} onClick={onOpenEditSiteDialog}>
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="Delete site">
                  <IconButton className={classes.p0p5} onClick={onDeleteSite}>
                    <DeleteIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Typography variant="body2">
              <b>Site ID:</b> {site.serialNum}
              <br />
              <b>Name:</b> {siteInfo.customerName || 'N/A'}
              <br />
              <b>Location:</b> {siteInfo.location || 'N/A'}
              <br />
              <b>System Time: </b>
              {siteInfo.timestamp ? moment(siteInfo.timestamp).format('LLL') : 'N/A'}
            </Typography>
          </Box>
          <Grid item>
            <AccessLevel serialNum={site.serialNum!} accessLevelDataObject={accessLevelDataObject} />
          </Grid>
        </Box>
        <Paper className={classes.paperAlt} elevation={0}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={selectedTab}
            variant="scrollable"
            className={classes.tabParent}
            scrollButtons="auto"
          >
            {tabs.map((tab) => {
              if (!tab) {
                return null;
              }

              return (
                <Tab
                  key={tab.url}
                  component={Link}
                  to={`${baseURL}/${tab.url}`}
                  icon={tab.icon}
                  label={smallUpDevice ? tab.label : null}
                  className={classes.tab}
                />
              );
            })}
          </Tabs>
          <Switch>
            <Route path="/app/site/:siteID/dashboard">
              <Dashboard siteID={siteID} isZone={false} />
            </Route>
            <Route path="/app/site/:siteID/settings">
              <SettingsDashboard siteID={siteID} controllerSiteID={site.serialNum!} />
            </Route>
            {/* Unknown routes go to 404 error page */}
            <Redirect to="/errors/404" />
          </Switch>
        </Paper>
        <Footer dashboard subtractMargin />
      </div>
    </OverlayScrollbarsComponent>
  );
}

export default SiteContent;
