import React, { useEffect } from 'react';
import { getControllerConfig } from 'reduxx/actions/app';
import { useDispatch, useSelector } from 'reduxx';

import { CircularProgress, makeStyles } from '@material-ui/core';

import ControllerConfigTable from 'components/controllerConfigTable';

import styles from 'styles';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
}));

type ControllerConfigProps = {
  siteID: string;
};

function ControllerConfig(props: ControllerConfigProps) {
  const classes = useStyles();
  const controllerConfig = useSelector((state) => state.app.controllerConfig);
  const dispatch = useDispatch();

  const { siteID } = props;

  useEffect(() => {
    dispatch(getControllerConfig(siteID));
  }, [dispatch, siteID]);

  return controllerConfig.loading ? (
    <div className={classes.centerH2}>
      <CircularProgress color="primary" />
    </div>
  ) : (
    <div className={classes.itemContainer}>
      {controllerConfig.data!.map((card, i) => (
        <ControllerConfigTable
          key={i}
          groups={card.groups}
          title={card.title}
          headers={card.headers}
          rows={card.rows}
          siteID={siteID}
        />
      ))}
    </div>
  );
}

export default ControllerConfig;
