import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'reduxx';

import clsx from 'clsx';

import {
  AppBar, Button, IconButton, Toolbar, makeStyles, Theme,
} from '@material-ui/core';
import { Menu as MenuIcon, Settings as SettingsIcon, ExitToApp as ExitToAppIcon } from '@material-ui/icons';

import UserAvatar from 'components/extras/userAvatar';
import ButtonDropdown, { BDMenuItem } from './extras/buttonDropdown';

import { handleDrawerOpen, handleDrawerClose, openDialog } from 'reduxx/actions/app';

import logoImage from 'images/white-logo.png';
import APLogoImage from 'images/AP-logo.png';
import cumberlandLogoImage from 'images/Cumberland-Logo.png';

import styles from 'styles';

type BarProps = {
  performingAction: boolean;
};

const useStyles = makeStyles<Theme, BarProps>((theme) => ({
  ...styles(theme),
  root: {
    backgroundImage: 'linear-gradient(to right, #16222A 0%, #3A6073 51%, #16222A 100%)',
    // Set z-index 1 larger than sidebar so that it appears over it when scrolling
    zIndex: 1201,
  },
  logoParent: {
    display: 'flex',
    flexGrow: 1,

    // Center logo on small devices
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  logoParentOffset: {
    // Only apply offset to fix centering on small devices
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 52,
    },
  },
  logo: {
    marginTop: 6,
    // Note: Max-width size is selected based on the desired maximum size of logo
    maxWidth: 380,
    width: '100%',
    paddingRight: theme.spacing(2),

    verticalAlign: 'middle',

    // Hide logo on small devices
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  brandLogo: {
    marginTop: 6,
    // Note: Max-width size is selected based on the desired maximum size of logo
    maxWidth: 90,
    maxHeight: 90,
    width: '100%',
    paddingRight: theme.spacing(),

    verticalAlign: 'middle',

    // Hide logo on small devices
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  logoSmall: {
    height: 48,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 40,
    },

    verticalAlign: 'middle',

    // Hide logo on large devices
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    padding: theme.spacing(0, 2, 0, 1),
    [theme.breakpoints.down('xs')]: {
      // Decrease padding on small screens, saves space
      padding: theme.spacing(0, 1),
    },
  },
  menu: {
    width: 176,
  },
  menuButton: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
  avatarButton: {
    padding: 0,

    // Add padding to properly center logo because menu icon is a bit smaller
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 12,
    },
  },
  button1: {
    backgroundColor: 'red',
    margin: '0 4px 0 4px',
  },
  xsHiddenD: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  smUpHiddenD: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

function Bar(props: BarProps) {
  const classes = useStyles(props);

  // Props
  const { performingAction } = props;

  const [user, userData] = useSelector((state) => [state.user.user, state.user.userData]);
  const drawerExists = useSelector((state) => state.app.drawer.exists);
  const drawerOpen = useSelector((state) => state.app.drawer.open);
  const dispatch = useDispatch();

  function openSignUpDialog() {
    dispatch(openDialog({ dialog: 'signUpDialog' }));
  }

  function openSignInDialog() {
    dispatch(openDialog({ dialog: 'signInDialog' }));
  }

  function openSignOutDialog() {
    dispatch(openDialog({ dialog: 'signOutDialog' }));
  }

  function onDrawerExpandCollapse() {
    if (drawerOpen) {
      dispatch(handleDrawerClose());
    } else {
      dispatch(handleDrawerOpen());
    }
  }

  const menuItems: BDMenuItem[] = [
    {
      name: 'Settings',
      icon: <SettingsIcon />,
      to: '/app/settings',
    },
    {
      name: 'Sign out',
      icon: <ExitToAppIcon />,
      divide: true,
      onClick: openSignOutDialog,
    },
  ];

  return (
    <AppBar position="sticky" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        {drawerExists && (
          <IconButton
            className={classes.menuButton}
            onClick={onDrawerExpandCollapse}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
        )}
        {!user && (
          <Button
            className={clsx(classes.smUpHiddenD, classes.mx0p5)}
            color="primary"
            variant="contained"
            size="small"
            onClick={openSignUpDialog}
          >
            Sign up
          </Button>
        )}
        <div
          className={clsx(classes.logoParent, {
            [classes.logoParentOffset]: !drawerExists && user,
          })}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div>
            <Link to="/">
              <img className={classes.logo} src={logoImage} alt="FeedLink Live!" />
            </Link>
          </div>
          <div style={{ marginLeft: 'auto', paddingRight: 20 }}>
            <Link to="/">
              <img className={classes.brandLogo} src={APLogoImage} alt="AP" />
            </Link>
            <Link to="/">
              <img className={classes.brandLogo} src={cumberlandLogoImage} alt="Cumberland" />
            </Link>
          </div>
        </div>
        {user ? (
          <ButtonDropdown menuItems={menuItems} className={classes.menu}>
            <IconButton
              color="inherit"
              disabled={performingAction}
              className={classes.avatarButton}
            >
              <UserAvatar user={user!} userData={userData!} />
            </IconButton>
          </ButtonDropdown>
        ) : (
          <Fragment>
            <Button
              className={clsx(classes.xsHiddenD, classes.mx0p5)}
              color="primary"
              variant="contained"
              size="small"
              onClick={openSignUpDialog}
            >
              Sign up
            </Button>
            <Button
              className={classes.mx0p5}
              color="primary"
              variant="contained"
              size="small"
              onClick={openSignInDialog}
            >
              Sign in
            </Button>
          </Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Bar;
