import moment from 'moment';

// Map to format BCore duration to string
export const durationFormatIMaskMap = {
  'day:hr': 'dd:hh',
  'h:m': 'hh:mm',
  'h:mS': 'hh:mm',
  'h:m:s': 'hh:mm:ss',
  min: 'mmm',
  'm:s': 'mm:ss',
  sec: 'ss',
} as { [key: string]: string };

// Map for displaying BCore duration in a "pretty" way
export const durationDisplayMap = {
  'day:hr': 'd:h',
  'h:m': 'h:m',
  'h:mS': 'h:m',
  'h:m:s': 'h:m:s',
  min: 'm',
  'm:s': 'm:s',
  sec: 's',
} as { [key: string]: string };

export function BParseDuration(duration: string, format: string): moment.Duration {
  let duration_: string;

  switch (format) {
    case 'min': {
      duration_ = `00:${duration}:00`;
      break;
    }

    case 'm:s': {
      duration_ = `00:${duration}`;
      break;
    }

    case 'sec': {
      duration_ = `00:00:${duration}`;
      break;
    }

    default: {
      duration_ = duration;
    }
  }

  return moment.duration(duration_, format as any);
}
