/* eslint-disable no-extend-native */

String.prototype.insert = function(index, str) {
  return this.substring(0, index) + str + this.substring(index);
};

String.prototype.equals = function(...item) {
  // Use some rather than includes so we can do == comparison instead of ===
  // eslint-disable-next-line eqeqeq
  return item.some((i) => i == this);
};

String.prototype.ucFirst = function() {
  return this[0].toUpperCase() + this.slice(1);
};

export {};
