import {
  TimeFormat, EnumDisplay, DateFormat,
} from './bEnums';
import _ from 'lodash';
import { getBEnumStr } from './bEnumStr';
import moment from 'moment';
import 'moment-duration-format';
import { SysTableValue } from 'types';

interface ToStringOptions {
  // Whether or not to include units in the string
  // Defaults to true
  includeUnits?: boolean;

  precision?: number;
  boolNumeric?: boolean;
  dateFormat?: DateFormat;
  enumDisplay?: EnumDisplay;
  timeFormat?: TimeFormat;
}

export function BToString(value: SysTableValue, options: ToStringOptions = {}) {
  options = _.defaults(options, {
    includeUnits: true,
    boolNumeric: false,
  });

  // Get datatype for value
  const { datatype } = value;
  if (!datatype) {
    return value.value;
  }

  switch (datatype[0]) {
    case 'bool': {
      if (options.boolNumeric) {
        return (+value.value).toString();
      }

      switch (datatype[1]) {
        case 'true': {
          return value.value ? 'true' : 'false';
        }

        case 'yesno': {
          return value.value ? 'yes' : 'no';
        }

        case 'enable': {
          return value.value ? 'enable' : 'disable';
        }

        case 'onoff': {
          return value.value ? 'on' : 'off';
        }

        default: {
          return value.value;
        }
      }
    }

    case 'int': {
      if (value.value === 'disabled' || value.value === 'OOR') {
        return value.value;
      }
      if (options.includeUnits && value.units) {
        return `${value.value.toStringE({ withCommas: true })} ${value.units}`;
      } else {
        return value.value.toStringE({ withCommas: true });
      }
    }

    case 'float': {
      if (value.value === 'disabled' || value.value === 'OOR') {
        return value.value;
      }

      const precision = options.precision || value.precision;
      if (options.includeUnits && value.units) {
        return `${value.value.toStringE({ precision, withCommas: true })} ${value.units}`;
      } else {
        return value.value.toStringE({ precision, withCommas: true });
      }
    }

    case 'enum': {
      return getBEnumStr(datatype[1]!, value.value) || '';
    }

    case 'duration': {
      return (value.value as moment.Duration).format('d[d] h[h] mm[m] ss[s]', {
        trim: 'both',
      });
    }

    case 'date': {
      if (!value.value) {
        return 'N/A';
      } else {
        return moment(value.value).format('YYYY-MM-DD');
      }
    }

    case 'string':
    default: {
      return value.value;
    }
  }
}
