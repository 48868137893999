/* eslint-disable import/no-duplicates */
import * as firebase from 'firebase/app';
import settings from 'settings';

import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

firebase.initializeApp(settings.credentials.firebase);

export default firebase;
export const auth = firebase.auth();
export const storage = firebase.storage();
export const analytics = firebase.analytics();
