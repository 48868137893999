/* eslint-disable func-names */
import { isDigit } from './ascii';

Number.prototype.toHexString = function(length: number = 0, uppercase: boolean = true, with0x: boolean = true): string {
  let str = this.toString(16);

  // Uppercase it
  if (uppercase) {
    str = str.toUpperCase();
  }

  // Pad it
  str = str.padStart(length, '0');

  // Add 0x
  if (with0x) {
    str = `0x${str}`;
  }

  return str;
};

Number.prototype.toPadString = function(maxLength: number, fillString?: string): string {
  return this.toString().padStart(maxLength, fillString);
};

Number.prototype.toStringE = function(options: NumberOptions): string {
  let str = '';
  if (options.width !== undefined && options.precision !== undefined) {
    str = ((this < 0) ? '-' : '') + Math.abs(this as number).toFixed(options.precision)
      .padStart(options.width + options.precision + 1, '0');
  } else if (options.width !== undefined) {
    const negative = (this < 0);
    str = Math.abs(this as number).toString();
    const x = str.indexOf('.');
    if (x === -1) {
      str = (negative ? '-' : '') + str.padStart(options.width, '0');
    } else {
      str = (negative ? '-' : '') + str.padStart(options.width + (str.length - x), '0');
    }
  } else if (options.precision !== undefined) {
    str = this.toFixed(options.precision);
  } else {
    str = this.toString();
  }

  if (options.withCommas) {
    let index = str.indexOf('.');
    if (index === -1) {
      index = str.length - 3;
    } else {
      index -= 3;
    }

    if (index < 2 || !isDigit(str.charCodeAt(index - 2))) {
      return str;
    }

    while (index > 0 && isDigit(str.charCodeAt(index - 1))) {
      str = str.insert(index, ',');
      index -= 3;
    }
  }

  return str;
};
