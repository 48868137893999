import React from 'react';
import { useSelector, useDispatch } from 'reduxx';
import { useHistory } from 'react-router';

import clsx from 'clsx';

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import {
  Dialog, DialogContent, DialogActions, DialogTitle, Button, Grid, makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import TextFieldRFF from 'components/extras/textFieldRFF';

import { newSite, closeDialog } from 'reduxx/actions/app';

import styles from 'styles';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  serialNumTextField: {
    // Align input adornment with text
    '& .MuiInputAdornment-positionStart': {
      marginRight: 4,
    },
    '& .MuiTypography-body1': {
      lineHeight: 'normal',
    },
  },
  authCodeTextField: {
    '& .MuiInputBase-input': {
      textTransform: 'uppercase',
    },
  },
}));

function NewSite() {
  const classes = useStyles();
  const history = useHistory();

  const data = useSelector((state) => state.app.newSiteDialog);
  const { open, siteID } = data;
  const siteTree = useSelector((state) => state.app.siteTree);
  const sites = useSelector((state) => state.app.sites);
  const dispatch = useDispatch();

  function onClose() {
    dispatch(closeDialog({ dialog: 'newSiteDialog' }));
  }

  async function onSubmit(values: any) {
    if (values.serialNum.length !== 8) {
      return { serialNum: 'Must be 8 characters' };
    } else if (values.authCode.length !== 8) {
      return { authCode: 'Must be 8 characters' };
    } else if (!/^[0-9A-Za-z]*$/.test(values.authCode)) {
      return { authCode: 'Valid characters: 0-9, A-Z' };
    }

    const { serialNum } = values;

    const authCode = values.authCode.toUpperCase();

    // Search site map for the serial number
    const siteExists = Object.values(sites).find((site) => site.serialNum === serialNum);

    if (siteExists) {
      return { [FORM_ERROR]: 'Site already exists' };
    }

    try {
      const newSite_ = await dispatch(
        newSite({
          parent: siteID,
          order: siteTree.filter((site) => site.parent === siteID).length,
          nickname: values.nickname,
          serialNum,
          authType: 'code',
          authCode,
        }),
      );

      history.push(`/app/site/${newSite_.id}`);
      onClose();
    } catch (error: any) {
      return {
        [FORM_ERROR]:
          error?.response?.data?.message || 'Something went wrong. Please try again later',
      };
    }

    return undefined;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth scroll="body">
      <DialogTitle>New Site</DialogTitle>
      <Form
        onSubmit={onSubmit}
        render={({ submitError, handleSubmit, submitting, values, pristine }) => {
          let serialNumHelperText;
          if (values.serialNum && values.serialNum.length !== 8) {
            serialNumHelperText = `Enter last ${8 - values.serialNum.length} characters of Site ID`;
          }

          return (
            <form onSubmit={handleSubmit}>
              {/* DialogContent has additional padding if first child, trick it by putting fake div */}
              <div />
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      variant="outlined"
                      fullWidth
                      required
                      autoFocus
                      name="serialNum"
                      component={TextFieldRFF}
                      type="text"
                      label="Controller Site ID"
                      helperText={serialNumHelperText}
                      inputProps={{
                        maxLength: 8,
                      }}
                      className={clsx(classes.serialNumTextField, classes.uppercaseTextField)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      variant="outlined"
                      fullWidth
                      required
                      name="authCode"
                      component={TextFieldRFF}
                      type="text"
                      label="Authentication Code"
                      inputProps={{
                        maxLength: 8,
                      }}
                      className={clsx(classes.uppercaseTextField)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      variant="outlined"
                      name="nickname"
                      fullWidth
                      required
                      component={TextFieldRFF}
                      type="TextField"
                      label="Nickname"
                    />
                  </Grid>
                  {submitError && (
                    <Grid item xs={12}>
                      <Alert className={classes.slimAlert} severity="error">
                        {submitError}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} disabled={submitting}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting || pristine}
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
}

export default NewSite;
