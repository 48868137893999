import React, { ReactNode } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Theme,
} from '@material-ui/core';

type AlertDialogProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  contentText?: string;
  dismissiveAction?: ReactNode;
  confirmingAction?: ReactNode;
  acknowledgementAction?: ReactNode;
};

const useStyles = makeStyles<Theme, AlertDialogProps>((theme) => ({
  noTitlePadding: {
    paddingTop: theme.spacing(3),
  },
}));

function AlertDialog(props: AlertDialogProps) {
  const classes = useStyles(props);

  const { open, onClose, title, contentText, dismissiveAction, confirmingAction, acknowledgementAction } = props;

  if ((dismissiveAction || confirmingAction) && acknowledgementAction) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent className={title ? undefined : classes.noTitlePadding}>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>

      {(dismissiveAction || confirmingAction || acknowledgementAction) && (
        <DialogActions>
          {dismissiveAction}
          {confirmingAction}
          {acknowledgementAction}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default AlertDialog;
