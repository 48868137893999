import { EnumDisplay } from './bEnums';

const enumStrMap: { [key: string]: (string[] | { [key: number]: string[] }) } = {
  LoadCellType: ['5K', '8K', '12K'],
  Units: ['U.S.', 'Metric'],
  ProbeType: ['Inside Probe', 'Outside Probe'],
  ProxSwitchConfig: ['Prox Switch disabled', 'Sensor #1', 'Sensor #2', 'Both Sensors'],
  SlideMode: ['Manual', 'Normal', 'Auto'],
  SlidePosition: ['Closed', 'Open'],
  FeedLine: [
    'Disabled',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'AA',
    'AB',
    'AC',
    'AD',
    'AE',
    'AF',
    'AG',
    'AH',
    'AI',
    'AJ',
    'AK',
    'AL',
    'AM',
    'AN',
    'AO',
    'AP',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AV',
    'AW',
    'AX',
    'AY',
    'AZ',
    'BA',
    'BB',
    'BC',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BK',
    'BL',
    'BM',
    'BN',
    'BO',
    'BP',
    'BQ',
    'BR',
    'BS',
    'BT',
    'BU',
    'BV',
    'BW',
    'BX',
    'BY',
    'BZ',
    'CA',
    'CB',
    'CC',
    'CD',
    'CE',
    'CF',
    'CG',
    'CH',
    'CI',
    'CJ',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CP',
    'CQ',
    'CR',
    'CS',
    'CT',
    'CU',
    'CV',
    'CW',
    'CX',
    'CY',
    'CZ',
    'DA',
    'DB',
    'DC',
    'DD',
    'DE',
    'DF',
    'DG',
    'DH',
    'DI',
    'DJ',
    'DK',
    'DL',
    'DM',
    'DN',
    'DO',
    'DP',
    'DQ',
    'DR',
    'DS',
    'DT',
    'DU',
    'DV',
    'DW',
    'DX',
    'DY',
    'DZ',
    'EA',
    'EB',
    'EC',
    'ED',
    'EE',
    'EF',
    'EG',
    'EH',
    'EI',
    'EJ',
    'EK',
    'EL',
    'EM',
    'EN',
    'EO',
    'EP',
    'EQ',
    'ER',
    'ES',
    'ET',
    'EU',
    'EV',
    'EW',
    'EX',
    'EY',
    'EZ',
    'FA',
    'FB',
    'FC',
    'FD',
    'FE',
    'FF',
    'FG',
    'FH',
    'FI',
    'FJ',
    'FK',
    'FL',
    'FM',
    'FN',
    'FO',
    'FP',
    'FQ',
    'FR',
    'FS',
    'FT',
    'FU',
    'FV',
    'FW',
    'FX',
    'FY',
    'FZ',
    'GA',
    'GB',
    'GC',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GI',
    'GJ',
    'GK',
    'GL',
    'GM',
    'GN',
    'GO',
    'GP',
    'GQ',
    'GR',
    'GS',
    'GT',
    'GU',
    'GV',
    'GW',
    'GX',
    'GY',
    'GZ',
    'HA',
    'HB',
    'HC',
    'HD',
    'HE',
    'HF',
    'HG',
    'HH',
    'HI',
    'HJ',
    'HK',
    'HL',
    'HM',
    'HN',
    'HO',
    'HP',
    'HQ',
    'HR',
    'HS',
    'HT',
    'HU',
    'HV',
    'HW',
    'HX',
    'HY',
    'HZ',
  ],
};

export function getBEnumStr(enumType: string, value: number) {
  const enumMap = enumStrMap[enumType];

  return enumMap[value];
}

export function getBEnumStrList(enumType: string, enumDisplay: EnumDisplay = EnumDisplay.Full): string[] | undefined {
  const enumMap = enumStrMap[enumType];
  if (!enumMap) {
    return undefined;
  } else if (Array.isArray(enumMap)) {
    return enumMap;
  } else {
    const enumMap2 = enumMap[enumDisplay];
    return enumMap2 || undefined;
  }
}
