import React, { ReactNode } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  FormHelperText,
  FormControlProps,
  FormHelperTextProps,
  Radio,
  RadioProps,
  RadioGroup,
} from '@material-ui/core';

type RadioGroupItemProps = {
  value: any;
  children: string;
  labelProps?: Omit<FormControlLabelProps, 'control' | 'value' | 'label'>;
} & RadioProps;

type RadioGroupRFFProps = {
  label: ReactNode;
  helperText?: string;
  formControlProps?: FormControlProps;
  formLabelProps?: any;
  formHelperTextProps?: FormHelperTextProps;
  variant?: 'standard' | 'outlined' | 'filled';
  fullWidth?: boolean;
  input: any;
  meta: any;
  children?: ReactNode;
};

export function RadioGroupItem(props: RadioGroupItemProps) {
  const { value, labelProps, children, ...rest } = props;

  return (
    <FormControlLabel
      control={<Radio {...rest} />}
      value={value}
      label={children}
      {...labelProps}
    />
  );
}

function RadioGroupRFF(props: RadioGroupRFFProps) {
  const {
    label,
    helperText,
    formControlProps,
    formLabelProps,
    formHelperTextProps,
    variant,
    fullWidth,
    input,
    meta,
    children,
    ...radioGroupProps
  } = props;

  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <FormControl fullWidth={fullWidth} variant={variant} error={showError} {...formControlProps}>
      {!!label && (
        <FormLabel component="legend" {...formLabelProps}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        {...radioGroupProps}
      >
        {children}
      </RadioGroup>
      {showError ? (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      ) : helperText ? (
        <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

export default RadioGroupRFF;
