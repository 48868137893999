export const SystemType = Object.freeze({
  None: 0,

  BECSys7: 37,
  BECSys6: 38,
  BECSys5: 39,
  BECSys3: 40,

  NetmasterSporlan: 41,
  NetmasterBECSys: 42,
  NetmasterFeedlink: 43,

  NetmasterFeedlink2: 48,
  BECSys6F: 49,
});

const products = [{
  num: 1100177,
  system: SystemType.BECSys7,
  subtype: 0,
  voltage: '115V',
  shortName: 'BECSys7',
  fullName: 'BECSys7 Mechanical Room Controller',
},
{
  num: 1100202,
  system: SystemType.BECSys7,
  subtype: 1,
  voltage: '230V',
  shortName: 'BECSys7',
  fullName: 'BECSys7 Mechanical Room Controller',
},
{
  num: 1100214,
  system: SystemType.BECSys6,
  subtype: 0,
  voltage: '115V',
  shortName: 'BECSysBW',
  fullName: 'BECSysBW Backwash Controller',
},
{
  num: 1100217,
  system: SystemType.BECSys6,
  subtype: 1,
  voltage: '230V',
  shortName: 'BECSysBW',
  fullName: 'BECSysBW Backwash Controller',
},
{
  num: 1100167,
  system: SystemType.BECSys5,
  subtype: 0,
  voltage: '115V',
  shortName: 'BECSys5',
  fullName: 'BECSys5 Chemistry Controller',
},
{
  num: 1100216,
  system: SystemType.BECSys5,
  subtype: 1,
  voltage: '230V',
  shortName: 'BECSys5',
  fullName: 'BECSys5 Chemistry Controller',
},
{
  num: 1100166,
  system: SystemType.BECSys3,
  subtype: 0,
  voltage: '115V',
  shortName: 'BECSys3',
  fullName: 'BECSys3 Chemistry Controller',
},
{
  num: 1100215,
  system: SystemType.BECSys3,
  subtype: 1,
  voltage: '230V',
  shortName: 'BECSys3',
  fullName: 'BECSys3 Chemistry Controller',
},
{
  num: 1100257,
  system: SystemType.BECSys3,
  subtype: 2,
  voltage: '',
  shortName: 'BECSys3',
  fullName: 'BECSys3 Chemistry Controller+',
},
{
  num: 1100342,
  system: SystemType.BECSys3,
  subtype: 3,
  voltage: '',
  shortName: 'BECSys3',
  fullName: 'BECSys3 w/RCM Chemistry Controller+',
},
{
  num: 1100184,
  system: SystemType.BECSys3,
  subtype: 4,
  voltage: '115V',
  shortName: 'BECSys2',
  fullName: 'BECSys2 Chemistry Controller',
},
{
  num: 1100186,
  system: SystemType.BECSys3,
  subtype: 5,
  voltage: '230V',
  shortName: 'BECSys2',
  fullName: 'BECSys2 Chemistry Controller',
},
{
  num: 1100343,
  system: SystemType.BECSys3,
  subtype: 6,
  voltage: '',
  shortName: 'BECSys3',
  fullName: 'BECSys3 w/RCM Chemistry Controller',
},
{
  num: 1100242,
  system: SystemType.NetmasterSporlan,
  subtype: 0,
  voltage: '',
  shortName: 'Sporlan NM',
  fullName: 'Sporlan NM',
},
{
  num: 1100236,
  system: SystemType.NetmasterBECSys,
  subtype: 0,
  voltage: '',
  shortName: 'BECSysRCM',
  fullName: 'BECSysRCM',
},
{
  num: 1100290,
  system: SystemType.NetmasterFeedlink,
  subtype: 0,
  voltage: '',
  shortName: 'Feedlink',
  fullName: 'Feedlink',
},
{
  num: 1100290,
  system: SystemType.BECSys6F,
  subtype: 0,
  voltage: '',
  shortName: 'Fluidra Filter Controller',
  fullName: 'Fluidra Filter Controller',
},
];

export function formatNodeSerialNum(serialNum: string, nodeID: number) {
  if (nodeID === 0) {
    return serialNum;
  }

  return `${serialNum}:${nodeID.toString().padStart(2, '0')}`;
}

export function parseSerialNum(serialNum: string): number {
  if (serialNum.startsWith('1100') && serialNum.length === 13) {
    const productNum = parseInt(serialNum.substr(0, 7));
    const number = parseInt(serialNum.substr(7, 6));

    for (const product of products) {
      if (product.num === productNum) {
        return (number & 0xFFFF) | (product.subtype << 16) | (product.system << 24);
      }
    }
  }

  return 0;
}

export function parseNodeSerialNum(serialNum: string): [string | null, number] {
  const parts = serialNum.split(':');

  if (parts.length > 2 || parts[0].length !== 8) {
    return [null, -1];
  }

  if (parts.length === 1) {
    return [serialNum, 0];
  }

  const nodeID = parseInt(parts[1]);

  return [parts[0], nodeID];
}

export function getProduct(serialNum: number | string) {
  if (typeof serialNum === 'string') {
    serialNum = parseSerialNum(serialNum);
  }

  const subtype = (serialNum >> 16) & 0xFF;
  const system = (serialNum >> 24) & 0xFF;

  for (const product of products) {
    if (product.system === system && product.subtype === subtype) {
      return product;
    }
  }

  return null;
}

export function isRCM(serialNum: string): boolean {
  return serialNum.startsWith('000CDC');
}

export enum NodeType {
  NWM,
  FBW,
  SF,
  PLM,
  TW,
  Group,
  GSIE,
  SG
}

export interface NodeIDMap {
  minID: number;
  maxID: number;
  numNodes: number;
}

export function getDisplayID(nodeType: NodeType, nodeID: number): number {
  switch (nodeType) {
    case NodeType.SG:
      return nodeID - nodeIDMap[nodeType].minID + 1;

    default:
      return nodeID;
  }
}

export function getNodeType(nodeID: number): NodeType {
  if (nodeID >= nodeIDMap[NodeType.FBW].minID && nodeID <= nodeIDMap[NodeType.FBW].maxID) {
    return NodeType.FBW;
  } else if (nodeID >= nodeIDMap[NodeType.SF].minID && nodeID <= nodeIDMap[NodeType.SF].maxID) {
    return NodeType.SF;
  } else if (nodeID >= nodeIDMap[NodeType.PLM].minID && nodeID <= nodeIDMap[NodeType.PLM].maxID) {
    return NodeType.PLM;
  } else if (nodeID >= nodeIDMap[NodeType.TW].minID && nodeID <= nodeIDMap[NodeType.TW].maxID) {
    return NodeType.TW;
  } else if (nodeID >= nodeIDMap[NodeType.SG].minID && nodeID <= nodeIDMap[NodeType.SG].maxID) {
    return NodeType.SG;
  } else {
    return NodeType.NWM;
  }
}

export const nodeIDMap = {
  [NodeType.FBW]: {
    minID: 1,
    maxID: 50,
    numNodes: 50,
  },
  [NodeType.SF]: {
    minID: 51,
    maxID: 75,
    numNodes: 25,
  },
  [NodeType.PLM]: {
    minID: 76,
    maxID: 85,
    numNodes: 10,
  },
  [NodeType.TW]: {
    minID: 101,
    maxID: 108,
    numNodes: 8,
  },
  [NodeType.SG]: {
    minID: 109,
    maxID: 158,
    numNodes: 50,
  },
} as { [key in NodeType]: NodeIDMap };
