import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Typography, makeStyles, Theme } from '@material-ui/core';

import greySwirlImage from 'images/grey-swirl.svg';
import greySwirlRedXImage from 'images/grey-swirl-red-x.svg';
import coloredSwirlImage from 'images/swirl-colored.svg';
import coloredSwirlBlueImage from 'images/swirl-colored-blue.svg';

import styles from 'styles';

export type LogoType = 'main' | 'redX' | 'main-colored' | 'main-colored-blue';
export type CenterType = 'centerH2' | 'centerHV4' | 'centerHV1';

type Notice1Props = {
  logoType?: LogoType;
  title?: string;
  description?: string;
  buttonText?: string;
  onClick?: () => void;
  linkTo?: string;
  buttonDisable?: boolean;
  type?: CenterType;
};

const useStyles = makeStyles<Theme, Notice1Props>(styles);

function Notice1(props: Notice1Props) {
  const classes = useStyles(props);
  const {
    logoType,
    title,
    description,
    buttonText,
    onClick,
    linkTo,
    buttonDisable,
    type,
  } = props;

  function getLogo() {
    if (logoType === 'main') {
      return <img src={greySwirlImage} alt="grey-swirl" />;
    } else if (logoType === 'redX') {
      return <img src={greySwirlRedXImage} alt="grey-swirl-red-x" />;
    } else if (logoType === 'main-colored') {
      return <img src={coloredSwirlImage} alt="swirl-colored" />;
    } else if (logoType === 'main-colored-blue') {
      return <img src={coloredSwirlBlueImage} alt="swirl-colored-blue" />;
    } else {
      return null;
    }
  }

  function getParentClass() {
    if (type === 'centerH2') {
      return classes.centerH2;
    } else if (type === 'centerHV4') {
      return classes.centerHV4;
    } else {
      // centerHV1 || null
      return classes.centerHV1;
    }
  }

  return (
    <div className={getParentClass()}>
      {logoType && <div style={{ textAlign: 'center' }}>{getLogo()}</div>}
      {title && (
      <Typography color="textSecondary" variant="h4" align="center">
        {title}
      </Typography>
      )}
      {description && (
      <Typography color="textSecondary" variant="subtitle1" align="center">
        {description}
      </Typography>
      )}
      {buttonText && (onClick ? (
        <Button
          color="primary"
          disabled={buttonDisable}
          variant="contained"
          onClick={onClick}
          className={classes.mt2}
        >
          {buttonText}
        </Button>
      ) : (
        <Button
          color="primary"
          disabled={buttonDisable}
          variant="contained"
          component={Link}
          to={linkTo!}
          className={classes.mt2}
        >
          {buttonText}
        </Button>
      ))}
    </div>
  );
}

export default Notice1;
