import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'reduxx';

import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, Theme,
} from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';

import { closeDialog } from 'reduxx/actions/app';
import { set2FAEnabled } from 'reduxx/actions/user';

import process2FAuth from 'utils/process2FAuth';
import { auth } from 'utils/firebase';
import handleEnterEscapeKey from 'utils/handleEnterEscapeKey';

type MfaEnrollDialogProps = {};

const useStyles = makeStyles<Theme, MfaEnrollDialogProps>((theme) => ({
  recaptchaParent: {
    textAlign: 'center',
  },
  recaptchaWidget: {
    paddingTop: theme.spacing(2),
    display: 'inline-block',
  },
}));

function MfaEnrollDialog(props: MfaEnrollDialogProps) {
  const classes = useStyles(props);

  const data = useSelector((state) => state.app.mfaEnrollDialog);
  const dispatch = useDispatch();
  const { open } = data;

  const [phoneNumber, setPhoneNumber] = useState('');
  const [performingAction, setPerformingAction] = useState(false);
  const [errors, setErrors] = useState<{ phoneNumber?: string[] }>({});
  const recaptchaRef = useRef<HTMLDivElement>(null);

  async function mfaEnroll() {
    const match = phoneNumber.match(/^(\+\d+)[ -]\(?(\d{3})\)?[ -](\d{3})[ -](\d{4})$/);
    if (!match) {
      setErrors({ phoneNumber: ['Invalid phone number'] });
      return;
    }

    setErrors({});
    setPerformingAction(true);

    // Combine phone number to get in format of +12345678901
    const phoneNumber_ = match[1] + match[2] + match[3] + match[4];
    const multiFactorSession = await auth.currentUser!.multiFactor.getSession();

    const result = await process2FAuth({
      dispatch,
      container: recaptchaRef.current!,
      createDiv: false,
      signIn: false,
      phoneNumber: phoneNumber_,
      session: multiFactorSession,
      onClose,
    });

    if (result.success) {
      dispatch(set2FAEnabled(true));
      onClose();
    } else if (result.cancelled) {
      onClose();
    } else {
      setErrors({ phoneNumber: [result.message] });
    }

    setPerformingAction(false);
  }

  function handlePhoneNumberChange(value: string) {
    setPhoneNumber(value);
  }

  function onClose() {
    dispatch(closeDialog({ dialog: 'mfaEnrollDialog' }));
    setPhoneNumber('');
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        onKeyPress={handleEnterEscapeKey(mfaEnroll)}
        scroll="body"
      >
        <DialogTitle>Enter Mobile Number</DialogTitle>

        <DialogContent>
          <MuiPhoneNumber
            color="primary"
            autoFocus
            fullWidth
            required
            type="text"
            label="Phone Number"
            error={!!errors?.phoneNumber}
            helperText={errors?.phoneNumber?.[0]}
            variant="outlined"
            disableDropdown
            defaultCountry="us"
            onChange={handlePhoneNumberChange}
          />
          <div className={classes.recaptchaParent}>
            <div ref={recaptchaRef} className={classes.recaptchaWidget} />
          </div>
        </DialogContent>

        <DialogActions>
          <Button color="primary" disabled={performingAction} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={performingAction}
            variant="contained"
            onClick={mfaEnroll}
          >
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MfaEnrollDialog;
