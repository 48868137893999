import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch as useDispatch_, createSelectorHook } from 'react-redux';

import rootReducer from './reducer';

export type RootState = ReturnType<typeof rootReducer>;

export const useSelector = createSelectorHook<RootState, Action>();

export type ReduxDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
export const useDispatch = () => useDispatch_<ReduxDispatch>();

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
