import { createAction } from '@reduxjs/toolkit';
import api from 'api';

import moment from 'moment';

import { AppThunk } from 'reduxx';
import { CSiteNoID, GraphDuration, Site, SiteTree } from 'types';
import { NodeType } from 'utils/serialUtils';

// Get status indicators for entire site tree
export const getSiteTreeStatusBegin = createAction('GET_SITE_TREE_STATUS_BEGIN');
export const getSiteTreeStatusSuccess = createAction<any>('GET_SITE_TREE_STATUS_SUCCESS');
export const getSiteTreeStatusFailure = createAction<any>('GET_SITE_TREE_STATUS_FAILURE');

// Get status indicator for specific site tree
export const getSiteStatusSuccess = createAction<any>('GET_SITE_STATUS_SUCCESS');
export const getSiteStatusFailure = createAction<any>('GET_SITE_STATUS_FAILURE');

// Get site tree (tree structure, not data)
export const getSiteTreeBegin = createAction('GET_SITE_TREE_BEGIN');
export const getSiteTreeSuccess = createAction<any>('GET_SITE_TREE_SUCCESS');
export const getSiteTreeFailure = createAction<any>('GET_SITE_TREE_FAILURE');

export const updateSiteTreeBegin = createAction('UPDATE_SITE_TREE_BEGIN');
export const updateSiteTreeSuccess = createAction<any>('UPDATE_SITE_TREE_SUCCESS');
export const updateSiteTreeFailure = createAction<any>('UPDATE_SITE_TREE_FAILURE');

export const getAlarmsBegin = createAction<any>('GET_ALARMS_BEGIN');
export const getAlarmsSuccess = createAction<any>('GET_ALARMS_SUCCESS');
export const getAlarmsFailure = createAction<any>('GET_ALARMS_FAILURE');

export const clearAccessCodeStatusBegin = createAction('CLEAR_ACCESS_LEVEL_STATUS_BEGIN');
export const setAccessCodeBegin = createAction('SET_ACCESS_LEVEL_BEGIN');
export const setAccessCodeSuccess = createAction<any>('SET_ACCESS_LEVEL_SUCCESS');
export const setAccessCodeFailure = createAction<any>('SET_ACCESS_LEVEL_FAILURE');

export const deleteZoneSuccess = createAction<any>('DELETE_ZONE_SUCCESS');
export const deleteSiteSuccess = createAction<any>('DELETE_SITE_SUCCESS');

export const setDrawerExists = createAction<boolean>('SET_DRAWER_EXISTS');
export const handleDrawerOpen = createAction('DRAWER_OPEN');
export const handleDrawerClose = createAction('DRAWER_CLOSE');

export const getDashboardBegin = createAction<boolean>('GET_DASHBOARD_BEGIN');
export const getDashboardSuccess = createAction<any>('GET_DASHBOARD_SUCCESS');
export const getDashboardFailure = createAction<any>('GET_DASHBOARD_FAILURE');

export const getSiteGraphsBegin = createAction('GET_SITE_GRAPHS_BEGIN');
export const getSiteGraphsSuccess = createAction<any>('GET_SITE_GRAPHS_SUCCESS');
export const getSiteGraphsFailure = createAction<any>('GET_SITE_GRAPHS_FAILURE');

export const getSiteGraphsDatesSuccess = createAction<any>('GET_SITE_GRAPHS_DATES_SUCCESS');

export const addZoneSuccess = createAction<any>('NEW_ZONE_SUCCESS');
export const updateZoneSuccess = createAction<any>('UPDATE_ZONE_SUCCESS');

export const newSiteSuccess = createAction<any>('NEW_SITE_SUCCESS');
export const addSites = createAction<any>('ADD_SITES');
export const updateSiteSuccess = createAction<any>('UPDATE_SITE_SUCCESS');

export const getSiteInfoBegin = createAction<boolean>('GET_SITE_INFO_BEGIN');
export const getSiteInfoSuccess = createAction<any>('GET_SITE_INFO_SUCCESS');
export const getSiteInfoFailure = createAction<any>('GET_SITE_INFO_FAILURE');

export const handleSearchSiteTreeOn = createAction<any>('SEARCH_SITE_TREE_ON');
export const handleSearchSiteTreeOff = createAction<any>('SEARCH_SITE_TREE_OFF');

export const toggleCollapseSiteTree = createAction<any>('TOGGLE_COLLAPSE_SITE_TREE');

export const openDialog = createAction<any>('OPEN_DIALOG');
export const closeDialog = createAction<any>('CLOSE_DIALOG');
export const closeAllDialogs = createAction('CLOSE_ALL_DIALOGS');

export const getControllerConfigSuccess = createAction<any>('GET_CONTROLLER_CONFIG_SUCCESS');
export const getInstalledNodesSuccess = createAction<any>('GET_INSTALLED_NODES_SUCCESS');

export const updateControllerConfigValue = createAction<any>('UPDATE_CONTROLLER_CONFIG_VALUE');
export const updateInstalledNodeValue = createAction<any>('UPDATE_INSTALLED_NODE_VALUE');

export function getSiteTreeStatus(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(getSiteTreeStatusBegin());

    const state = getState();
    const { sites } = state.app;

    try {
      // Grab a list of all controllers
      const [ids, serialNums] = Object.entries(sites).reduce((acc, [id, site]) => {
        if (site.serialNum) {
          acc[0].push(id);
          acc[1].push(site.serialNum);
        }

        return acc;
      }, [[], []] as [string[], string[]]);

      const response = await api.EZData.status(serialNums);
      dispatch(getSiteTreeStatusSuccess({ data: response.data, ids }));
    } catch (error) {
      dispatch(getSiteTreeStatusFailure(error));
    }
  };
}

export function getSiteStatus(serialNum: string, id: string): AppThunk {
  return async (dispatch) => {
    try {
      const response = await api.EZData.status([serialNum]);
      dispatch(getSiteStatusSuccess({ status: response.data[0], id }));
    } catch (error) {
      dispatch(getSiteStatusFailure({ error, id }));
    }
  };
}

export function deleteZone(id: string): AppThunk {
  return async (dispatch) => {
    try {
      // Delete site
      await api.sites.deleteSite(id);

      dispatch(deleteZoneSuccess({ id }));
    } catch (error) {
      // TODO Do something if fails
    }
  };
}

export function getSiteTrees(): AppThunk {
  return async (dispatch) => {
    dispatch(getSiteTreeBegin());

    try {
      const response = await api.sites.getSiteTree();
      dispatch(getSiteTreeSuccess({ data: response.data }));
    } catch (error) {
      dispatch(getSiteTreeFailure(error));
    }
  };
}

export function updateSiteTree(siteTree: SiteTree): AppThunk {
  return async (dispatch) => {
    dispatch(updateSiteTreeBegin());

    try {
      const response = await api.sites.editTree(siteTree);
      dispatch(updateSiteTreeSuccess({ data: response.data, siteTree }));
    } catch (error) {
      dispatch(updateSiteTreeFailure(error));
    }
  };
}

// export function getAlarms(serialNum: string, id?: string, clearPrevious: boolean = false): AppThunk {
//   return async (dispatch) => {
//     dispatch(getAlarmsBegin(clearPrevious));

//     try {
//       const response = await api.EZData.alarms(serialNum);
//       dispatch(getAlarmsSuccess({ data: response.data, id }));
//     } catch (error) {
//       dispatch(getAlarmsFailure({ error, id }));
//     }
//   };
// }

export function clearAccessCodeStatus(): AppThunk {
  return async (dispatch) => {
    dispatch(clearAccessCodeStatusBegin());
  };
}

export function setAccessCode(serialNum: string, accessCode: string): AppThunk<Promise<any>> {
  return async (dispatch, getState) => {
    dispatch(setAccessCodeBegin());

    try {
      const response = await api.EZData.validateAccessCode(serialNum, accessCode);
      await dispatch(setAccessCodeSuccess({ serialNum, accessCode, data: response.data }));

      const state = getState();
      return state.app.accessLevelStatus;
    } catch (error) {
      await dispatch(setAccessCodeFailure(error));

      const state = getState();
      return state.app.accessLevelStatus;
    }
  };
}

export function deleteSite(id: string): AppThunk {
  return async (dispatch) => {
    try {
      // Delete site
      await api.sites.deleteSite(id);
      dispatch(deleteSiteSuccess({ id }));
    } catch (error) {
      // TODO Do something if fails
    }
  };
}

export function getDashboard(id: string, clearPrevious: boolean = false): AppThunk {
  return async (dispatch, getState) => {
    dispatch(getDashboardBegin(clearPrevious));

    try {
      const response = await api.dashboard.getDashboard(id);
      const { data } = response;

      dispatch(getDashboardSuccess({ data }));
    } catch (error) {
      dispatch(getDashboardFailure(error));
    }
  };
}

export function getSiteGraphs(siteID: string, startTime: moment.Moment, stopTime: moment.Moment,
  duration: GraphDuration = 'daily'): AppThunk {
  return async (dispatch) => {
    dispatch(getSiteGraphsBegin());

    dispatch(getSiteGraphsDates(siteID, startTime, stopTime, duration));

    const getInputs = await api.liveBackend.getInputs(siteID);
    const inputs = getInputs.data?.inputs || [];

    try {
      const response = await api.liveBackend.getGraphs(siteID, inputs, startTime, stopTime);
      dispatch(getSiteGraphsSuccess({ response, siteID, startTime, stopTime }));
    } catch (error) {
      dispatch(getSiteGraphsFailure(error));
    }
  };
}

export function getSiteGraphsDates(siteID: string, startTime: moment.Moment, stopTime: moment.Moment,
  duration: GraphDuration): AppThunk {
  return async (dispatch) => {
    dispatch(getSiteGraphsDatesSuccess({ siteID, startTime, stopTime, duration }));
  };
}

export function newZone(values: CSiteNoID): AppThunk<Promise<any>> {
  return async (dispatch) => {
    const response = await api.sites.addSite(values);
    dispatch(addZoneSuccess({ data: response.data }));

    return response.data;
  };
}

export function updateZone(id: string, changes: Partial<Site>): AppThunk {
  return async (dispatch) => {
    try {
      const response = await api.sites.editSite(id, changes);
      dispatch(updateZoneSuccess({ data: response.data }));
    } catch (error) {
      // TODO Do something if fails
    }
  };
}

export function newSite(values: CSiteNoID): AppThunk<Promise<any>> {
  return async (dispatch) => {
    const response = await api.sites.addSite(values);
    dispatch(newSiteSuccess({ data: response.data }));

    return response.data;
  };
}

export function updateSite(id: string, changes: Partial<Site>): AppThunk {
  return async (dispatch) => {
    const response = await api.sites.editSite(id, changes);
    dispatch(updateSiteSuccess({ data: response.data }));
  };
}

export function getSiteInfo(serialNum: string, clearPrevious: boolean = false): AppThunk {
  return async (dispatch) => {
    dispatch(getSiteInfoBegin(clearPrevious));

    try {
      const response = await api.EZData.siteInfo(serialNum);

      dispatch(getSiteInfoSuccess({ data: response.data }));
    } catch (error) {
      dispatch(getSiteInfoFailure(error));
    }
  };
}

export function getInstalledNodes(serialNum: string, includeNodeTypes: NodeType[]): AppThunk {
  return async (dispatch) => {
    const response = await api.EZData.getInstalledNodes(serialNum, includeNodeTypes);

    dispatch(getInstalledNodesSuccess({ values: response.data.values }));
  };
}

export function getControllerConfig(serialNum: string): AppThunk {
  return async (dispatch) => {
    const response = await api.EZData.getControllerConfig(serialNum);

    dispatch(getControllerConfigSuccess({ data: response.data }));
  };
}
