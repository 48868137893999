import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect, withRouter,
} from 'react-router-dom';
import { compose } from 'recompose';
import { ContextProvider } from 'react-sortly';
import { DndProvider, TouchTransition, MouseTransition } from 'react-dnd-multi-backend';
// eslint-disable-next-line import/no-extraneous-dependencies
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import theme from 'theme';

import _ from 'lodash';

// redux
import { connect, Provider } from 'react-redux';
import { store, RootState } from 'reduxx';

import 'overlayscrollbars/css/OverlayScrollbars.css';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';

// Material-UI
import { createStyles, CssBaseline, Theme } from '@material-ui/core';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Components
import ActionContainer from 'components/actionContainer';
import SiteTreeContainer from 'components/siteTreeContainer';
import Bar from 'components/bar';
import Notice1 from 'components/extras/notice1';
import DialogHost from 'components/dialogHost';
import Footer from 'components/footer';
import LaunchScreen from 'components/launchScreen';
import Home from 'components/home';
import SiteMain from 'components/siteMain';
import NoSitesInfo from 'components/noSitesInfo';
import Settings from 'components/settings';
import About from 'components/about';

import {
  getSiteTrees, getSiteTreeStatus, handleDrawerClose, closeAllDialogs,
} from 'reduxx/actions/app';
import { setUser, resetUser } from 'reduxx/actions/user';

import firebase, { auth, analytics } from 'utils/firebase';
import api from 'api';
import { UserData } from 'api/user';
import settings from 'settings';
import globalStyles from 'styles';

import 'utils/number';
import 'utils/string';

auth.useDeviceLanguage();

type AppProps = {
  resetUser: () => Promise<void>;
  setUser: (data: any) => Promise<void>;
  getSiteTrees: () => Promise<void>;
  getSiteTreeStatus: () => Promise<void>;
  handleDrawerClose: () => Promise<void>;
  closeAllDialogs: () => Promise<void>;

  ready: boolean;
  user: firebase.User;
  userData: UserData;
  has2FA: boolean;
  classes: any;
  history: any;
};

type AppState = {
  isPerformingAuthAction: boolean;
  performingAction: boolean;
};

const initialState = {
  performingAction: false,
  isPerformingAuthAction: false,
  emailAddress: '',
};

const dndBackend = {
  backends: [
    {
      // Note: Fixes issue with having v6.0.2 of react-dnd-multi-backend but @types/react-dnd-multi-backend is
      // for version v6.0.0. Once, the @types/react-dnd-multi-backend is updated, updated the package and remove
      // the any cast
      backend: TouchBackend as any,
      options: { enableMouseEvents: false, delayTouchStart: 500 },
      preview: false,
      transition: TouchTransition,
      skipDispatchOnTransition: true,
    },
    {
      backend: HTML5Backend as any,
      transition: MouseTransition,
      skipDispatchOnTransition: true,
    },
  ],
};

const styles = (theme_: Theme) => createStyles({
  ...globalStyles(theme_),

  '@global': {
    'html, body': {
      height: '100%',
    },
    '#root': {
      height: '100%',

      display: 'flex',
      flexDirection: 'column',
    },
    // Special property set by popper.js when a Popper should be hidden
    'div[x-out-of-boundaries]': {
      display: 'none',
    },
  },
  root: {
    display: 'flex',
    backgroundColor: '#F6F6F6',
    flexGrow: 1,
    height: 'calc(100vh - 64px)',
    [theme_.breakpoints.down('xs')]: {
      height: 'calc(100vh - 56px)',
    },

    overflowY: 'auto',
  },
  content: {
    flex: '1 0 auto',
  },
});

class App extends Component<AppProps, AppState> {
  removeAuthObserver?: firebase.Unsubscribe;

  constructor(props: any) {
    super(props);

    this.state = initialState;
  }

  deleteAccount = () => {
    this.setState({
      performingAction: true,
    }, () => {
      api.user.deleteAccount().then(() => {
        this.props.closeAllDialogs();
        this.props.history.push('/');
      }).catch((reason) => {
        const { code, message } = reason;

        switch (code) {
          default:
            // eslint-disable-next-line no-alert
            alert(message);
        }
      }).finally(() => {
        this.setState({
          performingAction: false,
        });
      });
    });
  };

  signOut = () => {
    this.props.handleDrawerClose();

    this.setState({
      isPerformingAuthAction: true,
    }, () => {
      auth.signOut().then(() => {
        this.props.closeAllDialogs();
        this.props.resetUser();
        this.props.history.push('/');
      }).catch((reason) => {
        const { code, message } = reason;

        switch (code) {
          default:
            // eslint-disable-next-line no-alert
            alert(message);
        }
      }).finally(() => {
        this.setState({
          isPerformingAuthAction: false,
        });
      });
    });
  };

  render() {
    const {
      performingAction,
    } = this.state;

    const {
      classes,
      ready,
      user,
      userData,
      has2FA,
    } = this.props;

    // Indicates this is a beta build with all features enabled
    // Only for distributors
    const betaAppUnauthorized = settings.betaBuild && (!userData || !userData.betaUser);

    // Indicates this is a development build and the person is not an early adopter
    const devAppUnauthorized = settings.devBuild && (!userData || !userData.earlyAdopter);

    return (
      <Fragment>
        <Bar performingAction={performingAction} />
        <div className={classes.content}>
          <Switch>
            {/* Show launch screen until Firebase auth is initialized */}
            {!ready && (
              <Route path="*">
                <LaunchScreen />
              </Route>
            )}
            <Route exact path="/auth/setup">
              <Notice1
                logoType="main"
                description="Authentication setup is incomplete."
                buttonText="Go to Settings"
                linkTo="/app/settings"
              />
            </Route>
            <Route exact path="/errors/404">
              <Notice1
                logoType="main"
                title="Content Not Found"
                description="The requested URL was not found on this server."
                linkTo="/"
                buttonText="Go Home"
              />
            </Route>
            <Route exact path="/errors/401-early-adopters">
              <Notice1
                logoType="main-colored"
                title="Early Adopters Only"
                // eslint-disable-next-line max-len
                description="Only early adopters are allowed to try new features. Please contact an administrator to learn more about becoming an early adopter."
              />
            </Route>
            <Route exact path="/errors/401-beta">
              <Notice1
                logoType="main-colored"
                title="Beta Users Only"
                description="Only beta users are allowed here"
              />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            {user && (has2FA && !betaAppUnauthorized && !devAppUnauthorized // Valid access to routes
              ? (
                <Route path="/app">
                  <div className={classes.root}>
                    <SiteTreeContainer />
                    <Switch>
                      <Route exact path="/app/settings">
                        <Settings />
                      </Route>
                      <Route path="/app/site/:siteID">
                        <SiteMain />
                      </Route>
                      <Route exact path="/app/no-sites" component={NoSitesInfo} />
                      { /* Unknown routes go to 404 error page */ }
                      <Redirect to="/errors/404" />
                    </Switch>
                  </div>
                </Route>
              ) : !has2FA ? (
              // No 2FA enabled
                <Route path="/app">
                  <Switch>
                    <Route exact path="/app/settings">
                      <Settings />
                    </Route>
                    <Redirect to="/auth/setup" />
                  </Switch>
                </Route>
              ) : betaAppUnauthorized ? (
                // Beta build and user is not a beta user
                <Route path="/app">
                  <Redirect to="/errors/401-beta" />
                </Route>
              ) : (
                // Dev build and user is not a valid early adopter
                <Route path="/app">
                  <Redirect to="/errors/401-early-adopters" />
                </Route>
              ))}

            <Route path="/action">
              <ActionContainer {...this.props} />
            </Route>

            {/* Unknown routes go to 404 error page */}
            <Redirect to="/errors/404" />
          </Switch>
        </div>

        <Footer />

        <DialogHost performingAction={performingAction}
          dialogs={{
            signUpDialog: {},
            signInDialog: {},

            deleteAccountDialog: {
              deleteAccount: this.deleteAccount,
            },

            mfaEnrollDialog: {},
            mfaVerifyDialog: {},

            signOutDialog: {
              signOut: this.signOut,
            },
          }}
        />
      </Fragment>
    );
  }

  componentDidMount() {
    this.removeAuthObserver = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        this.props.resetUser();
        return;
      }

      try {
        const userDataResponse = await api.user.get();
        const roles = await api.user.getRoles();

        // get firebase 2FA info
        const { enrolledFactors } = firebase.auth().currentUser!.multiFactor;

        // show settings box b/c user's email and phone number arent verified.
        let has2FA = false;
        if (user.emailVerified && !_.isEmpty(enrolledFactors)) {
          has2FA = true;
        }

        // Get site tree and status
        await this.props.getSiteTrees();
        await this.props.getSiteTreeStatus();

        // Set user
        this.props.setUser({
          ready: true,
          user,
          userData: userDataResponse.data,
          roles: roles || [],
          has2FA,
        });

        analytics.setUserId(user.uid);
      } catch (error: any) {
        this.props.resetUser();
        const { code, message } = error;

        switch (code) {
          default:
            // eslint-disable-next-line no-alert
            alert(message);
        }
      }
    }, (error) => {
      this.props.resetUser();
      const { code } = error;
      const { message } = error;

      switch (code) {
        default:
          // eslint-disable-next-line no-alert
          alert(message);
      }
    });
  }

  componentWillUnmount() {
    if (this.removeAuthObserver) {
      this.removeAuthObserver();
    }
  }
}

function mapStateToProps(state: RootState) {
  return {
    ready: state.user.ready,
    user: state.user.user,
    userData: state.user.userData,
    has2FA: state.user.has2FA,
  };
}

const mapDispatchToProps = {
  getSiteTrees,
  getSiteTreeStatus,
  handleDrawerClose,
  closeAllDialogs,
  setUser,
  resetUser,
};

const enhancer = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withRouter);
const AppEnhanced = enhancer(App);

// Helper component that wraps App with some higher-order components
// Helps to alleviate indentation mess in App
// Another benefit is that react-redux's mapStateToProps can be used in App because it's inside Provider
function AppHOC() {
  return (
    <Provider store={store}>
      <DndProvider options={dndBackend}>
        <ContextProvider>
          <Router>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <ConfirmProvider>
                <SnackbarProvider disableWindowBlurListener>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <AppEnhanced />
                  </MuiPickersUtilsProvider>
                </SnackbarProvider>
              </ConfirmProvider>
            </MuiThemeProvider>
          </Router>
        </ContextProvider>
      </DndProvider>
    </Provider>
  );
}

export default AppHOC;
