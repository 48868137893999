import React, { ReactNode } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  FormControlProps,
  InputLabelProps,
  FormHelperTextProps,
} from '@material-ui/core';

type SelectRFFProps = {
  name: string;
  label: ReactNode;
  helperText?: string;
  formControlProps: FormControlProps;
  inputLabelProps: InputLabelProps;
  formHelperTextProps: FormHelperTextProps;
  variant?: 'standard' | 'outlined' | 'filled';
  fullWidth: boolean;
  input: any;
  meta: any;
  children?: ReactNode;
};

function SelectRFF(props: SelectRFFProps) {
  const {
    name,
    label,
    helperText,
    formControlProps,
    inputLabelProps,
    formHelperTextProps,
    variant,
    fullWidth,
    input,
    meta,
    children,
    ...selectProps
  } = props;

  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <FormControl fullWidth={fullWidth} variant={variant} error={showError} {...formControlProps}>
      {!!label && (
        <InputLabel htmlFor={name} {...inputLabelProps}>
          {label}
        </InputLabel>
      )}
      <Select
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        label={label}
        {...selectProps}
      >
        {children}
      </Select>

      {showError ? (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      ) : helperText ? (
        <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

export default SelectRFF;
