import React from 'react';
import { useSelector, useDispatch } from 'reduxx';

import { makeStyles } from '@material-ui/core/styles';

import Footer from 'components/footer';
import Notice1 from 'components/extras/notice1';

import { openDialog } from 'reduxx/actions/app';

import styles from 'styles';

const useStyles = makeStyles(styles);

function NoSitesInfo() {
  const classes = useStyles();

  const masterID = useSelector((state) => state.app.masterID);
  const dispatch = useDispatch();

  function openNewSiteDialog() {
    dispatch(openDialog({ dialog: 'newSiteDialog', siteID: masterID }));
  }

  return (
    <div className={classes.container1}>
      <Notice1
        type="centerHV4"
        title="There are no controllers in your Master List"
        logoType="main-colored"
        onClick={openNewSiteDialog}
        buttonText="Add new site"
      />
      <Footer dashboard />
    </div>
  );
}

export default NoSitesInfo;
