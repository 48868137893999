import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'reduxx';

import { Button } from '@material-ui/core';

import SignUpDialog from 'components/signUpDialog';
import SignInDialog from 'components/signInDialog';
import DeleteAccountDialog from 'components/deleteAccountDialog';
import MfaEnrollDialog from 'components/mfaEnrollDialog';
import { MfaVerifyDialog } from 'components/mfaVerifyDialog';
import AlertDialog from 'components/alertDialog';
import NewZone from 'components/newZone';
import NewSite from 'components/newSite';
import { ReenterPasswordDialog } from 'components/reenterPasswordDialog';

import { closeDialog } from 'reduxx/actions/app';

type DialogHostProps = {
  performingAction: boolean;
  dialogs: any;
};

function DialogHost(props: DialogHostProps) {
  const user = useSelector((state) => state.user.user);
  const signOutDialogState = useSelector((state) => state.app.signOutDialog);
  const dispatch = useDispatch();

  const { performingAction, dialogs } = props;

  const { signUpDialog } = dialogs;
  const { signInDialog } = dialogs;
  const { deleteAccountDialog } = dialogs;
  const { signOutDialog } = dialogs;
  const { mfaEnrollDialog } = dialogs;
  const { mfaVerifyDialog } = dialogs;

  function onCloseSignOutDialog() {
    dispatch(closeDialog({ dialog: 'signOutDialog' }));
  }

  return (
    <Fragment>
      {user ? (
        <Fragment>
          <AlertDialog open={signOutDialogState.open}
            onClose={onCloseSignOutDialog}
            title="Are you sure you want to sign out?"
            dismissiveAction={(
              <Button
                color="primary"
                onClick={onCloseSignOutDialog}
              >
                Cancel
              </Button>
            )}
            confirmingAction={(
              <Button
                color="primary"
                disabled={performingAction}
                variant="contained"
                onClick={signOutDialog.signOut}
              >
                Sign Out
              </Button>
            )}
          />

          <DeleteAccountDialog performingAction={performingAction} {...deleteAccountDialog} />
          <MfaEnrollDialog performingAction={performingAction} {...mfaEnrollDialog} />

          <ReenterPasswordDialog />
        </Fragment>
      ) : (
        <Fragment>
          <SignUpDialog performingAction={performingAction} {...signUpDialog} />
          <SignInDialog performingAction={performingAction} {...signInDialog} />
        </Fragment>
      )}

      <MfaVerifyDialog performingAction={performingAction} {...mfaVerifyDialog} />

      <NewSite />
      <NewZone />
    </Fragment>
  );
}

export default DialogHost;
