import React from 'react';

import { useSelector } from 'reduxx';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import Notice1, { LogoType } from 'components/extras/notice1';

import NetworkConfig from 'components/networkConfig';
import ControllerConfig from 'components/controllerConfig';

import styles from 'styles';
import clsx from 'clsx';

type SettingsDashboardProps = {
  siteID: string;
  controllerSiteID: string;
};

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  accordion: {
    '&.MuiAccordion-root': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      maxWidth: 800,
      width: '100%',
    },
  },
  summaryTitle: {
    '&.MuiTypography-colorPrimary': {
      color: '#2196F3',
    },
  },
  paper: {
    maxWidth: 800,
    width: '100%',
  },
}));

function SettingsDashboard(props: SettingsDashboardProps) {
  const classes = useStyles();
  const { siteID, controllerSiteID } = props;

  const siteTreeStatus = useSelector((state) => state.app.siteTreeStatus);

  const siteStatus = siteTreeStatus[siteID] || 'offline';

  let message: [string, LogoType] | undefined;
  if (siteStatus === 'offline') {
    message = ['Controller is offline', 'redX'];
  } else if (siteStatus === 'unauthorized') {
    message = ['Unauthorized access', 'redX'];
  }

  return message ? (
    <Notice1 title={message[0]} logoType={message[1]} type="centerH2" />
  ) : (
    <div className={classes.root}>
      <Paper className={clsx(classes.paper, classes.mt2, classes.mb2)}>
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div>
              <Typography className={classes.summaryTitle} color="primary" variant="h6">
                Controller Configuration
              </Typography>
              <Typography variant="body2">
                Set up FBW Display Units, SmartFlex Controllers, Pit Level Monitors, and more. You
                will need to enter your controller Access Code.
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <ControllerConfig siteID={controllerSiteID} />
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper className={classes.paper}>
        <Accordion className={classes.accordion} defaultExpanded={false}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div>
              <Typography className={classes.summaryTitle} color="primary" variant="h6">
                Network Configuration
              </Typography>
              <Typography variant="body2">
                Enable and disable nodes on the network by clicking on the cell. This table will not
                indicate which nodes are communicating. You will need to enter your controller
                Access Code.
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <NetworkConfig siteID={controllerSiteID} />
          </AccordionDetails>
        </Accordion>
      </Paper>
    </div>
  );
}

export default SettingsDashboard;
