import React, { useState } from 'react';
import { useDispatch } from 'reduxx';
import { updateInstalledNodeValue } from 'reduxx/actions/app';

import { GetNodesResponseItem } from 'api/EZData';

import { Button, ButtonGroup, Grid, makeStyles, Typography } from '@material-ui/core';

import SetValueDialog from 'components/setValueDialog';

import clsx from 'clsx';
import useAccessLevel from 'utils/useAccessLevel';
import { getDisplayID, formatNodeSerialNum } from 'utils/serialUtils';

import styles from 'styles';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',

    margin: '5px 0',

    [theme.breakpoints.down('md')]: {
      // On smaller screens, take up entire screen
      width: '100%',

      // Remove min width on smaller screens so that scrollbar shows appropriately
      minWidth: 'initial',
    },
  },
  buttonGroup: {
    width: '100%',
    flexWrap: 'wrap',
  },
  button: {
    width: 1,
    borderRadius: 5,
    margin: '6px 4px 6px 0',
    backgroundColor: '#FFFFFF !important',
    color: '#000000 !important',
    boxShadow: `${theme.shadows[3]} !important`,
  },
  installedButton: {
    backgroundColor: '#689F38 !important',
    color: '#FFFFFF !important',
    '&:hover': {
      backgroundColor: '#486F27 !important',
    },
  },
  nodeTypeTitle: {
    fontSize: 18,
    marginBottom: 10,
    color: '#135D9A',
  },
}));

type NetworkConfigCardProps = {
  header: string;
  nodeType: number;
  values: GetNodesResponseItem[];
  siteID: string;
};

function NetworkConfigCard(props: NetworkConfigCardProps) {
  const classes = useStyles(props);

  const dispatch = useDispatch();
  const { header, nodeType, values, siteID } = props;
  const [setValueDialog, setSetValueDialog] = useState<any>({ open: false });

  const accessLevelDataObject = useAccessLevel(siteID);

  function onCloseSetValueDialog() {
    setSetValueDialog({
      open: false,
    });
  }

  function onNodeClick(item: GetNodesResponseItem) {
    setSetValueDialog({
      open: true,
      code: item.installedCode,
      nodeID: item.id,
      value: {
        value: item.installed,
        datatype: ['bool', 'enable'],
      },
    });
  }

  function updateValue(nodeID: number, value: any) {
    dispatch(updateInstalledNodeValue({ nodeID, value }));
  }

  return (
    <Grid className={classes.root}>
      <SetValueDialog
        open={setValueDialog.open}
        code={setValueDialog.code}
        onClose={onCloseSetValueDialog}
        serialNum={setValueDialog.nodeID && formatNodeSerialNum(siteID, setValueDialog.nodeID)}
        value={setValueDialog.value}
        accessCode={accessLevelDataObject.accessCode!}
        updateValue={updateValue}
      />
      <Grid item>
        <Typography variant="h6" color="primary" className={classes.nodeTypeTitle}>
          {header}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ButtonGroup className={classes.buttonGroup}>
          {values.map((value, i) => (
            <Button
              key={i}
              disabled={accessLevelDataObject.accessLevel < 1}
              disableRipple
              disableFocusRipple
              className={clsx(classes.button, {
                [classes.installedButton]: value.installed,
              })}
              onClick={
                accessLevelDataObject.accessLevel > 0
                  ? onNodeClick.bind(null, value)
                  : undefined
              }
              variant="contained"
            >
              {getDisplayID(nodeType, value.id)}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}

export default NetworkConfigCard;
