import React from 'react';

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import clsx from 'clsx';
import moment from 'moment';
import 'moment-duration-format';

import {
  Button, Dialog, DialogContent, DialogActions, makeStyles, MenuItem, InputAdornment, Theme,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import TextFieldRFF from './extras/textFieldRFF';
import SelectRFF from './extras/selectRFF';
import DurationInput from './extras/durationInput';
import RadioGroupRFF, { RadioGroupItem } from './extras/radioGroupRFF';

import { BToString } from 'utils/bToString';
import { getBEnumStrList } from 'utils/bEnumStr';
import { durationDisplayMap, durationFormatIMaskMap, durationParseMap } from 'utils/const';
import { parseNodeSerialNum } from 'utils/serialUtils';

import api from 'api';
import styles from 'styles';

type SetValueDialogProps = {
  open: boolean;
  onClose: () => void;
  serialNum: string;
  accessCode: string;
  code: number;
  value: any;
  updateValue: (nodeID: number, value: any) => void;

};

const useStyles = makeStyles<Theme, SetValueDialogProps>(styles);

function SetValueDialog(props: SetValueDialogProps) {
  const classes = useStyles(props);
  const { open, onClose, serialNum, accessCode, code, value, updateValue } = props;

  async function onSubmit(values: any) {
    const { value: value_ } = values;

    if (!value_) {
      return { value: 'Required' };
    }

    try {
      await api.EZData.setValue(serialNum, code, value_, accessCode);

      // Parse value from string
      let newValue;
      switch (value.datatype?.[0] || '') {
        case 'bool': {
          newValue = value_ === '1';
          break;
        }

        case 'duration': {
          const format = durationParseMap[value.datatype[1]];
          newValue = moment.duration(value_, format as any);
          break;
        }

        case 'int':
        case 'float': {
          newValue = parseFloat(value_);
          break;
        }

        default: {
          newValue = value_;
          break;
        }
      }

      const [, nodeID] = parseNodeSerialNum(serialNum);
      updateValue(nodeID, newValue);
      onClose();

      return undefined;
    } catch (error: any) {
      // Failed to set value, report error to user
      const errorMessage = error?.response?.data?.message || 'Failed to set value';
      return { [FORM_ERROR]: errorMessage };
    }
  }

  function getInputWValue(): [any, React.ReactNode] {
    if (!open) {
      return [{}, null];
    }

    // Get value and field based on datatype
    switch (value?.datatype[0] || '') {
      case 'int':
      case 'float': {
        return [
          {
            value: BToString(value, {
              includeUnits: false,
            }),
          },
          // eslint-disable-next-line react/jsx-key
          <Field
            variant="outlined"
            type="text"
            name="value"
            label="Value"
            InputProps={{
              endAdornment: <InputAdornment position="end">{value.units || ''}</InputAdornment>,
            }}
            fullWidth
            required
            autoFocus
            component={TextFieldRFF}
          />,
        ];
      }
      case 'bool': {
        const itemMap = {
          true: [
            ['1', 'True'],
            ['0', 'False'],
          ],
          yesno: [
            ['1', 'Yes'],
            ['0', 'No'],
          ],
          enable: [
            ['1', 'Enable'],
            ['0', 'Disable'],
          ],
          onoff: [
            ['1', 'On'],
            ['0', 'Off'],
          ],
        } as { [key: string]: [string, string][]};
        const items = itemMap[value.datatype[1]];
        return [
          {
            value: value.value ? '1' : '0',
          },
          // eslint-disable-next-line react/jsx-key
          <Field
            component={RadioGroupRFF}
            name="value"
            className={classes.slimRadioGroup}
            type="text"
          >
            {items.map((item) => (
              <RadioGroupItem key={item[0]} className={classes.radioColorGreen1} value={item[0]}>
                {item[1]}
              </RadioGroupItem>
            ))}
          </Field>,
        ];
      }

      case 'enum': {
        const items = getBEnumStrList(value.datatype[1]);

        if (!items) {
          return [{}, null];
        } else if (items.length > 10) {
          return [
            {
              value: value.value.toString(),
            },
            // eslint-disable-next-line react/jsx-key
            <Field
              component={SelectRFF}
              name="value"
              variant="outlined"
              fullWidth
              required
              className={clsx(classes.slimOutlinedInput, classes.selectWidth1)}
            >
              {items.map((item, index) => (
                <MenuItem key={index} value={index.toString()}>
                  {item}
                </MenuItem>
              ))}
            </Field>,
          ];
        } else {
          return [
            {
              value: value.value.toString(),
            },
            // eslint-disable-next-line react/jsx-key
            <Field
              component={RadioGroupRFF}
              name="value"
              className={classes.slimRadioGroup}
              type="text"
            >
              {items.map((item, index) => (
                <RadioGroupItem
                  key={index}
                  className={classes.radioColorGreen1}
                  value={index.toString()}
                >
                  {item}
                </RadioGroupItem>
              ))}
            </Field>,
          ];
        }
      }

      case 'duration': {
        const durationFormat = durationFormatIMaskMap[value.datatype![1] as string];
        const durationDisplay = durationDisplayMap[value.datatype![1] as string] || '';

        return [
          {
            value: value.value.format(durationFormat, { trim: false }),
          },
          // eslint-disable-next-line react/jsx-key
          <Field
            variant="outlined"
            type="text"
            name="value"
            label="Value"
            InputProps={{
              inputComponent: DurationInput,
              inputProps: {
                mask: durationFormat,
              },
              endAdornment: <InputAdornment position="end">{durationDisplay}</InputAdornment>,
            }}
            fullWidth
            required
            autoFocus
            component={TextFieldRFF}
          />,
        ];
      }

      default: {
        return [
          {
            value: BToString(value),
          },
          // eslint-disable-next-line react/jsx-key
          <Field
            variant="outlined"
            type="text"
            name="value"
            label="Value"
            InputProps={{
              endAdornment: <InputAdornment position="end">{value.units || ''}</InputAdornment>,
            }}
            fullWidth
            required
            autoFocus
            component={TextFieldRFF}
          />,
        ];
      }
    }
  }

  const [initialValues, field] = getInputWValue();

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ submitError, handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              {field}
              {submitError && (
                <Alert style={{ marginTop: 8 }} className={classes.slimAlert} severity="error">
                  {submitError}
                </Alert>
              )}
            </DialogContent>
            <DialogActions style={{ paddingTop: 0 }}>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                color="primary"
                type="submit"
                disabled={submitting || pristine}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}

export default SetValueDialog;
