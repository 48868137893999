import React, { Fragment } from 'react';
import { useSelector } from 'reduxx';

import clsx from 'clsx';

import { Link, makeStyles, Theme } from '@material-ui/core';

import styles from 'styles';

type FooterProps = {
  spacer: boolean;
  dashboard: boolean;
  subtractMargin: boolean;
};

const useStyles = makeStyles<Theme, FooterProps>((theme) => ({
  ...styles(theme),
  spacer: {
    flex: '1 0 auto',
  },
  footer: {
    backgroundColor: '#F2F2F2',
    borderTop: '1px solid #DFE1E5',
    padding: '10px 16px',
    marginTop: theme.spacing(1),

    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexShrink: 0,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },

    // Direct children selector
    '& > *': {
      // Large screens, split content onto one line
      // Split to 2 lines on smaller screens
      flexBasis: '50%',
      [theme.breakpoints.down('xs')]: {
        flexBasis: '100%',
      },
    },

    '& ul': {
      display: 'flex',
      listStyle: 'none',

      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },

      padding: 0,
      margin: 0,
    },
    '& li': {
      marginLeft: 8,
    },
  },
  subtractMargin: {
    marginRight: -16,
    marginBottom: -16,
    marginLeft: -16,
  },
}));

function Footer(props: FooterProps) {
  const classes = useStyles(props);

  const drawerExists = useSelector((state) => state.app.drawer.exists);

  const { dashboard, spacer, subtractMargin } = props;

  // Used to determine if this footer should be displayed
  // Equivalent to: (!dashboard && drawerExists) || (dashboard && !drawerExists)
  if (dashboard !== drawerExists) {
    return null;
  }

  return (
    <Fragment>
      {spacer && <div className={classes.spacer} />}
      <footer className={clsx(classes.footer, subtractMargin && classes.subtractMargin)}>
        <div>&copy; 2020 BECS Technology</div>
        <ul>
          <li>
            <Link href="/about">About</Link>
          </li>
          {/* <li>
            <Link href="/terms">Terms</Link>
          </li>
          <li>
            <Link href="/privacy">Privacy</Link>
          </li> */}
        </ul>
      </footer>
    </Fragment>
  );
}

Footer.defaultProps = {
  spacer: true,
  dashboard: false,
  subtractMargin: false,
};

export default Footer;
