import axios_, { AxiosResponse } from 'axios';
import { auth } from 'utils/firebase';
import settings from 'settings';

const apiUrl = settings.devBuild
  ? 'https://api-srv2.feedlink.live/devLiveBackend'
  : 'https://api-srv2.feedlink.live/liveBackend';
const axios = axios_.create({ baseURL: apiUrl, withCredentials: true });

export interface DashboardCell {
  value: any;

  code?: number;
  datatype?: [string, string?];
  units?: string;
  precision?: number;

  linkSite?: string;
  commsOK?: boolean;
}

export type DashboardCellN = DashboardCell | null;

export interface DashboardItemTable1 {
  type: 'table1';
  title: string;
  headers: DashboardCell[];
  cells: DashboardCellN[][];
}

export interface DashboardItemTable2 {
  type: 'table2';
  title: string;
  headers: DashboardCell[];
  cells: DashboardCellN[][];
}

// Union of possible dashboard items
export type DashboardItem = DashboardItemTable1 | DashboardItemTable2;

export async function getDashboard(id: string): Promise<AxiosResponse<DashboardItem[]>> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();

  return axios.post(`/${id}/dashboard`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
