import axios_, { AxiosResponse } from 'axios';
import { auth } from 'utils/firebase';
import settings from 'settings';
import { CSite, CSiteNoID, CSiteTree, Site, SiteTree } from 'types';

const apiUrl = settings.devBuild
  ? 'https://api-srv2.feedlink.live/devLiveBackend/site'
  : 'https://api-srv2.feedlink.live/liveBackend/site';
const axios = axios_.create({ baseURL: apiUrl, withCredentials: true });

export async function addSite(site: CSiteNoID): Promise<AxiosResponse<CSite>> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();
  return axios.put('/', site, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export async function editSite(id: string, site: Partial<Site>): Promise<AxiosResponse> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();
  return axios.post(`/${id}`, site, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export async function editTree(siteTree: SiteTree): Promise<AxiosResponse> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();
  return axios.post('/tree', {
    tree: siteTree,
  }, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export async function deleteSite(id: string): Promise<AxiosResponse> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();
  return axios.delete(`/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export async function getSite(id: string): Promise<AxiosResponse<CSite>> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();
  return axios.get(`/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export async function getSiteTree(): Promise<AxiosResponse<CSiteTree>> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();
  return axios.post('/', {}, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
