/* eslint-disable no-useless-return */
import * as actions from 'reduxx/actions/user';

import withImmerReducer from 'utils/withImmerReducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserData } from 'api/user';

interface UserState {
  ready: boolean;
  user: firebase.User | null;
  userData: UserData | null;
  roles: string[];
  has2FA: boolean;
}

export const initialState: UserState = {
  ready: false,
  user: null,
  userData: null,
  roles: [],
  has2FA: false,
};

function app(state: UserState, action: PayloadAction<any>) {
  switch (action.type) {
    case actions.setUser.type: {
      Object.assign(state, action.payload);
      return;
    }

    case actions.resetUser.type: {
      Object.assign(state, {
        ready: true,
        user: null,
        userData: null,
        roles: [],
      });
      return;
    }

    case actions.setUserData.type: {
      state.userData = action.payload;
      return;
    }

    case actions.set2FAEnabled.type: {
      state.has2FA = action.payload;
      return;
    }

    default: {
      return;
    }
  }
}

export default withImmerReducer(app, initialState);
