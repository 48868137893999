import { useState, useCallback, RefObject } from 'react';

export default function<T = any>(): [RefObject<T>, (node: T) => void] {
  const [ref, setRef] = useState<RefObject<T>>({ current: null });

  const onRefChange = useCallback((node: T) => {
    setRef({ current: node });
  }, []);

  return [ref, onRefChange];
}
