const settings = {
  credentials: {
    firebase: {
      apiKey: 'AIzaSyB_xgp9rV4N-Pqh_eW4WWDIcVzc5LHB1uY',
      authDomain: 'feedlink-live.firebaseapp.com',
      databaseURL: 'https://feedlink-live.firebaseio.com',
      projectId: 'feedlink-live',
      storageBucket: 'feedlink-live.appspot.com',
      messagingSenderId: '556157276174',
      appId: '1:556157276174:web:e118821ac750ce602dac5f',
      measurementId: 'G-33376MJLYE',
    },
  },
  timers: {
    // Values in milliseconds
    siteTreeIndicators: 120000,
    siteInfo: 120000,
    dashboard: 120000,
  },
  version: '1.0.0',
  betaBuild: false,
  devBuild: false,
};

export default settings;
