import React, { useState, useRef, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'reduxx';

import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button, makeStyles, Theme,
} from '@material-ui/core';

import { openDialog, closeDialog } from 'reduxx/actions/app';

import { ReduxDispatch } from 'reduxx/helpers';

import process2FAuth from 'utils/process2FAuth';
import firebase, { auth } from 'utils/firebase';
import BError from 'utils/bError';
import styles from 'styles';
import handleEnterEscapeKey from 'utils/handleEnterEscapeKey';

type ReenterPasswordDialogProps = {};

const useStyles = makeStyles<Theme, ReenterPasswordDialogProps>((theme) => ({
  ...styles(theme),
  recaptchaParent: {
    textAlign: 'center',
  },
  recaptchaWidget: {
    paddingTop: theme.spacing(2),
    display: 'inline-block',
  },
}));

function reenterPassword(dispatch: ReduxDispatch): Promise<void> {
  return new Promise((resolve, reject) => {
    dispatch(openDialog({
      dialog: 'reenterPasswordDialog',
      resolve,
      reject,
    }));
  });
}

function ReenterPasswordDialog(props: ReenterPasswordDialogProps) {
  const classes = useStyles(props);
  const data = useSelector((state) => state.app.reenterPasswordDialog);
  const dispatch = useDispatch();
  const { open, resolve, reject } = data;

  const [password, setPassword] = useState('');
  const [performingAction, setPerformingAction] = useState(false);
  const [error, setError] = useState('');
  const recaptchaRef = useRef(null);

  async function onSubmit() {
    setPerformingAction(true);

    try {
      await auth.currentUser!.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(auth.currentUser!.email!, password),
      );

      setError('');
    } catch (error_: any) {
      const { code, message } = error_;

      switch (code) {
        case 'auth/wrong-password': {
          setError('Incorrect password');
          setPerformingAction(false);
          return;
        }

        case 'auth/multi-factor-auth-required': {
          const mfaResolver = error_.resolver as firebase.auth.MultiFactorResolver;
          const info = mfaResolver.hints[0] as firebase.auth.PhoneMultiFactorInfo;

          // Handle authenticating via 2FA
          const result = await process2FAuth({
            dispatch,
            container: recaptchaRef.current!,
            createDiv: false,
            signIn: true,
            resolver: mfaResolver,
            phoneInfo: info,
          });

          if (!result.success) {
            if (!result.cancelled) {
              setError(result.message);
            }

            return;
          }

          // No return here, means we were successful
          break;
        }

        default: {
          setError(message);
          setPerformingAction(false);
          break;
        }
      }
    }

    resolve!();
    onClose();
    setPerformingAction(false);
  }

  function handleChangePassword(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  function onClose() {
    dispatch(closeDialog({ dialog: 'reenterPasswordDialog' }));
    setPassword('');
    setPerformingAction(false);
    setError('');

    reject!(new BError('app/user-cancelled', 'User exited password dialog'));
  }

  return (
    <Dialog
      open={open}
      onKeyPress={handleEnterEscapeKey(onSubmit)}
      scroll="body"
    >
      <DialogTitle>Enter Password</DialogTitle>

      <DialogContent>
        <DialogContentText className={classes.mb4}>
          This is a sensitive operation. Please enter your password again
        </DialogContentText>
        <TextField
          autoFocus
          color="primary"
          disabled={performingAction}
          fullWidth
          label="Password"
          error={!!error}
          helperText={error}
          required
          type="password"
          autoComplete="current-password"
          value={password}
          variant="outlined"
          onChange={handleChangePassword}
        />
        <div className={classes.recaptchaParent}>
          <div ref={recaptchaRef} className={classes.recaptchaWidget} />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          disabled={performingAction}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={performingAction}
          variant="contained"
          onClick={onSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { reenterPassword, ReenterPasswordDialog };
