import React, { useState, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'reduxx';
import Spinner from 'react-spinkit';

import validate from 'validate.js';

import {
  Button, Grid, TextField, Typography, makeStyles,
} from '@material-ui/core';

import Notice1 from 'components/extras/notice1';

import useAsyncEffect from 'utils/useAsyncEffect';
import { auth } from 'utils/firebase';

import { openDialog } from 'reduxx/actions/app';

import constraints from 'constraints.json';
import styles from 'styles';

const useStyles = makeStyles(styles);

type ResetPasswordProps = {
  actionCode: string;
};

function ResetPassword(props: ResetPasswordProps) {
  const classes = useStyles(props);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [success, setSuccess] = useState(false);
  const [validCode, setValidCode] = useState(false);
  const [verifiedCode, setVerifiedCode] = useState(false);

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const { actionCode } = props;

  useAsyncEffect(async () => {
    try {
      // Verify the password reset code is valid.
      const email_ = await auth.verifyPasswordResetCode(actionCode);

      setEmail(email_);
      setValidCode(true);
      setVerifiedCode(true);
    } catch (error_: any) {
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
      setError(error_.message);
      setValidCode(false);
      setVerifiedCode(true);
    }
  }, []);

  async function handleResetPassword(event: any) {
    event.preventDefault();
    const newPassword = password;

    const errors = validate({ password, passwordConfirmation }, {
      password: constraints.password,
      passwordConfirmation: constraints.confirmPassword,
    });

    if (errors) {
      const newErrors = errors?.password?.[0]
        || errors?.password?.[1]
        || errors?.passwordConfirmation?.[0]
        || errors?.password?.[1];
      setError(newErrors);
      return;
    }

    try {
      // Save the new password.
      await auth.confirmPasswordReset(actionCode, newPassword);
      setSuccess(true);
      setError('');
    } catch (error_: any) {
      // Error occurred during confirmation. The code might have expired or the
      // password is too weak.
      setError(error_.message);
    }
  }

  function setText(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  function setTextConfirmation(event: ChangeEvent<HTMLInputElement>) {
    setPasswordConfirmation(event.target.value);
  }

  function clearText() {
    setPassword('');
    setPasswordConfirmation('');
  }

  function openSignInDialog() {
    dispatch(openDialog({ dialog: 'signInDialog' }));
  }

  let component;
  if (!verifiedCode) {
    component = <Spinner name="three-bounce" />;
  } else if (success) {
    if (user) {
      component = (
        <Notice1 title="Logged In"
          description="You have succesfully logged in with your new password."
          buttonText="Go to Dashboard"
          linkTo="/"
        />
      );
    } else {
      component = (
        <div className={classes.centerHV1}>
          <Typography color="textSecondary">
            Password changed
            <br />
            You can now sign in with your new password.
          </Typography>
          <br />
          {error && <p className={classes.error}>{error}</p>}
          <Grid container spacing={2}>
            <Grid item xs>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                onClick={openSignInDialog}
              >
                Sign In
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }
  } else if (verifiedCode && validCode) {
    component = (
      <div className={classes.centerHV1}>
        <Typography color="textSecondary">
          Reset your password for
          {' '}
          {email}
          .
        </Typography>
        <br />
        <form onSubmit={handleResetPassword}>
          {error && <div className={classes.error}>{error}</div>}
          <Grid container spacing={6}>
            <Grid item xs>
              <TextField
                onChange={setText}
                value={password}
                type="password"
                placeholder="New password"
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs>
              <TextField
                onChange={setTextConfirmation}
                value={passwordConfirmation}
                type="password"
                placeholder="Confirm password"
                required
              />
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <Button
                color="primary"
                type="submit"
                variant="contained"
              >
                Change Password
              </Button>
            </Grid>
            <Grid item xs>
              <Button onClick={clearText} variant="contained">
                Clear Password
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  } else if (verifiedCode && !validCode) {
    if (user) {
      component = (
        <Notice1
          title="Logged In"
          description="You have succesfully logged in with your new password."
          buttonText="Go to Dashboard"
          linkTo="/"
        />
      );
    } else {
      component = (
        <div className={classes.centerHV1}>
          <Typography color="textSecondary">Try resetting your password again.</Typography>
          {error && <p className={classes.error}>{error}</p>}
          <Grid container spacing={2}>
            <Grid item xs>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                onClick={openSignInDialog}
              >
                Sign In
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }
  } else {
    component = null;
  }

  return component;
}

export default ResetPassword;
