import React, { Fragment, useState, useRef } from 'react';
import { useDispatch } from 'reduxx';

import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import {
  Button,
  Tooltip,
  Popper,
  ClickAwayListener,
  Card,
  CardContent,
  CardActions,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';

import { setAccessCode } from 'reduxx/actions/app';
import { AccessLevelData } from 'reduxx/reducers/app';

import styles from 'styles';

type AccessLevelProps = {
  serialNum: string;
  accessLevelDataObject: AccessLevelData;
};

const useStyles = makeStyles<Theme, AccessLevelProps>((theme) => ({
  ...styles(theme),
  root: {
    zIndex: 1,
  },
  accessButton: {
    display: 'block',
    margin: 'auto',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
  },
}));

function AccessLevel(props: AccessLevelProps) {
  const classes = useStyles(props);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const { serialNum, accessLevelDataObject } = props;

  const dispatch = useDispatch();

  function onToggleDialog() {
    setOpen(!open);
  }

  function onCloseDialog() {
    setOpen(false);
  }

  async function onSubmitAccessCode(values: any) {
    if (!values.accessCode) {
      return { accessCode: 'Required' };
    }

    const accessLevelStatus = await dispatch(setAccessCode(serialNum, values.accessCode));

    if (accessLevelStatus.error) {
      return { accessCode: accessLevelStatus.error };
    } else {
      onCloseDialog();
      return undefined;
    }
  }

  return (
    <Fragment>
      <Typography>Access Level</Typography>
      <ClickAwayListener onClickAway={onCloseDialog}>
        <div>
          <Tooltip title="Set access level">
            <Button
              ref={anchorRef}
              onClick={onToggleDialog}
              variant="outlined"
              color="primary"
              size="small"
              className={classes.accessButton}
            >
              {accessLevelDataObject.accessLevelStr}
            </Button>
          </Tooltip>
          <Popper className={classes.root}
            anchorEl={anchorRef.current}
            open={open}
            placement="bottom-end"
            modifiers={{
              offset: {
                offset: '0px, 8px',
              },
            }}
          >
            <Card className={classes.slimCard}>
              <Form onSubmit={onSubmitAccessCode}
                render={({ handleSubmit, form, submitting }) => (
                  <Fragment>
                    <CardContent>
                      <form onSubmit={handleSubmit}>
                        <Field
                          variant="outlined"
                          type="text"
                          name="accessCode"
                          label="Enter Access Code"
                          fullWidth
                          required
                          autoFocus
                          component={TextField}
                        />
                      </form>
                    </CardContent>
                    <CardActions className={classes.buttonContainer}>
                      <Button onClick={onCloseDialog}>Cancel</Button>
                      <Button color="primary" type="submit" disabled={submitting} onClick={handleSubmit}>
                        Submit
                      </Button>
                    </CardActions>
                  </Fragment>
                )}
              />
            </Card>
          </Popper>
        </div>
      </ClickAwayListener>
    </Fragment>
  );
}

export default AccessLevel;
