import React, { useState, ReactNode, ChangeEvent } from 'react';
import { useDispatch } from 'reduxx';
import { updateControllerConfigValue } from 'reduxx/actions/app';

import {
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';

import SetValueDialog from 'components/setValueDialog';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { BToString } from 'utils/bToString';
import useAccessLevel from 'utils/useAccessLevel';
import { formatNodeSerialNum } from 'utils/serialUtils';

import clsx from 'clsx';
import styles from 'styles';
import { ControllerConfigCell, ControllerConfigRow } from 'api/EZData';

type ControllerConfigTableProps = {
  siteID: string;
  groups?: string[];
  title: string;
  headers: ControllerConfigCell[];
  rows: ControllerConfigRow[];
};

const useStyles = makeStyles<Theme, ControllerConfigTableProps>((theme) => ({
  ...styles(theme),
  root: {
    display: 'flex',

    // On larger screens, take up entire screen
    // Set min/max widths
    width: '100%',
    maxWidth: 850,
    minWidth: 600,

    [theme.breakpoints.down('md')]: {
      // On smaller screens, take up entire screen
      width: '100%',

      // Remove min width on smaller screens so that scrollbar shows appropriately
      minWidth: 'initial',
    },
  },
  paper: {
    width: '100%',

    // Present so shadow is shown
    margin: 4,
  },

  table: {
    minWidth: 600,
  },
  headingText: {
    color: '#2196F3',
    fontSize: 16,
    marginBottom: 12,
    padding: theme.spacing(1, 2, 1, 2),
    whiteSpace: 'nowrap',

    // Prevents I-beam cursor from showing
    cursor: 'default',
  },
  rowText: {
    padding: theme.spacing(0.5, 2),
    color: '#135D9A',
  },
  rowTextSettable: {
    '& span': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '& .MuiSvgIcon-root': {
      borderBottom: '1px solid #707070',
    },
  },
  cell: {
    color: '#707070',
    fontSize: 16,
    marginBottom: 12,
    padding: 'inherit',
    whiteSpace: 'nowrap',
  },
  iconButton: {
    padding: 0,
  },
  iconColorGreen: {
    '& .MuiSvgIcon-root': {
      fill: '#689F38',
    },
  },
  buttonSection: {
    margin: '10px 0 10px 5px',
  },
  toggleButtonGroup: {
    background: '#F1F2F3',
    borderRadius: '50px',
  },
  toggleButton: {
    border: 0,
    color: '#48494A',
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 800,
    height: 32,
    maxWidth: 414,
    padding: '0 18px',
    whiteSpace: 'nowrap',
    '&.Mui-selected': {
      background: '#FFFFFF',
      borderRadius: '50px',
      boxShadow: '0 1px 8px 0 rgb(0 0 0 / 32%)',
      color: '#2196F3',
      cursor: 'not-allowed',
    },
  },
  sticky: {
    background: '#FFFFFF',
    position: 'sticky',
    left: 0,
    zIndex: 99,
  },
}));

function ControllerConfigTable(props: ControllerConfigTableProps) {
  const { headers, rows, siteID, groups, title } = props;

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const accessLevelDataObject = useAccessLevel(siteID);

  const [setValueDialog, setSetValueDialog] = useState<any>({ open: false });
  const [group, setGroup] = useState('Node');

  function getCellValue(cellValue: ControllerConfigCell, row: ControllerConfigRow): ReactNode {
    if (cellValue.value === null) {
      return (
        <span onClick={cellValue.settable ? onCellClick.bind(null, cellValue, row) : undefined}>--</span>
      );
    }

    if (cellValue.datatype && cellValue.datatype[0] === 'bool') {
      return (
        <IconButton
          className={clsx(classes.iconButton, { [classes.iconColorGreen]: cellValue.value })}
          onClick={cellValue.settable
            && accessLevelDataObject.accessLevel > 0 ? onCellClick.bind(null, cellValue, row) : undefined}
          disabled={accessLevelDataObject.accessLevel < 1}
        >
          {cellValue.value ? <CheckIcon /> : <CloseIcon color="secondary" />}
        </IconButton>
      );
    } else {
      return (
        <span onClick={cellValue.settable
        && accessLevelDataObject.accessLevel > 0 ? onCellClick.bind(null, cellValue, row) : undefined}
        >
          {BToString(cellValue) || '--'}
        </span>
      );
    }
  }

  function onCellClick(item: ControllerConfigCell, row: ControllerConfigRow) {
    setSetValueDialog({
      open: true,
      code: item.code,
      value: {
        value: item.value,
        datatype: item.datatype,
        precision: item.precision,
        units: item.units,
      },
      nodeID: row.nodeID,
    });
  }

  function onCloseSetValueDialog() {
    setSetValueDialog({
      open: false,
    });
  }

  function isItemSettable(item: ControllerConfigCell): boolean {
    if (!item.settable) {
      return false;
    } else if (accessLevelDataObject.accessLevel < 1) {
      return false;
    } else {
      return true;
    }
  }

  function updateValue(nodeID: number, value: any) {
    dispatch(updateControllerConfigValue({ nodeID, code: setValueDialog.code, value }));
  }

  function handleChangeGroup(event: ChangeEvent<any>, group: string) {
    if (!group) {
      return;
    }

    setGroup(group);
  }

  return (
    <div className={classes.root}>
      <SetValueDialog
        open={setValueDialog.open}
        onClose={onCloseSetValueDialog}
        serialNum={setValueDialog.nodeID && formatNodeSerialNum(siteID, setValueDialog.nodeID)}
        code={setValueDialog.code}
        accessCode={accessLevelDataObject.accessCode!}
        value={setValueDialog.value}
        updateValue={updateValue}
      />
      <Paper className={classes.paper} elevation={3}>
        <Typography color="primary" variant="h6" className={classes.rowText}>
          {title}
        </Typography>
        {groups && (
          <div className={classes.buttonSection}>
            <ToggleButtonGroup
              className={classes.toggleButtonGroup}
              exclusive
              onChange={handleChangeGroup}
              value={group}
            >
              {groups.map((group_, i) => (
                <ToggleButton
                  className={classes.toggleButton}
                  key={i}
                  selected={group === group_}
                  value={group_}
                >
                  {group_}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        )}
        <OverlayScrollbarsComponent
          options={{
            overflowBehavior: {
              x: 'scroll',
              y: 'hidden',
            },
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headers.map((header, i) => (
                  <TableCell
                    key={i}
                    className={clsx(classes.headingText, {
                      [classes.hiddenD]: header.groups && !header.groups.includes(group),
                      [classes.sticky]: i === 0,
                    })}
                  >
                    {header.value}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                const backgroundColor = i % 2 === 0 ? '#FFFFFF' : '#F2F7FF';
                return (
                  <TableRow
                    key={`row-${i}`}
                    className={classes.rowText}
                    style={{ backgroundColor }}
                  >
                    {row.values.map((item, i) => {
                      const settable = isItemSettable(item);

                      return (
                        <TableCell
                          key={`cell-${i}`}
                          className={clsx(classes.cell, {
                            [classes.rowTextSettable]: settable,
                            [classes.hiddenD]: item.groups && !item?.groups?.includes(group),
                            [classes.sticky]: i === 0,
                          })}
                          style={{ backgroundColor }}
                        >
                          {getCellValue(item, row)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </OverlayScrollbarsComponent>
      </Paper>
    </div>
  );
}

export default ControllerConfigTable;
