import { KeyboardEvent } from 'react';

export default function(enterCallback: () => void, escapeCallback?: () => void, useCtrlEnter: boolean = false) {
  if (useCtrlEnter) {
    return function(event: KeyboardEvent) {
      const noModifiers = !event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey;

      // Handle Esc and Enter keypresses
      if (noModifiers && event.key === 'Escape' && escapeCallback) {
        escapeCallback();
      } else if (event.ctrlKey && event.key === 'Enter') {
        enterCallback();
      }
    };
  } else {
    return function(event: KeyboardEvent) {
      if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
        return;
      }

      // Handle Esc and Enter keypresses
      if (event.key === 'Escape' && escapeCallback) {
        escapeCallback();
      } else if (event.key === 'Enter') {
        enterCallback();
      }
    };
  }
}
