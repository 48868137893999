import React from 'react';

import clsx from 'clsx';

import { Container, Paper, makeStyles, Theme } from '@material-ui/core';

import styles from 'styles';

type AboutProps = {};

const useStyles = makeStyles<Theme, AboutProps>((theme) => ({
  ...styles(theme),
}));

function About(props: AboutProps) {
  const classes = useStyles(props);

  return (
    <Container maxWidth="md">
      <Paper elevation={2} className={clsx(classes.paper, classes.p2)}>
        <h1>Contact us</h1>
        <b>BECS Technology, Inc</b>
        <br />
        10818 Midwest Industrial Dr.
        <br />
        St. Louis, MO 63132
        <br />
        United States
        <br />
        <a href="mailto:BECSysInfo@becs.com">BECSysInfo@becs.com</a>
        <br />
        <h1>Credits</h1>
        <ul>
          <li>
            Icons made by <a href="https://fontawesome.com">Font Awesome</a> (
            <a href="https://fontawesome.com/license">license</a>)
          </li>
          <li>
            Icons made by <a href="https://www.flaticon.com/authors/freepik">Freepik</a> from{' '}
            <a href="https://www.flaticon.com/">FlatIcon</a>
          </li>
          <li>
            Icons made by <a href="https://www.flaticon.com/authors/becris">Becris</a> from{' '}
            <a href="https://www.flaticon.com/">FlatIcon</a>
          </li>
        </ul>
      </Paper>
    </Container>
  );
}

export default About;
