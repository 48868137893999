import axios_ from 'axios';
import { auth } from 'utils/firebase';

import moment from 'moment';

import parseMultipartForm from 'utils/parseMultipartForm';
import settings from 'settings';

const apiUrl = settings.devBuild
  ? 'https://api-srv2.feedlink.live/devLiveBackend'
  : 'https://api-srv2.feedlink.live/liveBackend';

const axios = axios_.create({ baseURL: apiUrl, withCredentials: true });

export async function getInputs(siteID: string) {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();
  return axios.post(`/${siteID}/inputs`, {}, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
}

export async function getGraphs(siteID: string, inputs: number[], startTime: moment.Moment,
  stopTime: moment.Moment): Promise<any> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();

  const values = await Promise.all(inputs.map((input) => axios.post(`/${siteID}/histGraph`, {
    code: input,
    startTime: startTime.clone().startOf('day').format(),
    stopTime: stopTime.clone().endOf('day').format(),
  }, {
    responseType: 'arraybuffer',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })));

  const newGraphs = values.map(async (value) => {
    try {
      const headers = value?.headers;
      const data = value?.data;

      if (headers['content-type'].startsWith('multipart/form-data')) {
        const formData = await parseMultipartForm(headers, data);

        const graphData = formData?.graph;
        const x = new Float32Array(graphData.buffer);

        const {
          name,
          highAlarm,
          lowAlarm,
          units,
          setpoint,
          setpointHigh,
          frequency,
        } = formData.meta;

        const xArray = Array.from(x);

        xArray.unshift(lowAlarm, highAlarm);

        const newArray = xArray.map((array, i) => {
          const toolTipFrequency = frequency * i;

          return {
            // eslint-disable-next-line no-restricted-globals
            value: isNaN(array) ? 0 : array,
            date: i,
            name,
            highAlarm,
            lowAlarm,
            units,
            setpoint,
            setpointHigh,
            frequency,
            toolTipFrequency,
            toolTipDate: startTime.clone().startOf('day'),
          };
        });

        return newArray;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    return [];
  });

  const newGraph = await Promise.all(newGraphs);
  return newGraph.map((graph) => [...graph]);
}
