import React from 'react';

import { SubmissionErrors } from 'final-form';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { Button, Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from '@material-ui/core';

type UnauthorizedDialogProps = {
 open: boolean;
  onSubmit: (values: any) => SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined | void;
  onCancel: () => void;
  initialValues: any;
  container?: any;
};

function UnauthorizedDialog(props: UnauthorizedDialogProps) {
  const { open, onSubmit, onCancel, initialValues, container } = props;

  return (
    <Dialog
      open={open}
      scroll="body" // Setting container allows dialog to be modal within a component
      container={container && (() => container.current)}
      BackdropProps={container && { style: { position: 'absolute' } }}
      style={container && { position: 'absolute' }}
    >
      <DialogTitle>Unauthorized</DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <DialogContent>
            <DialogContentText>
              You no longer have access to this controller. Please enter a new auth code.
            </DialogContentText>

            <form onSubmit={handleSubmit}>
              <Field
                fullWidth
                required
                autoFocus
                name="authCode"
                component={TextField}
                type="text"
                label="Authentication Code"
              />

              <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                  color="primary"
                  type="submit"
                  disabled={submitting || pristine}
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        )}
      />
    </Dialog>
  );
}

export default UnauthorizedDialog;
