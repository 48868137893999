import Busboy from 'busboy';
import { AnyRecord } from 'types';

export default function parseMultipartForm(headers: any, data: any): Promise<any> {
  return new Promise((resolve, reject) => {
    // Create busboy parser
    const busboy = new Busboy({ headers });
    const formData: AnyRecord = {};

    busboy.on('file', (fieldname, file, filename, encoding, mimetype) => {
      const chunks: any[] = [];
      let length = 0;

      file.on('data', (data_: any) => {
        chunks.push(data_);
        length += data_.length;
      });

      file.on('end', () => {
        if (chunks.length === 0) {
          formData[fieldname] = null;
        } else if (chunks.length === 1) {
          formData[fieldname] = new Int8Array(chunks[0]);
        } else {
          const buffer = new Int8Array(length);
          let index = 0;
          for (const chunk of chunks) {
            buffer.set(chunk, index);
            index += chunk.length;
          }

          formData[fieldname] = buffer;
        }
      });
    });

    busboy.on('field', (fieldname, val, fieldnameTruncated, valTruncated, encoding, mimetype) => {
      if (mimetype === 'application/json') {
        formData[fieldname] = JSON.parse(val);
      } else {
        formData[fieldname] = val.toString();
      }
    });

    // Finished parsing data
    busboy.on('finish', () => {
      resolve(formData);
    });

    // Write data
    busboy.write(new Uint8Array(data));
  });
}
