import React, { Fragment, useState, useRef, MouseEvent } from 'react';
import { useDispatch } from 'reduxx';
import api from 'api';
import { useDrag, useDrop } from 'react-sortly';
import { Flipped } from 'react-flip-toolkit';
import { useHistory, useRouteMatch } from 'react-router';

import clsx from 'clsx';
import moment from 'moment';

import {
  Button, IconButton, ClickAwayListener, Divider, ListItemIcon, ListItemText, MenuList, MenuItem, Paper, Popper,
  Theme, Tooltip, Typography, makeStyles, useTheme, useMediaQuery,
} from '@material-ui/core';
import {
  ChevronRight as ChevronRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  MoreVert as MoreVertIcon,
  ControlPoint as ControlPointIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import { useConfirm } from 'material-ui-confirm';

import { deleteZone, handleDrawerClose, openDialog } from 'reduxx/actions/app';

import useAsyncEffect from 'utils/useAsyncEffect';
import { isRCM, parseNodeSerialNum } from 'utils/serialUtils';
import { Point2DOpt, Site, SiteStatus } from 'types';
import styles from '../styles';

type SiteTreeProps = {
  id: string;
  depth: number;
  site: Site;
  onDragBegin: () => void;
  onDragEnd: () => void;
  isDragging: boolean;
  onToggleCollapse: (id: string) => void;
  searchSiteTree: boolean;
  siteTreeStatus: { [key: string]: SiteStatus };
};

const useStyles = makeStyles<Theme, SiteTreeProps>((theme) => ({
  ...styles(theme),
  root: {
    // Hide row if not searching and site is hidden (searching shows ALL sites)
    display: (props) => (!props.searchSiteTree && props.site.hidden ? 'none' : 'initial'),
  },
  siteRowDiv: {
    margin: 4,
    backgroundColor: '#504D4D',
    borderRadius: '4px',
  },
  siteRow: {
    display: 'flex',

    color: '#D8D8D8',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '1px 8px',

    borderWidth: '7px 2px 2px 2px',
    borderStyle: 'solid',

    backgroundColor: '#042140',
    '&:hover': {
      backgroundColor: '#031736',
      color: '#FFFFFF',
    },
  },
  siteRowSelected: {
    backgroundColor: '#135D9A !important',
    color: '#FFFFFF',
  },
  siteRowGood: {
    borderColor: '#2196f3',
  },
  siteRowAlarm: {
    borderColor: '#E41313',
  },
  siteRowOffline: {
    borderColor: '#707070',
  },
  siteRowUnauthorized: {
    borderColor: '#FFD713',
  },
  zoneRowDiv: {
    margin: 4,
  },
  zoneRow: {
    display: 'flex',

    color: '#D8D8D8',
    width: '100%',
    padding: '2px 8px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.30)',
      color: '#FFFFFF',
    },

    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
  },
  zoneRowSelected: {
    backgroundColor: '#135D9A !important',
    color: '#FFFFFF',
  },
  iconButton: {
    color: 'inherit',
    padding: 0,
  },
  siteName: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoTooltip: {
    width: 192,
  },
  infoTooltipHeader: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 500,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  popper: {
    zIndex: 1300,
  },
}));

function SiteTreeComponent(props: SiteTreeProps) {
  const classes = useStyles(props);
  const history = useHistory();

  const dispatch = useDispatch();

  const confirm = useConfirm();

  // Props
  const {
    id,
    depth,
    site,
    onDragBegin,
    onDragEnd,
    isDragging,
    onToggleCollapse,
    searchSiteTree,
    siteTreeStatus,
  } = props;
  const { parent, serialNum, nickname, collapsed } = props.site;

  const [zoneMenuOpen, setZoneMenuOpen] = useState(false);
  const [zoneMenuMouseState, setZoneMenuMouseState] = useState<Point2DOpt>({});
  const [tooltipInfo, setTooltipInfo] = useState<{ programVersion?: number; expirationDate?: string}>({});

  const dndRef = useRef(null);
  const [, drag, preview] = useDrag({
    canDrag: () =>
      // Cannot drag master list
      // eslint-disable-next-line implicit-arrow-linebreak
      !!site.parent,
    begin: onDragBegin,
    end: onDragEnd,
  });
  const [, drop] = useDrop();

  drag(drop(preview(dndRef)));

  // Whether or not this item is currently selected
  // Check URL to see if we're on this site
  const match = useRouteMatch<{ siteID: string }>('/app/site/:siteID');
  const selected = match && id === match.params.siteID;

  const theme = useTheme();
  const smallUpDevice = useMediaQuery(theme.breakpoints.up('sm'));
  const mediumUpDevice = useMediaQuery(theme.breakpoints.up('md'));

  const siteStatus = siteTreeStatus[id] || 'offline';

  // Site status text for tooltip for 57w & RCM nodes
  const siteStatusMap = {
    good: 'Online',
    alarm: 'In Alarm',
    offline: 'Offline',
    unauthorized: 'Unauthorized',
  };
  // Site status text for tooltip for RCM master
  const siteStatusMapRCM = {
    good: 'Connected',
    alarm: 'In Alarm',
    offline: 'Offline',
    unauthorized: 'Unauthorized',
  };

  useAsyncEffect(async () => {
    if (serialNum) {
      const response = await api.EZData.siteInfo(serialNum);
      const { programVersion, licenseExpirationDate } = response.data;
      const expirationDate = moment(licenseExpirationDate).format();

      setTooltipInfo({ programVersion, expirationDate: moment(expirationDate).format('L') });
    }
  }, [serialNum]);

  function siteDepthLeftMargin(depth_: number): number {
    if (depth_ === 0) {
      return 4;
    } else if (depth_ === 1) {
      return 4;
    } else if (depth_ === 2) {
      return 18;
    } else if (depth === 3) {
      return 32;
    } else {
      return 0;
    }
  }

  function zoneDepthLeftMargin(depth_: number): number {
    if (depth_ === 0) {
      return 4;
    } else if (depth_ === 1) {
      return 4;
    } else if (depth === 2) {
      return 18;
    } else {
      return 0;
    }
  }

  function onToggleZoneMenu(event: MouseEvent) {
    setZoneMenuOpen(!zoneMenuOpen);

    if (!zoneMenuOpen) {
      setZoneMenuMouseState({ x: event.clientX, y: event.clientY });
    }

    // Stops parent button from receiving click event
    event.stopPropagation();
  }

  function onCloseZoneMenu(event: any) {
    setZoneMenuOpen(false);
  }

  function openContextMenu(event: MouseEvent) {
    event.preventDefault();

    setZoneMenuMouseState({ x: event.clientX, y: event.clientY });
    setZoneMenuOpen(true);
  }

  function onNewSiteClick(event: MouseEvent) {
    dispatch(openDialog({ dialog: 'newSiteDialog', siteID: id }));

    // Stops parent button from receiving click event
    event.stopPropagation();
  }

  function onNewZoneClick(event: MouseEvent) {
    dispatch(openDialog({ dialog: 'newZoneDialog', siteID: id }));

    // Stops parent button from receiving click event
    event.stopPropagation();
  }

  async function onDeleteZoneDashboard() {
    await confirm({
      title: 'Are you sure you want to delete this zone? All its sites will be deleted.',
    });

    dispatch(deleteZone(props.id));
  }

  function onExpandCollapseClick(event: any) {
    onToggleCollapse(id);

    // Stops parent button from receiving click event
    event.stopPropagation();
  }

  function onNestedMouseButtonDown(event: any) {
    // Prevents ripple on parent button
    event.stopPropagation();
  }

  function navigateToSite() {
    // Do nothing if current site is selected
    if (selected) {
      return;
    }

    history.push(`/app/site/${id}`);

    // Close drawer on mobile devices
    if (!mediumUpDevice) {
      dispatch(handleDrawerClose());
    }
  }

  function getZoneButton(isMaster: boolean) {
    return (
      <Button onClick={navigateToSite}
        onContextMenu={openContextMenu} // Note: Span is required because nested buttons is not valid in HTML5
        component="span"
        startIcon={// Don't show expand/collapse icon if searching
          !searchSiteTree && (
            <IconButton
              size="small"
              className={classes.iconButton}
              onMouseDown={onNestedMouseButtonDown}
              onClick={onExpandCollapseClick}
              disableRipple
              disableFocusRipple
            >
              {collapsed
                ? <ChevronRightIcon fontSize="inherit" />
                : <KeyboardArrowDownIcon fontSize="inherit" />}
            </IconButton>
          )
        }
        endIcon={(
          <Fragment>
            <IconButton
              size="small"
              className={classes.iconButton}
              onMouseDown={onNestedMouseButtonDown}
              onClick={onToggleZoneMenu}
            >
              <MoreVertIcon fontSize="inherit" />
            </IconButton>
            <Popper anchorEl={{
              clientWidth: 0,
              clientHeight: 0,
              getBoundingClientRect() {
                return {
                  width: 0,
                  height: 0,
                  top: zoneMenuMouseState.y || 0,
                  bottom: zoneMenuMouseState.y || 0,
                  left: zoneMenuMouseState.x || 0,
                  right: zoneMenuMouseState.x || 0,
                } as any;
              },
            }}
              placement="bottom-start"
              open={zoneMenuOpen}
              className={classes.popper}
              modifiers={{
                offset: {
                  offset: '0px, 12px',
                },
              }}
            >
              <ClickAwayListener onClickAway={onCloseZoneMenu}>
                <Paper elevation={2} className={clsx(classes.paper, classes.menu)}>
                  <MenuList disablePadding>
                    {isMaster ? [
                      <MenuItem key={0} onClick={onNewSiteClick}>
                        <ListItemIcon className={classes.menuListIconSkinny}>
                          <ControlPointIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="New site" />
                      </MenuItem>,
                      <MenuItem key={1} onClick={onNewZoneClick}>
                        <ListItemIcon className={classes.menuListIconSkinny}>
                          <ControlPointIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="New zone" />
                      </MenuItem>,
                    ] : [
                      <MenuItem key={0} onClick={onNewSiteClick}>
                        <ListItemIcon className={classes.menuListIconSkinny}>
                          <ControlPointIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="New site" />
                      </MenuItem>,
                      <MenuItem key={1} onClick={onDeleteZoneDashboard}>
                        <ListItemIcon className={classes.menuListIconSkinny}>
                          <DeleteIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                      </MenuItem>,
                    ]}
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </Fragment>
        )}
        className={clsx(classes.zoneRow, {
          [classes.zoneRowSelected]: selected,
        })}
      >
        <span ref={dndRef} className={classes.siteName}>
          {nickname}
        </span>
      </Button>
    );
  }

  function getSiteTooltip() {
    const isRCM_ = isRCM(serialNum!);

    if (isRCM_) {
      const [serialNumMaster, nodeID] = parseNodeSerialNum(serialNum!);

      if (nodeID > 0) {
        return (
          <Fragment>
            <Typography className={classes.infoTooltipHeader}>{nickname}</Typography>
            <Divider />
            <Typography color="primary" className={classes.pt1}>
              MAC Address
            </Typography>
            <Typography className={clsx(classes.pl0p5, classes.pb1)}>{serialNumMaster}</Typography>
            <Typography color="primary">Node ID</Typography>
            <Typography className={clsx(classes.pl0p5, classes.pb1)}>{nodeID}</Typography>
            <Typography color="primary">Status</Typography>
            <Typography className={clsx(classes.pl0p5, classes.pb1)}>
              {siteStatusMap[siteStatus]}
            </Typography>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Typography className={classes.infoTooltipHeader}>{nickname}</Typography>
            <Divider />
            <Typography color="primary" className={classes.pt1}>
              MAC Address
            </Typography>
            <Typography className={clsx(classes.pl0p5, classes.pb1)}>{serialNum}</Typography>
            <Typography color="primary">Status</Typography>
            <Typography className={clsx(classes.pl0p5, classes.pb1)}>
              {siteStatusMapRCM[siteStatus]}
            </Typography>
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment>
          <Typography className={classes.infoTooltipHeader}>{nickname}</Typography>
          <Divider />
          <Typography color="primary" className={classes.pt1}>
            Site ID
          </Typography>
          <Typography className={clsx(classes.pl0p5, classes.pb1)}>{serialNum}</Typography>
          <Typography color="primary" className={classes.pt1}>
            Version
          </Typography>
          <Typography className={clsx(classes.pl0p5, classes.pb1)}>
            {tooltipInfo.programVersion}
          </Typography>
          <Typography color="primary" className={classes.pt1}>
            License Expiration
          </Typography>
          <Typography className={clsx(classes.pl0p5, classes.pb1)}>
            {tooltipInfo.expirationDate}
          </Typography>
          <Typography color="primary" className={classes.pt1}>
            Status
          </Typography>
          <Typography className={clsx(classes.pl0p5, classes.pb1)}>
            {siteStatusMap[siteStatus]}
          </Typography>
        </Fragment>
      );
    }
  }

  return (
    <Flipped flipId={id}>
      <div className={classes.root}>
        {serialNum ? (
          // site dashboard
          <div className={classes.siteRowDiv} style={{ marginLeft: siteDepthLeftMargin(depth) }}>
            <Tooltip
              placement={smallUpDevice ? 'right' : 'bottom'}
              classes={{
                tooltip: clsx(classes.tooltipLight, classes.infoTooltip, {
                  [classes.hiddenD]: isDragging,
                }),
                arrow: classes.tooltipLightArrow,
              }}
              arrow
              enterDelay={300}
              enterNextDelay={300}
              enterTouchDelay={500}
              disableFocusListener={isDragging}
              disableHoverListener={isDragging}
              disableTouchListener={isDragging}
              PopperProps={{
                modifiers: {
                  // Apply offset so tooltip is aligned to button (removes padding)
                  // Only relevant when tooltip is shown below button
                  offset: !smallUpDevice && {
                    offset: '0px, -16px',
                  },
                  flip: {
                    enabled: false,
                  },
                },
              }} // Note: Right now the tooltip is generated for EVERY site in the site tree
              // For large site trees, this may cause a delay up front. Tooltip doesn't provide a way to dynamically
              // generate content on showing the tooltip. Rather, the solution would be to use a Popper and implement
              // our own logic to show the tooltip after a delay.
              title={getSiteTooltip()}
            >
              <Button className={clsx(classes.siteRow, {
                [classes.siteRowGood]: siteStatus === 'good',
                [classes.siteRowAlarm]: siteStatus === 'alarm',
                [classes.siteRowOffline]: siteStatus === 'offline',
                [classes.siteRowUnauthorized]: siteStatus === 'unauthorized',
                [classes.siteRowSelected]: selected,
              })}
                onClick={navigateToSite}
              >
                <div ref={dndRef} className={classes.siteName}>
                  {nickname}
                </div>
              </Button>
            </Tooltip>
          </div>
        ) : parent ? (
          // zone dashboard
          <div
            className={classes.zoneRowDiv}
            style={{ marginLeft: zoneDepthLeftMargin(depth) }}
            onContextMenu={openContextMenu}
          >
            {getZoneButton(false)}
          </div>
        ) : (
          // master list
          <div className={classes.zoneRowDiv}>{getZoneButton(true)}</div>
        )}
      </div>
    </Flipped>
  );
}

export default SiteTreeComponent;
