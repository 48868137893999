import React, { Fragment, ReactElement, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import {
  ButtonProps,
  ClickAwayListener,
  makeStyles,
  Popper,
  Theme,
  ListItemIcon,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from '@material-ui/core';

import styles from 'styles';

interface BDMenuItem_ {
  name: string;
  icon?: ReactElement;

  externalLink?: boolean;
  to?: any;
  target?: string;
  onClick?: () => void;

  // Whether or not to add a divider above the item
  divide?: boolean;
}

export type BDMenuItem = (BDMenuItem_ | null);

type ButtonDropdownProps = {
  className?: string;
  menuItems: BDMenuItem[];
  children: ReactElement;
} & ButtonProps;

const useStyles = makeStyles<Theme, ButtonDropdownProps>(styles);

function ButtonDropdown(props: ButtonDropdownProps) {
  const classes = useStyles(props);

  const { className, menuItems, children } = props;

  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  function onToggleMenu(event: any) {
    setOpen(!open);
  }

  function onCloseMenu() {
    setOpen(false);
  }

  return (
    <Fragment>
      {React.cloneElement(children, {
        ref,
        onClick: onToggleMenu,
      })}
      <Popper
        anchorEl={ref.current}
        open={open}
        placement="bottom-end"
        disablePortal
        modifiers={{
          offset: {
            offset: '0px, 8px',
          },
        }}
      >
        <ClickAwayListener onClickAway={onCloseMenu}>
          <Paper elevation={2} className={clsx(classes.paper, className)}>
            <MenuList disablePadding>
              {menuItems.map((menuItem, index) => {
                if (!menuItem) {
                  return null;
                }

                let component;
                if (menuItem.to) {
                  if (menuItem.externalLink) {
                    component = (
                      <MenuItem
                        key={index}
                        component="a"
                        href={menuItem.to}
                        target={menuItem.target}
                        onClick={onCloseMenu}
                      >
                        {menuItem.icon && (
                          <ListItemIcon>{menuItem.icon}</ListItemIcon>
                        )}
                        <ListItemText primary={menuItem.name} />
                      </MenuItem>
                    );
                  } else {
                    component = (
                      <MenuItem
                        key={index}
                        component={Link}
                        to={menuItem.to}
                        onClick={onCloseMenu}
                      >
                        {menuItem.icon && (
                          <ListItemIcon>{menuItem.icon}</ListItemIcon>
                        )}
                        <ListItemText primary={menuItem.name} />
                      </MenuItem>
                    );
                  }
                } else {
                  component = (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        onCloseMenu();

                        menuItem.onClick!();
                      }}
                    >
                      {menuItem.icon && (
                        <ListItemIcon>{menuItem.icon}</ListItemIcon>
                      )}
                      <ListItemText primary={menuItem.name} />
                    </MenuItem>
                  );
                }

                if (menuItem.divide) {
                  return (
                    <span key={index}>
                      <Divider />

                      {component}
                    </span>
                  );
                }

                return component;
              })}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Fragment>
  );
}

export default ButtonDropdown;
