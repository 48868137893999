import React, { useState, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'reduxx';

import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Hidden, Box, TextField, Button,
} from '@material-ui/core';

import { closeDialog } from 'reduxx/actions/app';

import handleEnterEscapeKey from 'utils/handleEnterEscapeKey';

type DeleteAccountDialogProps = {
  performingAction: boolean;
  deleteAccount: () => void;
};

function DeleteAccountDialog(props: DeleteAccountDialogProps) {
  const { performingAction, deleteAccount } = props;

  const data = useSelector((state) => state.app.deleteAccountDialog);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { open } = data;

  const [email, setEmail] = useState('');

  function handleSubmit() {
    if (!email || email !== user!.email) {
      return;
    }

    deleteAccount();
  }

  function handleUsernameChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function onClose() {
    dispatch(closeDialog({ dialog: 'deleteAccountDialog' }));
    setEmail('');
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onKeyPress={handleEnterEscapeKey(handleSubmit)}
      scroll="body"
    >
      <DialogTitle>Delete account?</DialogTitle>

      <DialogContent>
        <Box mb={2}>
          <DialogContentText>
            Deleted accounts can’t be recovered. All data associated with your account will be deleted.
          </DialogContentText>

          <DialogContentText>
            Type your email and
            {' '}
            <Hidden xsDown>click</Hidden>
            <Hidden smUp>tap</Hidden>
            {' '}
            to delete your account. This action is irreversible.
          </DialogContentText>
        </Box>

        <TextField
          autoComplete="email"
          autoFocus
          color="secondary"
          disabled={performingAction}
          fullWidth
          label="Username"
          placeholder={user!.email!}
          required
          type="text"
          value={email}
          variant="outlined"
          onChange={handleUsernameChange}
        />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" disabled={performingAction} onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          disabled={performingAction || (email !== user!.email!)}
          variant="contained"
          onClick={deleteAccount}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteAccountDialog;
