export const dayShortNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// Map to parse FCore duration to Moment.js duration
export const durationParseMap = {
  'day:hr': 'd:h',
  'h:m': 'h:m',
  'h:mS': 'h:m',
  'h:m:s': 'h:m:s',
  min: 'm',
  'm:s': 'm:s',
  sec: 's',
} as { [key: string]: string };

// Map to format FCore duration to string
export const durationFormatIMaskMap = {
  'day:hr': 'dd:hh',
  'h:m': 'hh:mm',
  'h:mS': 'hh:mm',
  'h:m:s': 'hh:mm:ss',
  min: 'mmm',
  'm:s': 'mm:ss',
  sec: 'ss',
} as { [key: string]: string };

// Map for displaying FCore duration in a "pretty" way
export const durationDisplayMap = {
  'day:hr': 'd:h',
  'h:m': 'hh:mm',
  'h:mS': 'hh:mm',
  'h:m:s': 'hh:mm:ss',
  min: 'm',
  'm:s': 'm:s',
  sec: 's',
} as { [key: string]: string };

// Map for displaying FCore duration in template string using square brackets
export const durationDisplayBracketMap = {
  'h:m': '[hh]:[mm]',
  'h:mS': '[hh]:[mm]',
  'h:m:s': '[hh]:[mm]:[ss]',
} as { [key: string]: string };
