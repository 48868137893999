import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Spinner from 'react-spinkit';

import { Button, Typography, makeStyles } from '@material-ui/core';

import useAsyncEffect from 'utils/useAsyncEffect';
import { auth } from 'utils/firebase';
import styles from 'styles';

const useStyles = makeStyles(styles);

type VerifyEmailProps = {
  actionCode: string;
};

function VerifyEmail(props: VerifyEmailProps) {
  const classes = useStyles(props);
  const history = useHistory();

  const [error, setError] = useState('');
  const [validCode, setValidCode] = useState(false);
  const [verifiedCode, setVerifiedCode] = useState(false);

  const { actionCode } = props;

  useAsyncEffect(async () => {
    try {
      // Try to apply the email verification code.
      await auth.applyActionCode(actionCode);

      if (auth.currentUser) {
        // Reload user
        await auth.currentUser.reload();

        setError('');
        setValidCode(true);
        setVerifiedCode(true);
        history.push('/app/settings');
      } else {
        // User is not logged in, go to main page
        history.push('/');
      }
    } catch (error_: any) {
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
      setError(error_.message);
      setValidCode(false);
      setVerifiedCode(true);
    }
  }, []);

  let component;
  if (!verifiedCode) {
    component = <Spinner name="three-bounce" />;
  } else if (verifiedCode && validCode) {
    component = (
      <div className={classes.centerHV1}>
        <Typography color="textSecondary">
          Your email has been verified.
        </Typography>
        <br />
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/app/settings"
        >
          Go to Settings
        </Button>
        <br />
        <br />
        <Button
          color="primary"
          component={Link}
          to="/"
          variant="contained"
        >
          Go to Dashboard
        </Button>
      </div>
    );
  } else if (verifiedCode && !validCode) {
    component = (
      <div className={classes.centerHV1}>
        <Typography color="textSecondary">
          Try verifying your email again.
        </Typography>
        {error && <p className={classes.error}>{error}</p>}
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/app/settings"
        >
          Go to Settings
        </Button>
        <br />
        <br />
        <Button
          color="primary"
          component={Link}
          to="/"
          variant="contained"
        >
          Go to Dashboard
        </Button>
      </div>
    );
  } else {
    component = null;
  }

  return component;
}

export default VerifyEmail;
