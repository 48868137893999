import React, { useState } from 'react';
import Spinner from 'react-spinkit';

import { Link } from 'react-router-dom';

import {
  Button, Grid, Typography, makeStyles,
} from '@material-ui/core';

import useAsyncEffect from 'utils/useAsyncEffect';
import { auth } from 'utils/firebase';
import styles from 'styles';

const useStyles = makeStyles(styles);

type RecoverEmailProps = {
  actionCode: string;
};

function RecoverEmail(props: RecoverEmailProps) {
  const classes = useStyles(props);

  const [restoredEmail, setRestoredEmail] = useState('');
  const [error, setError] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [validCode, setValidCode] = useState(false);
  const [verifiedCode, setVerifiedCode] = useState(false);

  const { actionCode } = props;

  useAsyncEffect(async () => {
    try {
      // Verify the password reset code is valid
      const info = await auth.checkActionCode(actionCode);

      // Get the restored email address
      const restoredEmail_ = info.data.email;

      // Revert to the old email
      await auth.applyActionCode(actionCode);

      // Account email reverted to restoredEmail
      setRestoredEmail(restoredEmail_!);
      setValidCode(true);
      setVerifiedCode(true);
    } catch (error_: any) {
      // Invalid or expired action code. Ask user to try to reset the password again.
      setError(error_.message);
      setValidCode(false);
      setVerifiedCode(true);
    }
  }, []);

  async function sendReset() {
    // You might also want to give the user the option to reset their password
    // in case the account was compromised:
    await auth.sendPasswordResetEmail(restoredEmail);

    // Password reset confirmation sent. Ask user to check their email.
    setResetSent(true);
  }

  let component;
  if (!verifiedCode) {
    component = <Spinner name="three-bounce" />;
  } else if (resetSent) {
    component = (
      <div className={classes.centerHV1}>
        <Typography color="textSecondary">Check your email</Typography>
        <Typography color="textSecondary">
          Follow the instructions sent to
          {' '}
          <span>{restoredEmail}</span>
          {' '}
          to recover your password.
        </Typography>
        {error && <p className={classes.error}>{error}</p>}
      </div>
    );
  } else if (verifiedCode && validCode) {
    component = (
      <div className={classes.centerHV1}>
        <Typography>
          Your sign-in email address has been changed back to
          {' '}
          <span>{restoredEmail}</span>
          .
        </Typography>
        <Typography>
          If you did not change your sign-in email, it is possible someone is trying to access your
          account and you should
          {' '}
          <button onClick={sendReset}>change your password right away</button>
          .
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs>
            <Button color="primary" variant="contained" component={Link} to="/app/settings">
              Go to Settings
            </Button>
            <br />
            <br />
            <Button color="primary" component={Link} to="/" variant="contained">
              Go to Dashboard
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  } else if (verifiedCode && !validCode) {
    component = (
      <div className={classes.centerHV1}>
        <Typography color="textSecondary">Unable to update your email address</Typography>
        <Typography color="textSecondary">
          There was a problem changing your sign-in email back.
        </Typography>
        {error && <p className={classes.error}>{error}</p>}
        <Grid container spacing={2}>
          <Grid item xs>
            <Button color="primary" variant="contained" component={Link} to="/app/settings">
              Go to Settings
            </Button>
            <br />
            <br />
            <Button color="primary" component={Link} to="/" variant="contained">
              Go to Dashboard
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    component = null;
  }

  return component;
}

export default RecoverEmail;
