import React, { useState, useMemo, useRef, MouseEvent, ReactNode } from 'react';
import { useSelector } from 'reduxx';
import { Link as RouterLink } from 'react-router-dom';

import clone from 'clone';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';

import { useSnackbar } from 'notistack';

import {
  IconButton,
  makeStyles,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Theme,
  Tooltip,
} from '@material-ui/core';

import {
  GetApp as GetAppIcon,
  PictureAsPdf as PictureAsPdfIcon,
  MoreVert as MoreVertIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';

import Notice1 from 'components/extras/notice1';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ButtonDropdown from 'components/extras/buttonDropdown';

import api from 'api';
import styles from 'styles';
import moment from 'moment';
import { DashboardCell, DashboardCellN, DashboardItem } from 'api/dashboard';
import { BToString } from '../utils/bToString';
import { SysTableValue } from '../types';
import { toISOStringNoMs } from 'utils/moment';

type DashboardTableProps = {
  siteID: string;
  serialNum: string;
  nickname: string;
  item: DashboardItem;
};

interface DashboardCellExt extends DashboardCell {
  rawValue?: any;
}

type DashboardCellNExt = DashboardCellExt | null;

const useStyles = makeStyles<Theme, DashboardTableProps>((theme) => ({
  ...styles(theme),
  root1: {
    display: 'flex',

    // On larger screens, take up half of the screen
    // Set min/max widths
    width: '50%',
    maxWidth: 850,
    minWidth: 600,

    [theme.breakpoints.down('lg')]: {
      // On smaller screens, take up entire screen
      width: '100%',

      // Remove min width on smaller screens so that scrollbar shows appropriately
      minWidth: 'initial',
    },
  },
  root2: {
    // On larger screens, take up half of the screen
    // Set min/max widths
    width: '50%',
    maxWidth: 1200,
    minWidth: 950,

    [theme.breakpoints.down('lg')]: {
      // On smaller screens, take up entire screen
      width: '100%',

      // Remove min width on smaller screens so that scrollbar shows appropriately
      minWidth: 'initial',
    },
  },
  paper: {
    width: '100%',

    // Present so shadow is shown
    margin: 4,
  },
  table1: {
    minWidth: 600,
  },
  headingText: {
    color: '#2196F3',
    fontSize: 16,
    marginBottom: 12,
    padding: theme.spacing(1, 2, 1, 2),
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  cell: {
    color: '#707070',
    marginBottom: 12,
    padding: 'inherit',
    textAlign: 'center',
    fontSize: 16,
  },
  cell2: {
    fontSize: '14px !important',
  },
  cellText: {
    color: '#135D9A',
    padding: theme.spacing(0.5, 2),
    whiteSpace: 'nowrap',
  },
  tooltipContainer: {
    marginLeft: 'auto',
    zIndex: 1,
  },
  tooltip: {
    marginRight: theme.spacing(1),
  },
  dropdownMenu: {
    width: 176,
  },
  helperIcon: {
    fontSize: '1.25rem',
    color: '#FFA726',
    marginBottom: -4,
    marginRight: 4,
  },
}));

function descendingComparator(a: any, b: any, orderBy: number): number {
  const aValue = a[orderBy]?.rawValue;
  const bValue = b[orderBy]?.rawValue;

  if (aValue === bValue) {
    return 0;
  } else if (aValue === undefined) {
    return 1;
  } else if (bValue === undefined) {
    return -1;
  } else if (bValue < aValue) {
    return -1;
  } else if (bValue > aValue) {
    return 1;
  } else {
    return 0;
  }
}

function getComparator(order: 'asc' | 'desc', orderBy: number) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any[], comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function DashboardTable(props: DashboardTableProps) {
  const classes = useStyles(props);
  const tableRef = useRef<HTMLTableElement>(null);

  const { item, nickname, serialNum, siteID } = props;
  const siteInfo = useSelector((state) => state.app.siteInfo);

  const { enqueueSnackbar } = useSnackbar();

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<number>(0);

  function onSortClick(property: number, event: MouseEvent) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function getCellValue(item: DashboardCellN): ReactNode {
    if (!item) {
      return (<span>--</span>);
    }

    const elements = [] as ReactNode[];

    if (item?.linkSite) {
      elements.push((
        <Link key={item.linkSite} to={`/app/site/${item.linkSite}`} component={RouterLink}>
          <Typography noWrap align="center">
            {item?.value || '--'}
          </Typography>
        </Link>
      ));
      return elements;
    }

    const sysTableValue: SysTableValue = {
      datatype: item.datatype,
      precision: item.precision,
      units: item.units,
      value: item.value,
    };

    const value1 = BToString(sysTableValue);

    elements.push((
      item?.commsOK === false
        ? (
          <span key={0}>
            <Tooltip title="Node is having a problem communicating" placement='bottom'>
              <WarningIcon className={classes.helperIcon} />
            </Tooltip>
            {value1 || '--'}
          </span>
        )
        : (
          <span key={0}>
            {value1 || '--'}
          </span>
        )
    ));

    return elements;
  }

  async function exportDashboardReport() {
    try {
      const values = clone(cells);

      // Temporary solution until the reports API is updated
      for (const row of values) {
        for (const cell of row) {
          const sysTableValue: SysTableValue = {
            datatype: cell?.datatype,
            precision: cell?.precision,
            units: cell?.units,
            value: cell?.value,
          };
          cell!.value = BToString(sysTableValue);
        }
      }

      const [reportFilename, reportBlob] = await api.reports.getDashboardReport(siteID, 'pdf', {
        nickname,
        customerName: siteInfo?.customerName ?? '',
        location: siteInfo?.location ?? '',
        siteID: serialNum ?? '',
        timestamp: siteInfo.timestamp?.format() ?? toISOStringNoMs(moment()),
      }, {
        title: item.title,
        values: [item.headers, values],
      });

      if (reportFilename) {
        saveAs(reportBlob, reportFilename);
      } else {
        saveAs(
          reportBlob,
          `${nickname} ${item.title} Report.pdf`,
        );
      }
    } catch (error) {
      enqueueSnackbar('Failed to download report. Please try again later', {
        variant: 'error',
      });
    }
  }

  function exportCSV() {
    if (!tableRef.current) {
      return;
    }

    // Convert table into 2D string array
    const data = [];
    for (const row of tableRef.current.rows) {
      // Use regex replaceAll to remove commas from FBW weights
      const rowData = [];
      for (const cell of row.cells) {
        rowData.push(cell.innerText.replaceAll(/,/g, ''));
      }

      data.push(rowData);
    }

    // Convert 2D array of data to worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Convert worksheet to csv
    const csv = XLSX.utils.sheet_to_csv(ws, { FS: ',\t' });

    // Create blob
    const blob = new Blob([csv], { type: 'application/csv' });
    saveAs(blob, `${nickname}. ${item.title}.csv`);
  }

  const menuItems = [
    {
      name: 'Export to CSV',
      icon: <GetAppIcon color="primary" />,
      onClick: exportCSV,
    },
    {
      name: 'Export to PDF',
      icon: <PictureAsPdfIcon color="primary" />,
      divide: true,
      onClick: exportDashboardReport,
    },
  ];

  const cells = useMemo(() => {
    const cells_: DashboardCellNExt[][] = item.cells ? clone(item.cells) : [];

    const numColumns = item.headers.length || 0;
    for (const row of cells_) {
      if (!row) {
        continue;
      }

      if (row[0]) {
        row[0].rawValue = row[0].value;
      }

      for (let i = 1; i < numColumns; ++i) {
        const row_ = row[i];
        if (row_) {
          switch (row_.datatype?.[0] ?? '') {
            case 'int':
            case 'float': {
              row_.rawValue = parseFloat(row_.value);
              break;
            }

            case 'date': {
              row_.rawValue = moment(row_.value);
              break;
            }

            case 'duration': {
              row_.rawValue = row_.value.hours() * 60 + row_.value.minutes();
              break;
            }

            case 'enum':
            case 'string': {
              row_.rawValue = row_.value;
              break;
            }
          }
        }
      }
    }

    if (orderBy === 0) {
      return cells_;
    }

    return stableSort(cells_, getComparator(order, orderBy)) as DashboardCellExt[][];
  }, [order, orderBy, item]);

  return (
    <div className={clsx({
      [classes.root1]: item.type === 'table1',
      [classes.root2]: item.type === 'table2',
    })}
    >
      {item.cells.length === 0 ? (
        <Notice1 title="No data" logoType="redX" type="centerH2" />
      ) : (
        <Paper className={classes.paper} elevation={2}>
          <OverlayScrollbarsComponent
            options={{
              overflowBehavior: {
                x: 'scroll',
                y: 'hidden',
              },
            }}
          >
            <div className={classes.centerV3}>
              <Typography color="primary" variant="h6" className={classes.cellText}>
                {item.title}
              </Typography>
              <div className={classes.tooltipContainer}>
                <ButtonDropdown menuItems={menuItems} className={classes.dropdownMenu}>
                  <IconButton color="inherit" className={classes.floatRight}>
                    <MoreVertIcon />
                  </IconButton>
                </ButtonDropdown>
              </div>
            </div>
            <Table
              className={clsx({
                [classes.table1]: item.type === 'table1',
              })}
              ref={tableRef}
            >
              <TableHead>
                <TableRow>
                  {item.headers.map((column, i) => (
                    <TableCell
                      key={i}
                      sortDirection={orderBy === i ? order : false}
                      className={classes.headingText}
                    >
                      <TableSortLabel hideSortIcon
                        active={orderBy !== 0 && orderBy === i}
                        direction={orderBy === i ? order : 'asc'}
                        onClick={onSortClick.bind(null, i)}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <Typography align="center" variant="body1">
                          {column?.value}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cells.map((row, i) => {
                  const backgroundColor = i % 2 === 0 ? '' : '#F2F7FF';
                  return (
                    <TableRow
                      key={`row-${i}`}
                      className={classes.cellText}
                      style={{ backgroundColor }}
                    >
                      {row.map((cell, j) => (
                        <TableCell key={`cell-${j}`}
                          className={clsx(classes.cell, {
                            [classes.cell2]: item.type === 'table2',
                          })}
                        >
                          {getCellValue(cell)}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </OverlayScrollbarsComponent>
        </Paper>
      )}
    </div>
  );
}

export default DashboardTable;
