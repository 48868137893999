import React, { useState, ReactNode } from 'react';
import { useSelector, useDispatch } from 'reduxx';
import { useRouteMatch } from 'react-router';
import { Switch, Route, Link, Redirect } from 'react-router-dom';

import clsx from 'clsx';
import {
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  useTheme,
  useMediaQuery,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Edit as EditIcon, Home as HomeIcon } from '@material-ui/icons';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import EditZoneDashboardDialog from 'components/editZoneDashboardDialog';
import Footer from 'components/footer';
import Dashboard from 'components/dashboard';

import { updateZone } from 'reduxx/actions/app';

import useCallbackRef from 'utils/useCallbackRef';
import styles from 'styles';
import { AnyRecord } from 'types';

type ZoneContentProps = {
  siteID: string;
};

const useStyles = makeStyles<Theme, ZoneContentProps>((theme) => ({
  ...styles(theme),
  scrollbar: {
    // Takes remaining space
    flexGrow: 1,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    position: 'relative',

    padding: theme.spacing(2),
  },
  tabParent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tab: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    borderRadius: 8,
  },
  zoneDashboardTab: {
    cursor: 'inherit',
  },
}));

function ZoneContent(props: ZoneContentProps) {
  const classes = useStyles(props);

  const [editZoneDialog, setEditZoneDialog] = useState({ open: false });
  const [rootRef, setRootRef] = useCallbackRef<ReactNode>();

  const dashboard = useSelector((state) => state.app.dashboard);
  const sites = useSelector((state) => state.app.sites);
  const dispatch = useDispatch();

  const { siteID } = props;
  const site = sites[siteID];

  // Match page from URL
  const match = useRouteMatch<{ page: string }>('/app/site/:siteID/:page');

  const theme = useTheme();
  const smallUpDevice = useMediaQuery(theme.breakpoints.up('sm'));

  const baseURL = `/app/site/${siteID}`;
  const tabs = [
    {
      url: 'dashboard',
      label: 'Dashboard',
      icon: <HomeIcon />,
    },
  ];

  async function onOpenEditZoneDialog() {
    setEditZoneDialog({
      open: true,
    });
  }

  function onCloseEditZoneDialog() {
    setEditZoneDialog({
      open: false,
    });
  }

  async function onSubmitEditZoneDialog(values: any) {
    const changesObj: AnyRecord = {};

    // Check if value changed
    if (values.nickname !== site.nickname) {
      changesObj.nickname = values.nickname;
    }

    // Only update zone if something changed
    if (Object.keys(changesObj).length > 0) {
      await dispatch(updateZone(siteID, changesObj));
    }

    onCloseEditZoneDialog();
  }

  // Handle finding selected tab based on URL
  // If root URL is used (i.e. !match), then we redirect to appropriate tab
  //
  // Note: Logic down here b/c all React hooks must unconditionally be used, requirement for hooks
  let selectedTab;
  if (!match) {
    return <Redirect to={`/app/site/${siteID}/dashboard`} />;
  } else {
    // Search tab for matching URL
    for (const [index, tab] of tabs.entries()) {
      if (tab?.url === match.params.page) {
        selectedTab = index;
        break;
      }
    }
  }

  return (
    <OverlayScrollbarsComponent
      ref={setRootRef}
      className={clsx(classes.scrollbar, 'os-host-flexbox')}
      options={{
        sizeAutoCapable: false,
        overflowBehavior: {
          x: 'hidden',
          y: 'scroll',
        },
      }}
    >
      <div className={classes.root}>
        <EditZoneDashboardDialog
          open={editZoneDialog.open}
          onSubmit={onSubmitEditZoneDialog}
          onClose={onCloseEditZoneDialog}
          initialValues={{
            nickname: site.nickname,
          }}
          container={rootRef}
        />
        <Paper className={classes.paperAlt} elevation={0}>
          <Grid container direction="row" alignItems="center">
            <Grid item className={clsx(classes.mr0p5, classes.headingGrey1)}>
              {site.nickname}
            </Grid>
            {!dashboard.loading && (
              <Grid item>
                <Tooltip title="Edit zone">
                  <IconButton className={classes.p0p5} onClick={onOpenEditZoneDialog}>
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>

          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={selectedTab}
            variant="scrollable"
            className={classes.tabParent}
            scrollButtons="auto"
          >
            {tabs.map((tab) => {
              if (!tab) {
                return null;
              }

              return (
                <Tab
                  key={tab.url}
                  component={Link}
                  to={`${baseURL}/${tab.url}`}
                  icon={tab.icon}
                  label={smallUpDevice ? tab.label : null}
                  className={classes.tab}
                />
              );
            })}
          </Tabs>
          <Switch>
            <Route path="/app/site/:siteID/dashboard">
              <Dashboard siteID={siteID} isZone />
            </Route>
            {/* Unknown routes go to 404 error page */}
            <Redirect to="/errors/404" />
          </Switch>
        </Paper>
        <Footer dashboard subtractMargin />
      </div>
    </OverlayScrollbarsComponent>
  );
}

export default ZoneContent;
