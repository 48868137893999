import React from 'react';

import IMask from 'imask';
import { IMaskInput } from 'react-imask';

export default function DurationInput(props: any) {
  const { onChange, ...rest } = props;

  return (
    <IMaskInput
      {...rest}
      placeholderChar="0"
      overwrite
      // Note: imask says NOT to use onChange, rather use onAccept
      onAccept={onChange}
      // Always display placeholder
      lazy={false}
      blocks={{
        dd: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 31,
        },
        d: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 31,
        },

        hh: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 99,
        },
        h: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 23,
        },

        mmm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 999,
        },
        mm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
        },
        m: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
        },

        ss: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
        },
        s: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
        },
      }}
    />
  );
}
