import React from 'react';

import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SubmissionErrors } from 'final-form';

type EditZoneDashboardProps = {
  open: boolean;
  onSubmit: (values: any) => SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined | void;
  onClose: () => void;
  initialValues: any;
  container?: any;
};

function EditZoneDashboardDialog(props: EditZoneDashboardProps) {
  const { open, onSubmit, onClose, initialValues, container } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      scroll="body" // Setting container allows dialog to be modal within a component
      container={container && (() => container.current)}
      BackdropProps={container && { style: { position: 'absolute' } }}
      style={container && { position: 'absolute' }}
    >
      <DialogTitle>Edit Zone</DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container alignItems="flex-start">
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    fullWidth
                    required
                    name="nickname"
                    component={TextField}
                    type="text"
                    label="Zone Nickname"
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose} disabled={submitting}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || pristine}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}

export default EditZoneDashboardDialog;
