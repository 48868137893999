import React, { useState, useRef, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'reduxx';

import clsx from 'clsx';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';

import {
  Dialog, DialogTitle, DialogContent, DialogActions, Typography, Tooltip, IconButton, Hidden, Grid, Button, TextField,
  makeStyles, Theme,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import { closeDialog } from 'reduxx/actions/app';

import constraints from 'constraints.json';
import process2FAuth from 'utils/process2FAuth';
import api from 'api';
import styles from 'styles';
import handleEnterEscapeKey from 'utils/handleEnterEscapeKey';

type SignInDialogProps = {};

const useStyles = makeStyles<Theme, SignInDialogProps>((theme) => ({
  ...styles(theme),
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),

    padding: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: 'auto',
  },

  grid: {
    marginBottom: theme.spacing(2),
  },
  recaptchaParent: {
    textAlign: 'center',
  },
  recaptchaWidget: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function SignInDialog(props: SignInDialogProps) {
  const classes = useStyles(props);

  const [performingAction, setPerformingAction] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [showingRecaptcha, setShowingRecaptcha] = useState(false);
  const recaptchaRef = useRef<HTMLDivElement>(null);

  const data = useSelector((state) => state.app.signInDialog);
  const dispatch = useDispatch();
  const { open } = data;

  const { enqueueSnackbar } = useSnackbar();

  async function resetPassword() {
    const errors_ = validate({ emailAddress }, { emailAddress: constraints.emailAddress });
    if (errors_) {
      setErrors(errors_);
      return;
    }

    setErrors({});
    setPerformingAction(true);

    try {
      await api.user.resetPassword(emailAddress);
      enqueueSnackbar(`Sent password reset email to ${emailAddress}`, {
        variant: 'info',
      });
    } catch (error: any) {
      const { message } = error;
      setErrors({ submitError: [message] });
    }

    setPerformingAction(false);
  }

  async function signIn() {
    if (!emailAddress && !password) {
      return;
    }

    const errors_ = validate({ emailAddress }, { emailAddress: constraints.emailAddress });
    if (errors_) {
      setErrors(errors_);
      return;
    }

    if (!password) {
      setErrors({ password: ['This field is required'] });
      return;
    }

    setErrors({});
    setPerformingAction(true);

    try {
      await api.user.signIn(emailAddress, password);
    } catch (error: any) {
      const { code } = error;
      switch (code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
        case 'auth/wrong-password': {
          setErrors({ submitError: ['Incorrect username or password'] });
          setPerformingAction(false);
          return;
        }

        case 'auth/multi-factor-auth-required': {
          const mfaResolver = error.resolver;
          const info = mfaResolver.hints[0];

          // Handle authenticating via 2FA
          setShowingRecaptcha(true);
          const result = await process2FAuth({
            dispatch,
            container: recaptchaRef.current!,
            createDiv: false,
            signIn: true,
            resolver: mfaResolver,
            phoneInfo: info,
          });
          setShowingRecaptcha(false);

          if (!result.success) {
            if (!result.cancelled) {
              setErrors({ submitError: [result.message] });
            }

            return;
          }

          // No return here, means we were successful
          break;
        }

        default: {
          setErrors({ submitError: ['Something went wrong. Please try again later'] });
          setPerformingAction(false);
          return;
        }
      }
    }

    onClose();
    setPerformingAction(false);
  }

  function handleEmailAddressChange(event: ChangeEvent<HTMLInputElement>) {
    setEmailAddress(event.target.value);
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  function onClose() {
    if (!performingAction) {
      dispatch(closeDialog({ dialog: 'signInDialog' }));
      setEmailAddress('');
      setPassword('');
      setErrors({});
      setShowingRecaptcha(false);
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        scroll="body"
        keepMounted={false}
        open={open}
        onClose={onClose}
        onKeyPress={handleEnterEscapeKey(signIn)}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">Sign in to your account</Typography>

          <Tooltip title="Close">
            <IconButton
              className={classes.closeButton}
              disabled={performingAction}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Hidden xsDown>
            <Grid container direction="row">
              <Grid item xs={7}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs>
                    <TextField
                      autoComplete="email"
                      disabled={performingAction}
                      error={!!errors?.emailAddress}
                      fullWidth
                      helperText={errors?.emailAddress?.[0] || ''}
                      label="Email address"
                      placeholder="john@doe.com"
                      required
                      autoFocus
                      type="email"
                      value={emailAddress}
                      variant="outlined"
                      onChange={handleEmailAddressChange}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      autoComplete="current-password"
                      disabled={performingAction}
                      error={!!errors?.password}
                      fullWidth
                      helperText={errors?.password?.[0] || ''}
                      label="Password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      type="password"
                      value={password}
                      variant="outlined"
                      onChange={handlePasswordChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden smUp>
            <Grid container direction="column" spacing={2}>
              <Grid item xs>
                <TextField
                  autoComplete="email"
                  disabled={performingAction}
                  error={!!errors?.emailAddress}
                  fullWidth
                  helperText={errors?.emailAddress?.[0] || ''}
                  label="Email address"
                  placeholder="john@doe.com"
                  required
                  autoFocus
                  type="email"
                  value={emailAddress}
                  variant="outlined"
                  onChange={handleEmailAddressChange}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  autoComplete="current-password"
                  disabled={performingAction}
                  error={!!errors?.password}
                  fullWidth
                  helperText={errors?.password?.[0] || ''}
                  label="Password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  type="password"
                  value={password}
                  variant="outlined"
                  onChange={handlePasswordChange}
                />
              </Grid>
            </Grid>
          </Hidden>
          {errors?.submitError && (
            <Alert
              style={{ marginTop: 8 }}
              className={classes.slimAlert}
              severity="error"
            >
              {errors?.submitError?.[0] || ''}
            </Alert>
          )}
          <div className={clsx(classes.recaptchaParent, !showingRecaptcha && classes.hiddenD)}>
            <div ref={recaptchaRef} className={classes.recaptchaWidget} />
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            disabled={!emailAddress || performingAction}
            variant="outlined"
            onClick={resetPassword}
          >
            Forgot password
          </Button>
          <Button
            color="primary"
            disabled={!emailAddress || performingAction}
            variant="contained"
            onClick={signIn}
          >
            Sign in
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SignInDialog;
