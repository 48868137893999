import React from 'react';

import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import {
  Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import styles from 'styles';
import { SubmissionErrors } from 'final-form';

type EditSiteDashboardProps = {
  open: boolean;
  onSubmit: (values: any) => SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined | void;
  onClose: () => void;
  initialValues: any;
  container?: any;
};

const useStyles = makeStyles<Theme, EditSiteDashboardProps>(styles);

function EditSiteDashboardDialog(props: EditSiteDashboardProps) {
  const classes = useStyles(props);

  const { open, onSubmit, onClose, initialValues, container } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth="sm"
      fullWidth // Setting container allows dialog to be modal within a component
      container={container && (() => container.current)}
      BackdropProps={container && { style: { position: 'absolute' } }}
      style={container && { position: 'absolute' }}
    >
      <DialogTitle>Edit Site</DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ submitError, handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            {/* DialogContent has additional padding if first child, trick it by putting fake div */}
            <div />
            <DialogContent>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    fullWidth
                    required
                    name="authCode"
                    component={TextField}
                    type="text"
                    label="Authentication Code"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    fullWidth
                    required
                    name="nickname"
                    component={TextField}
                    type="text"
                    label="Site Nickname"
                  />
                </Grid>
                {submitError && (
                  <Grid item xs={12}>
                    <Alert className={classes.slimAlert} severity="error">
                      {submitError}
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose} disabled={submitting}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || pristine}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}

export default EditSiteDashboardDialog;
