import React, { ReactNode, useEffect } from 'react';
import { useSelector, useDispatch } from 'reduxx';

import { CircularProgress, makeStyles, Theme } from '@material-ui/core';

import Notice1, { LogoType } from 'components/extras/notice1';
import DashboardTable from 'components/dashboardTable';

import { getDashboard } from 'reduxx/actions/app';
import { DashboardItem } from 'api/dashboard';

import settings from 'settings';
import styles from 'styles';
import clsx from 'clsx';

type DashboardProps = {
  siteID: string;
  isZone: boolean;
};

const useStyles = makeStyles<Theme, DashboardProps>((theme) => ({
  ...styles(theme),
  actionHeader: {
    justifyContent: 'flex-end',
  },
  itemContainer1: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  itemContainer2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function Dashboard(props: DashboardProps) {
  const classes = useStyles(props);

  const sites = useSelector((state) => state.app.sites);
  const siteTreeStatus = useSelector((state) => state.app.siteTreeStatus);
  const dashboard = useSelector((state) => state.app.dashboard);
  const dispatch = useDispatch();

  const { siteID, isZone } = props;
  const site = sites[siteID];
  const siteStatus = siteTreeStatus[siteID] || 'offline';
  // Whether or not the site is online
  const siteOnline = siteStatus.equals('good', 'alarm');

  function refreshDashboard() {
    dispatch(getDashboard(siteID));
  }

  // Get dashboard
  useEffect(() => {
    if (siteOnline || isZone) {
      dispatch(getDashboard(siteID, true));
    }
  }, [dispatch, siteID, siteOnline, isZone]);

  // Periodically update dashboard
  useEffect(() => {
    if (siteOnline || isZone) {
      const timer = setInterval(refreshDashboard, settings.timers.dashboard);
      return () => clearInterval(timer);
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, siteID, siteOnline, isZone]);

  let message: [string, LogoType] | undefined;
  if (!isZone) {
    if (siteStatus === 'offline') {
      message = ['Controller is offline', 'redX'];
    } else if (siteStatus === 'unauthorized') {
      message = ['Unauthorized access', 'redX'];
    } else if (dashboard.error) {
      message = [dashboard.error, 'redX'];
    }
  }

  function getItem(item: DashboardItem, index: number): ReactNode {
    if (item.type === 'table1' || item.type === 'table2') {
      return (
        <DashboardTable
          key={index}
          siteID={siteID}
          serialNum={site.serialNum!}
          nickname={site.nickname}
          item={item}
        />
      );
    } else {
      return null;
    }
  }

  return message ? (
    <Notice1 title={message[0]} logoType={message[1]} type="centerH2" />
  ) : dashboard.loading ? (
    <div className={classes.centerH3}>
      <CircularProgress color="primary" />
    </div>
  ) : (
    <div className={clsx({
      [classes.itemContainer1]: !isZone,
      [classes.itemContainer2]: isZone,
    })}
    >
      {dashboard.items!.map((item, i) => getItem(item, i))}
    </div>
  );
}

export default Dashboard;
