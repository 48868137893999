import React from 'react';

import { CircularProgress, makeStyles } from '@material-ui/core';

import styles from 'styles';

const useStyles = makeStyles(styles);

function LaunchScreen() {
  const classes = useStyles();

  return (
    <div className={classes.centerHV1}>
      <CircularProgress />
    </div>
  );
}

export default LaunchScreen;
