import axios_, { AxiosResponse } from 'axios';
import { auth } from 'utils/firebase';
import moment from 'moment';
import settings from 'settings';

// Base url for reports scheduler api
const apiUrl = settings.devBuild
  ? 'https://api.srv4.becsys.com/devReportScheduler'
  : 'https://api.srv4.becsys.com/reportScheduler';
const axios = axios_.create({ baseURL: apiUrl, withCredentials: true });

export type ReportTypes = 'summary.daily' | 'summary.weekly' | 'summary.monthly' |
  'zone.daily' | 'zone.weekly' | 'zone.monthly' |
  'testkit.daily' | 'testkit.weekly' | 'testkit.monthly' |
  'alarms.daily' | 'alarms.weekly' | 'alarms.monthly' |
  'params.daily' | 'params.weekly' | 'params.monthly' |
  'extSummary.daily' | 'extSummary.weekly' | 'extSummary.monthly';

export type TriggerTypes = 'time';

export interface InsertTriggerOptions {
  site: string;
  emails: string[];
  report: ReportTypes;
  trigger: {
    type: TriggerTypes;
    cron?: string;
    timezone?: string;
  };
}

export interface ReportTrigger {
  id: string;
  site: string;
  emails: string[];
  report: ReportTypes;
  trigger: {
    type: TriggerTypes;
    cron?: string;
  };
}

export interface DashboardReportSiteInfo {
  nickname: string;
  customerName: string;
  location: string;
  siteID: string;
  timestamp: string;
}

export interface DashboardReportData {
  title: string;
  values: [columns: any[], rows: any[][]];
}

export async function getDashboardReport(site: string, content: string, siteInfo: DashboardReportSiteInfo,
  data: DashboardReportData) {
  const dashboardUrl = settings.devBuild
    ? 'https://api-srv2.feedlink.live/devReports'
    : 'https://api-srv2.feedlink.live/reports';

  const response = await axios_.post(`${dashboardUrl}/dashboard/${site}`, {
    content,
    siteInfo,
    data,
  }, {
    timeout: 5000,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });

  // Grab attachment filename from header info
  const contentDisposition = response.headers['content-disposition'];
  const filenameMatch = contentDisposition?.match(/(filename\*=(.*)''(.*))|(filename="(.*)")/);
  let filename;
  if (filenameMatch) {
    filename = filenameMatch[5];
    if (!filename) {
      // Assume utf-8 encoded filename
      filename = decodeURIComponent(filenameMatch[3]);
    }
  } else {
    filename = '';
  }

  return [filename, new Blob([response.data])];
}

export async function getReport(site: string, reportType: string, startDate: moment.Moment | string,
  stopDate: moment.Moment | string, recipients: string[], content: string): Promise<[string, Blob]> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  // Map moment to ISO8601 string
  if (moment.isMoment(startDate)) {
    startDate = startDate.format('YYYY-MM-DD');
  }

  // Map moment to ISO8601 string
  if (moment.isMoment(stopDate)) {
    stopDate = stopDate.format('YYYY-MM-DD');
  }

  const token = await auth.currentUser.getIdToken();
  const response = await axios_.post(
    `https://api.srv4.becsys.com/${settings.devBuild ? 'devReports' : 'reports'}/${reportType}/${site}`, {
      startDate,
      stopDate,
      recipients,
      content,
    }, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  // Grab attachment filename from header info
  const contentDisposition = response.headers['content-disposition'];
  const filenameMatch = contentDisposition?.match(/filename="?([^"]+)"?/);
  const filename = filenameMatch?.[1];

  return [filename, new Blob([response.data])];
}

export async function getAllSchedReports(): Promise<AxiosResponse<ReportTrigger[]>> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();
  return axios.post('/trigger', {}, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getSchedReports(site: string): Promise<AxiosResponse<ReportTrigger[]>> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();
  return axios.post(`/${site}/trigger`, {}, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function addSchedReport(data: InsertTriggerOptions): Promise<AxiosResponse> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  // Setup request and send to Reports scheduler api
  const token = await auth.currentUser.getIdToken();

  return axios.put('/trigger', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

// Delete a scheduled report
export async function deleteSchedReport(id: string): Promise<AxiosResponse> {
  if (!auth.currentUser) {
    throw new Error('Not logged in');
  }

  const token = await auth.currentUser.getIdToken();
  return axios.delete(`/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}
