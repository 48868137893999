import React, {
  Fragment, useState, useRef, useMemo, ChangeEvent,
} from 'react';
import { useSelector, useDispatch } from 'reduxx';

import clsx from 'clsx';
import validate from 'validate.js';
import moment from 'moment';

import {
  Button, Container, Paper, Typography, Grid, Divider, TextField, Tooltip, IconButton, makeStyles, Theme,
} from '@material-ui/core';
import {
  Edit as EditIcon, Check as CheckIcon, Clear as ClearIcon, Delete as DeleteIcon,
} from '@material-ui/icons';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { reenterPassword } from 'components/reenterPasswordDialog';
import Footer from 'components/footer';

import { openDialog } from 'reduxx/actions/app';
import { setUser, setUserData, set2FAEnabled } from 'reduxx/actions/user';

import process2FAuth from 'utils/process2FAuth';
import firebase from 'utils/firebase';
import handleEnterEscapeKey from 'utils/handleEnterEscapeKey';
import api from 'api';
import constraints from 'constraints.json';
import settings from 'settings';
import styles from 'styles';

type SettingsProps = {};

interface TextFieldInlineEditState {
  editing: boolean;
  value: string;
  error?: string;
  performingAction: boolean;
}

interface TextFieldState {
  value: string;
  error?: string;
}

const useStyles = makeStyles<Theme, SettingsProps>((theme) => ({
  ...styles(theme),
  root: {
    // Takes remaining space
    flexGrow: 1,

    padding: theme.spacing(2),

    overflowX: 'auto',
  },
  pb1I: {
    paddingBottom: '8px !important',
  },
  pb2I: {
    paddingBottom: '16px !important',
  },
  pb4I: {
    paddingBottom: '32px !important',
  },
  textCenterIcon: {
    marginTop: 2,
  },
  emailAddressTextField: {
    flexBasis: 304,
  },
}));

function Settings(props: SettingsProps) {
  const classes = useStyles(props);

  const user = useSelector((state) => state.user.user);
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();

  const firebaseUser = firebase.auth().currentUser;
  const phoneMfaInfo = useMemo(() => {
    if (!firebaseUser) {
      return null;
    }

    // Loop through MFA data and find phone info
    const { enrolledFactors } = firebaseUser.multiFactor;
    for (const factor of enrolledFactors) {
      if (factor.factorId === 'phone') {
        // Return phone MFA info
        return factor as firebase.auth.PhoneMultiFactorInfo;
      }
    }

    return null;
    // Ignore eslint exhaustive-deps because the firebaseUser object doesn't change when 2FA changes, rather just
    // the contents inside of it does
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseUser, firebaseUser?.multiFactor, firebaseUser?.multiFactor.enrolledFactors]);

  const [firstName, setFirstName] = useState<TextFieldInlineEditState>({
    editing: false,
    value: userData!.firstName,
    performingAction: false,
  });
  const [lastName, setLastName] = useState<TextFieldInlineEditState>({
    editing: false,
    value: userData!.lastName,
    performingAction: false,
  });
  const [emailAddress, setEmailAddress] = useState<TextFieldInlineEditState>({
    editing: false,
    value: user!.email!,
    performingAction: false,
  });
  const [sentVerificationEmail, setSentVerificationEmail] = useState(false);
  const [phoneNumberPerformingAction, setPhoneNumberPerformingAction] = useState(false);

  const [oldPassword, setOldPassword] = useState<TextFieldState>({ value: '' });
  const [newPassword, setNewPassword] = useState<TextFieldState>({ value: '' });
  const [confirmPassword, setConfirmPassword] = useState<TextFieldState>({ value: '' });
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const rootRef = useRef(null);

  const [impersonateEmail, setImpersonateEmail] = useState<TextFieldState>({ value: '' });
  const [impersonateUserID, setImpersonateUserID] = useState<TextFieldState>({ value: '' });

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  function handleEditFirstName() {
    setFirstName({
      editing: true,
      value: firstName.value,
      performingAction: false,
    });
  }

  function handleExitEditFirstName() {
    setFirstName({
      editing: false,
      value: userData!.firstName,
      performingAction: false,
    });
  }

  function handleChangeFirstName(event: ChangeEvent<HTMLInputElement>) {
    setFirstName({
      ...firstName,
      value: event.target.value,
    });
  }

  async function handleSubmitFirstName() {
    const errors = validate({ firstName: firstName.value }, { firstName: constraints.firstName });
    if (errors) {
      // Validation error
      setFirstName({
        ...firstName,
        error: errors.firstName[0],
      });
      return;
    }

    // Value didn't change, don't submit anything
    if (firstName.value === userData!.firstName) {
      setFirstName({
        ...firstName,
        editing: false,
        error: undefined,
      });
      return;
    }

    // Set performing action, clear previous error
    setFirstName({
      ...firstName,
      error: undefined,
      performingAction: true,
    });

    let success;
    try {
      await api.user.changeFirstName(firstName.value);
      dispatch(setUserData({ firstName: firstName.value }));

      success = true;
    } catch (error: any) {
      // Show error message in snackbar
      enqueueSnackbar(error.message, {
        variant: 'error',
      });

      success = false;
    }

    // Clear performing action
    setFirstName({
      editing: !success,
      value: firstName.value,
      performingAction: false,
    });
  }

  function handleEditLastName() {
    setLastName({
      editing: true,
      value: lastName.value,
      performingAction: false,
    });
  }

  function handleExitEditLastName() {
    setLastName({
      editing: false,
      value: userData!.lastName,
      performingAction: false,
    });
  }

  function handleChangeLastName(event: ChangeEvent<HTMLInputElement>) {
    setLastName({
      ...lastName,
      value: event.target.value,
    });
  }

  async function handleSubmitLastName() {
    const errors = validate({ lastName: lastName.value }, { lastName: constraints.lastName });
    if (errors) {
      // Validation error
      setLastName({
        ...lastName,
        error: errors.lastName[0],
      });
      return;
    }

    // Value didn't change, don't submit anything
    if (lastName.value === userData!.lastName) {
      setLastName({
        ...lastName,
        editing: false,
        error: undefined,
      });
      return;
    }

    // Set performing action, clear previous error
    setLastName({
      ...lastName,
      error: undefined,
      performingAction: true,
    });

    let success;
    try {
      await api.user.changeLastName(lastName.value);
      dispatch(setUserData({ lastName: lastName.value }));

      success = true;
    } catch (error: any) {
      // Show error message in snackbar
      enqueueSnackbar(error.message, {
        variant: 'error',
      });

      success = false;
    }

    // Clear performing action
    setLastName({
      editing: !success,
      value: lastName.value,
      performingAction: false,
    });
  }

  function handleEditEmailAddress() {
    setEmailAddress({
      editing: true,
      value: emailAddress.value,
      performingAction: false,
    });
  }

  function handleExitEditEmailAddress() {
    setEmailAddress({
      editing: false,
      value: user!.email!,
      performingAction: false,
    });
  }

  function handleChangeEmailAddress(event: ChangeEvent<HTMLInputElement>) {
    setEmailAddress({
      ...emailAddress,
      value: event.target.value,
    });
  }

  async function handleSubmitEmailAddress() {
    const errors = validate({ emailAddress: emailAddress.value }, { emailAddress: constraints.emailAddress });
    if (errors) {
      // Validation error
      setEmailAddress({
        ...emailAddress,
        error: errors.emailAddress[0],
      });
      return;
    }

    // Value didn't change, don't submit anything
    if (emailAddress.value === user!.email) {
      setEmailAddress({
        ...emailAddress,
        editing: false,
        error: undefined,
      });
      return;
    }
    // Set performing action, clear previous error
    setEmailAddress({
      ...emailAddress,
      error: undefined,
      performingAction: true,
    });

    let success;
    try {
      await api.user.changeEmailAddress(emailAddress.value);
      success = true;
    } catch (error: any) {
      const { code, message } = error;

      switch (code) {
        case 'auth/requires-recent-login':
        {
          // Ask user to reenter their password
          try {
            await reenterPassword(dispatch);
            // Submit again after password was reentered
            await handleSubmitEmailAddress();
            return;
          } catch (error_) {
            // Do nothing
            success = false;
          }
          break;
        }

        default:
        {
          enqueueSnackbar(message, {
            variant: 'error',
          });
          success = false;
          break;
        }
      }
    }

    // Clear performing action
    setEmailAddress({
      editing: !success,
      value: emailAddress.value,
      performingAction: false,
    });
  }

  async function handleVerifyEmailAddress() {
    setEmailAddress({
      ...emailAddress,
      performingAction: true,
    });

    try {
      await api.user.verifyEmailAddress();
      setSentVerificationEmail(true);

      enqueueSnackbar('Verification Email Sent', {
        variant: 'info',
      });
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }

    setEmailAddress({
      ...emailAddress,
      performingAction: false,
    });
  }

  async function onMfaUnEnroll() {
    await confirm({ title: 'Are you sure you want to delete your mobile number?' });

    setPhoneNumberPerformingAction(true);

    try {
      await firebaseUser!.multiFactor.unenroll(phoneMfaInfo!);
      dispatch(set2FAEnabled(false));
      enqueueSnackbar('Unenrolled from 2FA', {
        variant: 'info',
      });
    } catch (error: any) {
      const { code, message } = error;

      switch (code) {
        case 'auth/requires-recent-login':
        {
          // Ask user to reenter their password
          try {
            await reenterPassword(dispatch);
            // Submit again after password was reentered
            await onMfaUnEnroll();
            return;
          } catch (error_) { // Do nothing
          }

          break;
        }

        default:
        {
          enqueueSnackbar(message, {
            variant: 'error',
          });
          break;
        }
      }
    }

    setPhoneNumberPerformingAction(false);
  }

  function handleChangeOldPassword(event: ChangeEvent<HTMLInputElement>) {
    setOldPassword({
      ...oldPassword,
      value: event.target.value,
    });
  }

  function handleChangeNewPassword(event: ChangeEvent<HTMLInputElement>) {
    setNewPassword({
      ...newPassword,
      value: event.target.value,
    });
  }

  function handleChangeConfirmPassword(event: ChangeEvent<HTMLInputElement>) {
    setConfirmPassword({
      ...confirmPassword,
      value: event.target.value,
    });
  }

  async function handleUpdatePassword() {
    const errors = validate({
      password: newPassword.value,
      confirmPassword: confirmPassword.value,
    }, {
      password: constraints.password,
      confirmPassword: constraints.confirmPassword,
    });

    if (errors) {
      setOldPassword({
        ...oldPassword,
        error: undefined,
      });
      setNewPassword({
        ...newPassword,
        error: errors?.password?.[0],
      });
      setConfirmPassword({
        ...confirmPassword,
        error: errors?.confirmPassword?.[0],
      });
      return;
    }

    // Check if passwords are the same
    if (oldPassword.value === newPassword.value) {
      setOldPassword({
        ...oldPassword,
        error: undefined,
      });
      setNewPassword({
        ...newPassword,
        error: 'Current and new password are identical',
      });
      setConfirmPassword({
        ...confirmPassword,
        error: undefined,
      });
      return;
    }

    // Clear existing errors & performing action
    setUpdatingPassword(true);
    setOldPassword({
      ...oldPassword,
      error: undefined,
    });
    setNewPassword({
      ...newPassword,
      error: undefined,
    });

    setConfirmPassword({
      ...confirmPassword,
      error: undefined,
    });

    try {
      // Attempt to reauthenticate with user's password
      await firebaseUser!.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(user!.email!,
        oldPassword.value));
    } catch (error: any) {
      const { code } = error;
      switch (code) {
        case 'auth/multi-factor-auth-required':
        {
          const mfaResolver = error.resolver;
          const info = mfaResolver.hints[0];

          // Handle authenticating via 2FA
          const result = await process2FAuth({
            dispatch,
            container: rootRef.current!,
            createDiv: true,
            signIn: true,
            resolver: mfaResolver,
            phoneInfo: info,
          });

          if (!result.success) {
            setUpdatingPassword(false);

            if (!result.cancelled) {
              enqueueSnackbar(result.message, {
                variant: 'error',
              });
            }

            return;
          }

          // No return here, means we were successful
          break;
        }

        default:
        {
          setOldPassword({
            ...oldPassword,
            error: 'Incorrect password',
          });
          setUpdatingPassword(false);
          return;
        }
      }
    }

    try {
      await api.user.changePassword(newPassword.value);

      const user = await api.user.get();
      await dispatch(setUser({ userData: user.data }));

      enqueueSnackbar('Password changed', {
        variant: 'info',
      });

      // Reset text fields
      setOldPassword({ value: '' });
      setNewPassword({ value: '' });
      setConfirmPassword({ value: '' });
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }

    // Clear performing action
    setUpdatingPassword(false);
  }

  function handleChangeImpersonateEmail(event: ChangeEvent<HTMLInputElement>) {
    // Can't have user ID AND email entered, wipe user ID text field
    if (impersonateUserID.value) {
      setImpersonateUserID({
        ...impersonateUserID,
        value: '',
      });
    }

    setImpersonateEmail({
      ...impersonateEmail,
      value: event.target.value,
    });
  }

  function handleChangeImpersonateUserID(event: ChangeEvent<HTMLInputElement>) {
    // Can't have user ID AND email entered, wipe email text field
    if (impersonateEmail.value) {
      setImpersonateEmail({
        ...impersonateEmail,
        value: '',
      });
    }

    setImpersonateUserID({
      ...impersonateUserID,
      value: event.target.value,
    });
  }

  async function handleImpersonateUser() {
    try {
      let data;
      if (impersonateEmail.value) {
        data = await api.user.impersonateUser({ email: impersonateEmail.value });
      } else {
        data = await api.user.impersonateUser({ userID: impersonateUserID.value });
      }

      // Store old refresh token so we can go back after impersonating user
      localStorage.setItem('app.firebase.impersonate.oldRefreshToken', firebaseUser!.refreshToken);

      // Login with the custom token now
      await firebase.auth().signInWithCustomToken(data.token);

      // Refresh page
      window.location.reload();
    } catch (error: any) {
      // Show error message in snackbar
      enqueueSnackbar(error?.response?.data?.message || 'Something went wrong. Please try again later', {
        variant: 'error',
      });
    }
  }

  async function handleDeImpersonateUser() {
    try {
      // Grab refresh token
      const refreshToken = localStorage.getItem('app.firebase.impersonate.oldRefreshToken');

      // The below logic is a special hack to deal with Firebase's obfuscated code
      // Firebase does not provide any public means to signing user in by refresh token
      let found = false;
      for (const [, item] of Object.entries(firebaseUser!)) {
        // Search for StsTokenManager class inside firebase user
        // Only public member is getToken function
        if (item instanceof Object && 'getToken' in item && typeof item.getToken === 'function') {
          // Search for the variable that contains the refresh token
          for (const key_ of Object.keys(item)) {
            if (typeof item[key_] === 'string' && item[key_] === firebaseUser!.refreshToken) {
              item[key_] = refreshToken;
              found = true;
              break;
            }
          }

          if (found) {
            break;
          }
        }
      }

      if (!found) {
        throw new Error('Did not find correct item in Firebase user in order to update refresh token');
      }

      // Force refresh using new refresh token
      await firebaseUser!.getIdToken(true);
      localStorage.removeItem('app.firebase.impersonate.oldRefreshToken');

      // Refresh page
      window.location.reload();
    } catch (error) {
      // Show error message in snackbar
      enqueueSnackbar('Failed to stop impersonating user', {
        variant: 'error',
      });
    }
  }

  function openMfaEnrollDialog() {
    dispatch(openDialog({ dialog: 'mfaEnrollDialog' }));
  }

  return (
    <OverlayScrollbarsComponent className={classes.root}
      options={{
        overflowBehavior: {
          x: 'hidden',
          y: 'scroll',
        },
      }}
    >
      <div ref={rootRef}>
        <Container className={clsx(classes.p0, classes.pb1)} maxWidth="md">
          {/* Sacrificial username input so that autocomplete doesnt do weird things */}
          <input type="text" autoComplete="username" className={classes.hiddenD}></input>
          <Typography className={classes.headingGrey1}>Settings</Typography>
          <Paper elevation={2} className={clsx(classes.paper, classes.p2)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.headingGrey1}>General</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.formLabel}>First Name</Typography>
                <Grid container wrap="nowrap">
                  {firstName.editing ? (
                    <Fragment>
                      <Grid item>
                        <TextField
                          name="fname"
                          variant="outlined"
                          value={firstName.value}
                          disabled={firstName.performingAction}
                          onChange={handleChangeFirstName}
                          onKeyDown={handleEnterEscapeKey(handleSubmitFirstName, handleExitEditFirstName)}
                          className={classes.slimOutlinedInput}
                          error={!!firstName.error}
                          helperText={firstName.error}
                          autoComplete="given-name"
                          autoFocus
                          inputProps={{
                            maxLength: 32,
                          }}
                        />
                      </Grid>
                      <Grid item className={clsx(classes.ml1, classes.mt1)}>
                        <IconButton
                          disabled={firstName.performingAction}
                          onClick={handleSubmitFirstName}
                          className={classes.successText}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                      <Grid item className={clsx(classes.ml1, classes.mt1)}>
                        <IconButton
                          disabled={firstName.performingAction}
                          onClick={handleExitEditFirstName}
                          className={classes.failureText}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid item>
                        <Typography className={classes.textCenterIcon}>
                          {firstName.value || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item className={classes.ml1}>
                        <IconButton disabled={firstName.performingAction} onClick={handleEditFirstName}>
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.formLabel}>Last Name</Typography>
                <Grid container wrap="nowrap">
                  {lastName.editing ? (
                    <Fragment>
                      <Grid item>
                        <TextField
                          name="lname"
                          variant="outlined"
                          value={lastName.value}
                          disabled={lastName.performingAction}
                          onChange={handleChangeLastName}
                          onKeyDown={handleEnterEscapeKey(handleSubmitLastName, handleExitEditLastName)}
                          className={classes.slimOutlinedInput}
                          error={!!lastName.error}
                          helperText={lastName.error}
                          autoComplete="family-name"
                          autoFocus
                          inputProps={{
                            maxLength: 32,
                          }}
                        />
                      </Grid>
                      <Grid item className={clsx(classes.ml1, classes.mt1)}>
                        <IconButton
                          disabled={lastName.performingAction}
                          onClick={handleSubmitLastName}
                          className={classes.successText}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                      <Grid item className={clsx(classes.ml1, classes.mt1)}>
                        <IconButton
                          disabled={lastName.performingAction}
                          onClick={handleExitEditLastName}
                          className={classes.failureText}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid item>
                        <Typography className={classes.textCenterIcon}>
                          {lastName.value || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item className={classes.ml1}>
                        <IconButton disabled={lastName.performingAction} onClick={handleEditLastName}>
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.formLabel}>Email Address</Typography>
                <Grid container wrap="nowrap">
                  {emailAddress.editing ? (
                    <Fragment>
                      <Grid item className={classes.emailAddressTextField}>
                        <TextField
                          name="email"
                          variant="outlined"
                          type="email"
                          value={emailAddress.value}
                          fullWidth
                          disabled={emailAddress.performingAction}
                          onChange={handleChangeEmailAddress}
                          onKeyDown={handleEnterEscapeKey(handleSubmitEmailAddress, handleExitEditEmailAddress)}
                          className={classes.slimOutlinedInput}
                          error={!!emailAddress.error}
                          helperText={emailAddress.error}
                          autoComplete="email"
                          autoFocus
                        />
                      </Grid>
                      <Grid item className={clsx(classes.ml1, classes.mt1)}>
                        <IconButton
                          disabled={emailAddress.performingAction}
                          onClick={handleSubmitEmailAddress}
                          className={classes.successText}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                      <Grid item className={clsx(classes.ml1, classes.mt1)}>
                        <IconButton
                          disabled={emailAddress.performingAction}
                          onClick={handleExitEditEmailAddress}
                          className={classes.failureText}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid item>
                        <Typography className={classes.textCenterIcon}>
                          {emailAddress.value || 'N/A'}
                        </Typography>
                      </Grid>
                      {!user?.emailVerified && (
                      <Grid item className={classes.ml1}>
                        <Button
                          color="primary"
                          disabled={emailAddress.performingAction || sentVerificationEmail}
                          variant="contained"
                          size="small"
                          onClick={handleVerifyEmailAddress}
                        >
                          Verify
                        </Button>
                      </Grid>
                      )}
                      <Grid item className={classes.ml1}>
                        <Tooltip
                          title={
                            phoneMfaInfo?.phoneNumber
                              ? 'You must delete your mobile number before you can change your email address'
                              : 'Edit'
                          }
                        >
                          <span>
                            <IconButton
                              disabled={emailAddress.performingAction || !!phoneMfaInfo?.phoneNumber}
                              onClick={handleEditEmailAddress}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.pb4I}>
                <Typography className={classes.formLabel}>Phone Number</Typography>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography className={classes.textCenterIcon}>
                      {phoneMfaInfo?.phoneNumber}
                    </Typography>
                  </Grid>
                  {phoneMfaInfo ? (
                    <Fragment>
                      <Grid item className={classes.ml1}>
                        <IconButton disabled={phoneNumberPerformingAction} onClick={openMfaEnrollDialog}>
                          <EditIcon />
                        </IconButton>
                      </Grid>
                      <Tooltip title="Unenroll from 2FA">
                        <Grid item className={classes.ml1}>
                          <IconButton disabled={phoneNumberPerformingAction} onClick={onMfaUnEnroll}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Tooltip>
                    </Fragment>
                  ) : (
                    <Grid item className={classes.ml1}>
                      <Button
                        color="primary"
                        disabled={phoneNumberPerformingAction || !user?.emailVerified}
                        variant="contained"
                        size="small"
                        onClick={openMfaEnrollDialog}
                      >
                        Add 2FA
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.headingText6}>Change password</Typography>
              </Grid>
              <Grid item xs={12} className={classes.pb1I}>
                <Typography className={classes.formLabel}>
                  Old password
                </Typography>
                <TextField
                  name="password"
                  variant="outlined"
                  fullWidth
                  className={clsx(classes.slimOutlinedInput, classes.mb1)}
                  value={oldPassword.value}
                  onChange={handleChangeOldPassword}
                  error={!!oldPassword.error}
                  helperText={oldPassword.error}
                  type="password"
                  autoComplete="current-password"
                />
                <Typography className={classes.formLabel}>
                  New password
                </Typography>
                <TextField
                  name="password"
                  variant="outlined"
                  fullWidth
                  className={clsx(classes.slimOutlinedInput, classes.mb1)}
                  value={newPassword.value}
                  onChange={handleChangeNewPassword}
                  error={!!newPassword.error}
                  helperText={newPassword.error}
                  type="password"
                  autoComplete="new-password"
                />
                <Typography className={classes.formLabel}>
                  Confirm new password
                </Typography>
                <TextField

                  name="password"
                  variant="outlined"
                  fullWidth
                  className={clsx(classes.slimOutlinedInput, classes.mb1p5)}
                  value={confirmPassword.value}
                  onChange={handleChangeConfirmPassword}
                  error={!!confirmPassword.error}
                  helperText={confirmPassword.error}
                  type="password"
                  autoComplete="new-password"
                  onKeyDown={handleEnterEscapeKey(handleUpdatePassword)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleUpdatePassword}
                  disabled={updatingPassword || !oldPassword.value || !newPassword.value || !confirmPassword.value}
                >
                  Update password
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {userData!.admin && userData!.admin >= 3 && (
          <Paper elevation={2} className={clsx(classes.paper, classes.p2, classes.my2)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.headingGrey1}>Admin</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.pb2I}>
                <Typography className={classes.formLabel}>Admin Level</Typography>
                <Typography className={classes.textCenterIcon}>
                  Level
                  {' '}
                  {userData!.admin}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.headingText6}>Impersonate user</Typography>
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: 'inline-block' }}>
                  <Typography className={classes.formLabel}>Email</Typography>
                  <TextField
                    name="impersonee-email"
                    variant="outlined"
                    className={clsx(classes.slimOutlinedInput, classes.mb1)}
                    value={impersonateEmail.value}
                    onChange={handleChangeImpersonateEmail}
                    error={!!impersonateEmail.error}
                    helperText={impersonateEmail.error}
                  />
                </div>
                <div style={{
                  display: 'inline-block',
                  marginLeft: 16,
                  marginRight: 16,
                  marginBottom: 16,
                  verticalAlign: 'bottom',
                }}
                >
                  OR
                </div>
                <div style={{ display: 'inline-block' }}>
                  <Typography className={classes.formLabel}>User ID</Typography>
                  <TextField
                    name="impersonee-uid"
                    variant="outlined"
                    className={clsx(classes.slimOutlinedInput, classes.mb1)}
                    value={impersonateUserID.value}
                    onChange={handleChangeImpersonateUserID}
                    error={!!impersonateUserID.error}
                    helperText={impersonateUserID.error}
                  />
                </div>
              </Grid>
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleImpersonateUser}
                  disabled={!impersonateEmail.value && !impersonateUserID.value}
                >
                  Impersonate
                </Button>
              </Grid>
            </Grid>
          </Paper>
          )}
          <Paper elevation={2} className={clsx(classes.paper, classes.p2, classes.my2)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.headingGrey1}>About</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.pb2I}>
                <Typography className={classes.formLabel}>Software version</Typography>
                <Typography className={classes.textCenterIcon}>
                  v
                  {settings.version}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.pb2I}>
                <Typography className={classes.formLabel}>Last sign in</Typography>
                <Typography className={classes.textCenterIcon}>
                  {moment(user!.metadata.lastSignInTime).format('LLLL')}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.pb2I}>
                <Typography className={classes.formLabel}>Last password change</Typography>
                <Typography className={classes.textCenterIcon}>
                  {userData!.lastPasswordChange
                    ? moment(userData!.lastPasswordChange).format('LL')
                    : 'Never changed'}
                </Typography>
              </Grid>
              {('app.firebase.impersonate.oldRefreshToken' in localStorage) && (
                <Grid item xs="auto">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleDeImpersonateUser}
                  >
                    Stop impersonating
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Container>
        <Footer dashboard subtractMargin />
      </div>
    </OverlayScrollbarsComponent>
  );
}

export default Settings;
