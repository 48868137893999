import React from 'react';

import { Avatar, ListItemAvatar, makeStyles } from '@material-ui/core';
import { AccountCircle as AccountCircleIcon, Person as PersonIcon } from '@material-ui/icons';

import api from 'api';
import { UserData } from 'api/user';

const useStyles = makeStyles({
  nameInitials: {
    cursor: 'default',
  },
});

type UserAvatarProps = {
  context: string;
  user: firebase.User;
  userData: UserData;
  defaultCursor: boolean;
};

function UserAvatar(props: UserAvatarProps) {
  const classes = useStyles(props);

  const { context, user, userData, defaultCursor } = props;

  if (context === 'standalone') {
    if (!user) {
      return <AccountCircleIcon />;
    }

    const photoUrl = user.photoURL;

    if (photoUrl) {
      return <Avatar alt="Avatar" src={photoUrl} />;
    }

    const nameInitials = api.user.getNameInitials(userData.firstName, userData.lastName);
    if (nameInitials) {
      return (
        <Avatar alt="Avatar">
          <span className={defaultCursor ? classes.nameInitials : undefined}>{nameInitials}</span>
        </Avatar>
      );
    }

    return <AccountCircleIcon />;
  }

  if (context === 'list') {
    if (!user) {
      return (
        <ListItemAvatar>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
      );
    }

    const photoUrl = user.photoURL;

    if (photoUrl) {
      return (
        <ListItemAvatar>
          <Avatar alt="Avatar" src={photoUrl} />
        </ListItemAvatar>
      );
    }

    const nameInitials = api.user.getNameInitials(userData.firstName, userData.lastName);
    if (nameInitials) {
      return (
        <ListItemAvatar>
          <Avatar alt="Avatar">
            <span className={defaultCursor ? classes.nameInitials : undefined}>{nameInitials}</span>
          </Avatar>
        </ListItemAvatar>
      );
    }

    return (
      <ListItemAvatar>
        <Avatar>
          <PersonIcon />
        </Avatar>
      </ListItemAvatar>
    );
  }

  return null;
}

UserAvatar.defaultProps = {
  context: 'standalone',
  defaultCursor: false,
};

export default UserAvatar;
