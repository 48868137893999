import React, { ChangeEvent, useState } from 'react';
import { useSelector, useDispatch } from 'reduxx';

import validate from 'validate.js';

import {
  Dialog, DialogTitle, DialogContent, DialogActions, Typography, Tooltip, IconButton, Hidden, Grid, Button, TextField,
  makeStyles, Theme,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import { closeDialog } from 'reduxx/actions/app';
import { setUserData } from 'reduxx/actions/user';

import constraints from 'constraints.json';
import api from 'api';
import styles from 'styles';
import handleEnterEscapeKey from 'utils/handleEnterEscapeKey';

type SignUpDialogProps = {};

const useStyles = makeStyles<Theme, SignUpDialogProps>((theme) => ({
  ...styles(theme),
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),

    padding: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  divider: {
    margin: 'auto',
  },
  grid: {
    marginBottom: theme.spacing(2),
  },
}));

function SignUpDialog(props: SignUpDialogProps) {
  const classes = useStyles(props);

  const [performingAction, setPerformingAction] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressConfirmation, setEmailAddressConfirmation] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const data = useSelector((state) => state.app.signUpDialog);
  const dispatch = useDispatch();
  const { open } = data;

  async function signUp() {
    const errors_ = validate(
      { emailAddress, emailAddressConfirmation, password, confirmPassword },
      {
        emailAddress: constraints.emailAddress,
        emailAddressConfirmation: constraints.emailAddressConfirmation,
        password: constraints.password,
        confirmPassword: constraints.confirmPassword,
      },
    );

    if (errors_) {
      setErrors(errors_);
      return;
    }

    setErrors({});
    setPerformingAction(true);

    try {
      const userData = await api.user.signUpWithEmailAddressAndPassword(emailAddress, password);
      dispatch(setUserData(userData.data));
      onClose();
    } catch (error: any) {
      const { code, message } = error;
      switch (code) {
        case 'auth/email-already-in-use':
        case 'auth/invalid-email':
        case 'auth/operation-not-allowed':
        case 'auth/weak-password': {
          setErrors({ submitError: [message] });
          break;
        }

        default: {
          setErrors({ submitError: ['Something went wrong. Please try again later'] });
          break;
        }
      }
    }

    setPerformingAction(false);
  }

  function handleEmailAddressChange(event: ChangeEvent<HTMLInputElement>) {
    setEmailAddress(event.target.value);
  }

  function handleEmailAddressConfirmationChange(event: ChangeEvent<HTMLInputElement>) {
    setEmailAddressConfirmation(event.target.value);
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  function handlePasswordConfirmationChange(event: ChangeEvent<HTMLInputElement>) {
    setConfirmPassword(event.target.value);
  }

  function onClose() {
    if (!performingAction) {
      dispatch(closeDialog({ dialog: 'signUpDialog' }));
      setEmailAddress('');
      setEmailAddressConfirmation('');
      setPassword('');
      setConfirmPassword('');
      setErrors({});
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={open}
      onClose={onClose}
      onKeyPress={handleEnterEscapeKey(signUp)}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">Sign up for an account</Typography>

        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            disabled={performingAction}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <Hidden xsDown>
          <Grid container direction="row">
            <Grid item xs={7}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs>
                  <TextField
                    disabled={performingAction}
                    error={!!errors?.emailAddress}
                    fullWidth
                    helperText={errors?.emailAddress?.[0] || ''}
                    label="Email address"
                    placeholder="john@doe.com"
                    required
                    autoFocus
                    type="email"
                    value={emailAddress}
                    variant="outlined"
                    onChange={handleEmailAddressChange}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    disabled={performingAction}
                    error={!!errors?.emailAddressConfirmation}
                    fullWidth
                    helperText={errors?.emailAddressConfirmation?.[0] || ''}
                    label="Email address confirmation"
                    placeholder="john@doe.com"
                    required
                    type="email"
                    value={emailAddressConfirmation}
                    variant="outlined"
                    onChange={handleEmailAddressConfirmationChange}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    autoComplete="new-password"
                    disabled={performingAction}
                    error={!!errors?.password}
                    fullWidth
                    helperText={errors?.password?.[0]
                      || 'Passwords must be at least 8 characters, 1 uppercase, 1 lowercase, and 1 digit.'}
                    label="Password"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    required
                    type="password"
                    value={password}
                    variant="outlined"
                    onChange={handlePasswordChange}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    autoComplete="password"
                    disabled={performingAction}
                    error={!!errors?.confirmPassword}
                    fullWidth
                    helperText={errors?.confirmPassword?.[0] || ''}
                    label="Confirm password"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    required
                    type="password"
                    value={confirmPassword}
                    variant="outlined"
                    onChange={handlePasswordConfirmationChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        <Hidden smUp>
          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <TextField
                disabled={performingAction}
                error={!!errors?.emailAddress}
                fullWidth
                helperText={errors?.emailAddress?.[0] || ''}
                label="Email address"
                placeholder="john@doe.com"
                required
                autoFocus
                type="email"
                value={emailAddress}
                variant="outlined"
                onChange={handleEmailAddressChange}
              />
            </Grid>

            <Grid item xs>
              <TextField
                disabled={performingAction}
                error={!!errors?.emailAddressConfirmation}
                fullWidth
                helperText={errors?.emailAddressConfirmation?.[0] || ''}
                label="Email address confirmation"
                placeholder="john@doe.com"
                required
                type="email"
                value={emailAddressConfirmation}
                variant="outlined"
                onChange={handleEmailAddressConfirmationChange}
              />
            </Grid>

            <Grid item xs>
              <TextField
                autoComplete="new-password"
                disabled={performingAction}
                error={!!errors?.password}
                fullWidth
                helperText={errors?.password?.[0]
                  || 'Passwords must be at least 8 characters, 1 uppercase, 1 lowercase, and 1 digit.'}
                label="Password"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                required
                type="password"
                value={password}
                variant="outlined"
                onChange={handlePasswordChange}
              />
            </Grid>

            <Grid item xs>
              <TextField
                autoComplete="password"
                disabled={performingAction}
                error={!!errors?.confirmPassword}
                fullWidth
                helperText={errors?.confirmPassword?.[0] || ''}
                label="Password confirmation"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                required
                type="password"
                value={confirmPassword}
                variant="outlined"
                onChange={handlePasswordConfirmationChange}
              />
            </Grid>
          </Grid>
        </Hidden>
        {errors?.submitError && (
          <Alert style={{ marginTop: 8 }} className={classes.slimAlert} severity="error">
            {errors?.submitError?.[0] || ''}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          disabled={!emailAddress || !emailAddressConfirmation || !password || !confirmPassword || performingAction}
          variant="contained"
          onClick={signUp}
        >
          Sign up
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SignUpDialog;
