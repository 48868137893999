export enum DateFormat {
  Euro = 1,
  US,
  ISO8601,
}

export enum TimeFormat {
  Normal = 0,
  Military,
}

export enum EnumDisplay {
  // Used for 16x40 character LCD display, usually shortened as much as possible
  CharLCD,

  // Full "pretty" text
  Full,

  // Shortened text
  Short,

  // Display value as the raw number
  Numeric,
}
