import { createStyles, Theme } from '@material-ui/core';
import {
  red, green, blueGrey, deepOrange, deepPurple,
} from '@material-ui/core/colors';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { margins, paddings } from 'utils/spacingStyles';

const styles = (theme: Theme) => createStyles({
  '@global': {
    'input[type=text]::-ms-clear': {
      display: 'none',
    },
  },
  centerHV1: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  centerH2: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  centerV2: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  centerHV2: {
    margin: 'auto',
  },
  centerH3: {
    textAlign: 'center',
  },
  error: {
    color: '#D0011B',
    fontSize: 14,
    padding: 5,
    textAlign: 'center',
  },
  // Center children items vertically
  centerV3: {
    display: 'flex',
    alignItems: 'center',
  },
  // Center and apply width constraints
  centerHV4: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',

    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  paperAlt: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F6F6F6',
    flexShrink: 0,
  },
  tooltipLight: {
    position: 'relative',
    maxWidth: 256,

    backgroundColor: '#FFFFFF',
    border: '1px solid #DADDE9',
    boxShadow: theme.shadows[6],

    fontSize: theme.typography.pxToRem(12),
    color: 'rgba(0, 0, 0, 0.87)',
  },
  tooltipLight2: {
    position: 'relative',

    backgroundColor: '#FFFFFF',
    border: '1px solid #DADDE9',
    boxShadow: theme.shadows[6],

    fontSize: theme.typography.pxToRem(12),
    color: 'rgba(0, 0, 0, 0.87)',
  },
  tooltipLightArrow: {
    color: '#FFFFFF',
  },
  headingGrey1: {
    color: '#707070',
    fontSize: 25,
    fontWeight: 700,
  },
  bodyGrey1: {
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: 14,
    fontWeight: 500,
  },
  headingText1: {
    color: '#7E7E7E',
    fontSize: 30,
    fontWeight: 700,
    letterSpacing: '-0.01em',
    textAlign: 'center',
  },
  headingText2: {
    color: '#707070',
    textAlign: 'center',
    width: '100%',
    fontWeight: 700,
    fontSize: 24,
  },
  headingText3: {
    color: '#363636',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 52,
  },
  headingText4: {
    color: '#363636',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 22,
  },
  headingText5: {
    fontWeight: 700,
    fontSize: 22,
    color: '#2196F3',
    backgroundColor: '#EDEDED',
    borderRadius: 5,
    padding: '5px 10px',
  },
  headingText6: {
    fontWeight: 700,
    fontSize: 22,
    color: '#FFFFFF',
    backgroundColor: '#3DA2F5',
    borderRadius: 5,
    padding: '5px 10px',
  },
  bodyText1: {
    color: '#363636',
    fontSize: 18,
  },
  formLabel: {
    fontWeight: 600,
  },
  container1: {
    // Takes remaining space
    flexGrow: 1,

    // Used to allow centering in flexbox & absolute to work correctly
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  slimAlert: {
    padding: theme.spacing(0, 1),

    '& .MuiAlert-icon': {
      marginRight: theme.spacing(1),
    },
  },
  slimCard: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(1.5),
    },
    '& .MuiCardActions-root': {
      paddingTop: 0,
    },
  },
  slimContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  ultraSlimOutlinedInput: {
    '& .MuiOutlinedInput-input': {
      padding: '6px 10px',
    },
  },
  slimOutlinedInput: {
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    },
    '& .MuiSelect-outlined': {
      paddingRight: '32px !important',
    },
  },
  slimRadioGroup: {
    '& .MuiRadio-root': {
      padding: '2px 9px',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  show: {
    visibility: 'visible',
  },
  hiddenD: {
    display: 'none',
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
  successText: {
    color: '#66BB6A',
  },
  failureText: {
    color: '#FF5252',
  },
  uppercaseTextField: {
    '& .MuiInputBase-input': {
      textTransform: 'uppercase',
    },
  },
  menuListIconSkinny: {
    minWidth: 24,
    paddingRight: 4,
  },
  colorHoverPrimary: {
    '& :hover': {
      color: '#2196F3',
    },
  },
  chipSkinny: {
    height: 20,
    marginTop: 4,
    marginBottom: 4,
  },
  chipOverflowAuto: {
    // Prevents chip from extending past parents size
    maxWidth: '100%',
  },

  whitespaceNoWrap: {
    whiteSpace: 'nowrap',
  },

  // Predefined colors for filled components
  colorFilledRed1: {
    backgroundColor: red[500],
    color: '#FFFFFF',
  },
  colorFilledBlue1: {
    backgroundColor: blueGrey[500],
    color: '#FFFFFF',
  },
  colorFilledGreen1: {
    backgroundColor: green[600],
    color: '#FFFFFF',
  },
  colorFilledOrange1: {
    backgroundColor: deepOrange.A400,
    color: '#FFFFFF',
  },
  colorFilledPurple1: {
    backgroundColor: deepPurple[400],
    color: '#FFFFFF',
  },

  // Predefined colors
  colorGreen1: {
    color: green[600],
  },

  radioColorGreen1: {
    '&:hover': {
      backgroundColor: alpha('#000000', theme.palette.action.hoverOpacity),
    },
    // Reset on touch devices
    '@media (hover: none)': {
      backgroundColor: 'transparent !important',
    },

    '&.Mui-checked': {
      color: green[600],

      '&:hover': {
        backgroundColor: alpha('#000000', theme.palette.action.hoverOpacity),
      },
    },
  },
  selectWidth1: {
    width: '250px',
  },

  // Add in the utility margin & padding CSS classes
  ...margins(),
  ...paddings(),
});

export default styles;
