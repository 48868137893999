import React, { ChangeEvent, useState } from 'react';
import { useSelector, useDispatch } from 'reduxx';

import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button,
} from '@material-ui/core';

import { openDialog, closeDialog } from 'reduxx/actions/app';

import firebase, { auth } from 'utils/firebase';
import BError from 'utils/bError';
import { ReduxDispatch } from 'reduxx/helpers';
import handleEnterEscapeKey from 'utils/handleEnterEscapeKey';

function verify2FACode(dispatch: ReduxDispatch, verificationId: string, resolver?: firebase.auth.MultiFactorResolver,
  phoneNumber?: string): Promise<void> {
  return new Promise((resolve, reject) => {
    dispatch(openDialog({
      dialog: 'mfaVerifyDialog',
      verificationId,
      resolver,
      phoneNumber,
      resolve,
      reject,
    }));
  });
}

type MfaVerifyDialogProps = {};

function MfaVerifyDialog(props: MfaVerifyDialogProps) {
  const data = useSelector((state) => state.app.mfaVerifyDialog);
  const dispatch = useDispatch();
  const { open, verificationId, resolver, phoneNumber, resolve, reject } = data;

  const [verificationCode, setVerificationCode] = useState('');
  const [performingAction, setPerformingAction] = useState(false);
  const [error, setError] = useState('');

  async function onVerify() {
    setPerformingAction(true);

    const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(credential);

    try {
      if (resolver) {
        // Sign in with code
        await resolver.resolveSignIn(multiFactorAssertion);
      } else {
        // Enroll the phone second factor.
        await auth.currentUser!.multiFactor.enroll(multiFactorAssertion);
      }

      setError('');
      resolve!();
      onClose();
    } catch (error) {
      setError('Invalid verification code');
    }

    setPerformingAction(false);
  }

  function handleVerificationCodeChange(event: ChangeEvent<HTMLInputElement>) {
    setVerificationCode(event.target.value);
  }

  function onClose() {
    dispatch(closeDialog({ dialog: 'mfaVerifyDialog' }));
    setVerificationCode('');
    setPerformingAction(false);
    setError('');

    reject!(new BError('app/user-cancelled', 'User exited verify dialog'));
  }

  return (
    <Dialog
      open={open}
      onKeyPress={handleEnterEscapeKey(onVerify)}
      scroll="body"
    >
      <DialogTitle>Enter verification code{phoneNumber && ` for ${phoneNumber}`}</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          color="primary"
          disabled={performingAction}
          fullWidth
          label="Code"
          error={!!error}
          helperText={error}
          required
          type="text"
          value={verificationCode}
          variant="outlined"
          onChange={handleVerificationCodeChange}
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          disabled={performingAction}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={performingAction}
          variant="contained"
          onClick={onVerify}
        >
          Verify Code
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { verify2FACode, MfaVerifyDialog };
