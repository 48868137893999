import React from 'react';

import { TextField } from '@material-ui/core';

function TextFieldRFF(props: any) {
  const { name, input, meta, inputProps, ...rest } = props;
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <TextField
      name={name}
      error={showError}
      inputProps={inputProps}
      onChange={input.onChange}
      value={input.value}
      {...rest}
      helperText={showError ? meta.error || meta.submitError : rest.helperText}
    />
  );
}

export default TextFieldRFF;
