import axios_, { AxiosResponse } from 'axios';
import moment from 'moment';
import settings from 'settings';
import { auth, storage, analytics } from 'utils/firebase';

const apiUrl = settings.devBuild
  ? 'https://api-srv2.feedlink.live/devLiveBackend/user'
  : 'https://api-srv2.feedlink.live/liveBackend/user';
const axios = axios_.create({ baseURL: apiUrl, withCredentials: true });

export interface UserData {
  admin?: number;
  betaUser?: boolean;
  earlyAdopter?: boolean;
  firstName: string;
  inTrial: boolean;
  lastName: string;
  lastPasswordChange: string | null;
  trialCount: number;
  trialEndTime: string | null;
  trialStartTime: string | null;
  user: string;
}

interface ImpersonateUserOptions {
  email?: string;
  userID?: string;
}

export async function signUpWithEmailAddressAndPassword(email: string, password: string) {
  if (!email || !password) {
    throw new Error('Cannot create user: Missing fields');
  }

  if (auth.currentUser) {
    throw new Error('Cannot create user: Already logged in');
  }

  const value = await auth.createUserWithEmailAndPassword(email, password);
  analytics.logEvent('sign_up', { method: value.additionalUserInfo?.providerId });

  const { user } = value;
  if (!user) {
    throw new Error('Cannot create user');
  }

  const token = await user.getIdToken();
  return axios.put('/', {
    firstName: '',
    lastName: '',
    lastPasswordChange: null,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function signIn(email: string, password: string) {
  if (!email || !password) {
    throw new Error('Cannot sign in: Invalid email or password');
  }

  if (auth.currentUser) {
    throw new Error('Cannot sign in: Already logged in');
  }

  const value = await auth.signInWithEmailAndPassword(email, password);
  analytics.logEvent('login', { method: value.additionalUserInfo?.providerId });

  const { user } = value;
  if (!user) {
    throw new Error('Cannot sign in');
  }
}

export async function signOut() {
  const { currentUser } = auth;
  if (!currentUser) {
    throw new Error('Cannot sign out: Not logged in');
  }

  return auth.signOut();
}

export async function get(): Promise<AxiosResponse<UserData>> {
  const { currentUser } = auth;
  if (!currentUser) {
    throw new Error('Cannot get user: Not logged in');
  }

  const token = await currentUser.getIdToken();
  return axios.get('/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function resetPassword(email: string) {
  if (!email) {
    throw new Error('Cannot reset password: Missing fields');
  }

  if (auth.currentUser) {
    throw new Error('Cannot reset password: Already logged in');
  }

  return auth.sendPasswordResetEmail(email);
}

export async function changeAvatar(avatar: any) {
  if (!avatar) {
    throw new Error('Cannot change avatar');
  }

  const avatarFileTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp', 'image/svg+xml'];

  if (!avatarFileTypes.includes(avatar.type)) {
    throw new Error('Cannot change avatar');
  }

  if (avatar.size > 20 * 1024 * 1024) {
    throw new Error('Cannot change avatar');
  }

  const { currentUser } = auth;

  if (!currentUser) {
    throw new Error('Cannot change avatar: Not logged in');
  }

  const { uid } = currentUser;

  if (!uid) {
    throw new Error('Cannot change avatar: Missing uid');
  }

  const avatarReference = storage.ref().child('images').child('avatars').child(uid);

  await avatarReference.put(avatar);
  const value = await avatarReference.getDownloadURL();
  return currentUser.updateProfile({ photoURL: value });
}

export async function removeAvatar() {
  const { currentUser } = auth;

  if (!currentUser) {
    throw new Error('Cannot delete avatar: Not logged in');
  }

  const { uid } = currentUser;

  if (!uid) {
    throw new Error('Cannot remove avatar: Missing uid');
  }

  await currentUser.updateProfile({ photoURL: null });
  const avatarReference = storage.ref().child('images').child('avatars').child(uid);

  return avatarReference.delete();
}

export async function changeFirstName(firstName: string) {
  if (!firstName) {
    throw new Error('Cannot change name: Missing fields');
  }

  const { currentUser } = auth;

  if (!currentUser) {
    throw new Error('Cannot change name: Not logged in');
  }

  const token = await currentUser.getIdToken();
  return axios.post('/', { firstName }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function changeLastName(lastName: string) {
  if (!lastName) {
    throw new Error('Cannot change name: Missing fields');
  }

  const { currentUser } = auth;

  if (!currentUser) {
    throw new Error('Cannot change name: Not logged in');
  }

  const token = await currentUser.getIdToken();
  return axios.post('/', { lastName }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function changeEmailAddress(email: string) {
  if (!email) {
    throw new Error('Cannot change email: Missing fields');
  }

  const { currentUser } = auth;

  if (!currentUser) {
    throw new Error('Cannot change email: Not logged in');
  }

  const { uid } = currentUser;

  if (!uid) {
    throw new Error('Cannot change email: Missing uid');
  }

  return currentUser.updateEmail(email);
}

export async function changePassword(password: string) {
  if (!password) {
    throw new Error('Cannot change password: Missing fields');
  }

  const { currentUser } = auth;

  if (!currentUser) {
    throw new Error('Cannot change password: Not logged in');
  }

  await currentUser.updatePassword(password);
  const token = await currentUser.getIdToken();
  return axios.post('/', { lastPasswordChange: moment.utc().toISOString() }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function verifyEmailAddress() {
  const { currentUser } = auth;

  if (!currentUser) {
    throw new Error('Cannot verify email: Not logged in');
  }

  return currentUser.sendEmailVerification();
}

export async function deleteAccount() {
  const { currentUser } = auth;

  if (!currentUser) {
    throw new Error('Cannot delete account: Not logged in');
  }

  const token = await currentUser.getIdToken();
  await removeAvatar();
  await axios.delete('/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  await currentUser.delete();
}

export async function impersonateUser(options: ImpersonateUserOptions) {
  const { currentUser } = auth;
  if (!currentUser) {
    throw new Error('Cannot get user: Not logged in');
  }

  const { email, userID } = options;

  const token = await currentUser.getIdToken();
  const response = await axios.post('/impersonate', {
    email,
    userID,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export async function getRoles() {
  const { currentUser } = auth;

  if (!currentUser) {
    throw new Error('Cannot get roles: Not logged in');
  }

  const idTokenResult = await currentUser.getIdTokenResult();
  return idTokenResult.claims.roles;
}

export function getNameInitials(firstName: string, lastName: string) {
  if (firstName && lastName) {
    return firstName.charAt(0) + lastName.charAt(0);
  }

  if (firstName) {
    return firstName.charAt(0);
  }

  if (lastName) {
    return lastName.charAt(0);
  }

  return null;
}
