/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

/**
 * Loads all size image paths for a responsive image using a WebPack context.
 *
 * This function uses a WebPack context along with a source template path that will be replaced with given widths
 * that the responsive image is sized at. In addition, multiple file types can be specified in the source template
 * path, although only one file type can be loaded using
 * this function at once.
 *
 * @param {object} context Webpack require.context used for dynamically loading the responsive image
 * @param {string} src Source template path for the responsive image to be loaded from the context. Extension for
 * the source can be denoted with %e and widths with %w
 * @param {string} ext Image file extension (without the period at beginning) for the responsive image
 * @param {int[]} widths A list of widths, in pixels, to load for the responsive image
 *
 * @returns {string[]} A list of image paths for the responsive image
 */
function loadResponsiveImages(context: __WebpackModuleApi.RequireContext, src: string, ext?: string,
  widths?: number[]): string[] {
  // Get the path with %e replaced with the extension
  const path1 = src.replace(new RegExp('%e', 'g'), ext!);

  // If no widths are given, then just load the src path itself
  if (!widths) {
    return [context(src)];
  }

  return widths.map((width) => {
    // Replace all occurrences of %w with the width to get the actual path
    const path = path1.replace(new RegExp('%w', 'g'), width.toString());

    // Load the actual path by calling require, essentially offloads it to webpack
    return context(path).default;
  });
}

/**
 * Creates a srcset string to be used in an img tag for the responsive image.
 *
 * This takes in a list of image paths and widths to create a srcset string.
 *
 * @param {string[]} images List of image paths for the responsive image
 * @param {int[]} widths  List of widths, in pixels, representing the various sizes for the responsive image
 *
 * @return {string} A string that is compatible with the img srcset tag denoting image path and corresponding
 * width it has
 */
function createSrcSetString(images: string[], widths: number[]) {
  return images.map((image, index) => {
    const width = widths[index];

    return `${image} ${width}w`;
  }).join(', ');
}

type ResponsiveImageProps = {
  className?: string;
  context: __WebpackModuleApi.RequireContext;
  src: string;
  exts: string[];
  widths: number[];
  sizes: string;
};

function ResponsiveImage(props: ResponsiveImageProps) {
  function getImages(context: __WebpackModuleApi.RequireContext, src: string, exts: string[], widths: number[],
    sizes: string): { images: string[]; webpImages: string[] | null } {
    let images = null;
    let webpImages = null;

    // Matches two cases, if no widths given, then throw an error if %w is in string
    // Or, if widths are given and there is no %w in string, throw error
    if (!widths === src.includes('%w')) {
      throw TypeError('Invalid properties for ResponsiveImage, %w must be present if widths are given');
    }

    // Matches two cases, if no exts given, then throw an error if %e is in string
    // Or, if exts are given and there is no %e in string, throw error
    if (!exts === src.includes('%e')) {
      throw TypeError('Invalid properties for ResponsiveImage, %e must be present if exts are given');
    }

    if (!exts) {
      images = loadResponsiveImages(context, src, undefined, widths);
    } else {
      props.exts.forEach((ext) => {
        const images_ = loadResponsiveImages(context, src, ext, widths);

        // If ext is webp, do that stuff
        // If extension is webp, save those in a different spot as the normal images
        if (ext === 'webp') {
          webpImages = images_;
        } else {
          images = images_;
        }
      });

      if (!images) {
        throw TypeError('Invalid properties for ResponsiveImage, at least one normal image is required');
      }
    }

    return { images, webpImages };
  }

  // Use object destructuring to retrieve all props to pass along to img tag
  const { context, src, exts, widths, sizes, ...imageProps } = props;

  // Get image path based on props
  // Note: Since this is a PureComponent, render is only called when a prop changes, we can assume the images will
  // be different
  const { images, webpImages } = getImages(context, src, exts, widths, sizes);

  // Dont bother with picture tag if no webp
  if (!webpImages) {
    return (
      <img // Only display srcset & sizes if there is more than 1 image, otherwise just use src
        srcSet={images.length !== 1 ? createSrcSetString(images, widths) : undefined}
        sizes={images.length !== 1 ? sizes : undefined}
        src={images.length === 1 ? images[0] : undefined}
        {...imageProps}
      />
    );
  } else {
    return (
      <picture>
        <source
          // If there is only one WebP image, meaning no responsive images are given, then just use the srcset
          // attribute and treat it like the src attribute
          // That is what many examples show and what spec uses, no src tag in source element
          srcSet={webpImages.length !== 1 ? createSrcSetString(webpImages, widths) : webpImages[0]}
          sizes={webpImages.length !== 1 ? sizes : undefined}
          type="image/webp"
        />

        <img // Only display srcset & sizes if there is more than 1 image, otherwise just use src
          srcSet={images.length !== 1 ? createSrcSetString(images, widths) : undefined}
          sizes={images.length !== 1 ? sizes : undefined}
          src={images.length === 1 ? images[0] : undefined}
          {...imageProps}
        />
      </picture>
    );
  }
}

export { loadResponsiveImages, createSrcSetString, ResponsiveImage };
