/* eslint-disable no-useless-return */
import * as actions from 'reduxx/actions/app';

import moment from 'moment';

import withImmerReducer from 'utils/withImmerReducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnyRecord, Sites, SiteStatus, SiteTree } from 'types';
import {
  SiteInfoM, GetNodesResponseItem, ControllerConfigCard,
} from 'api/EZData';
import { DashboardItem } from 'api/dashboard';
import { DialogTypes } from 'reduxx/types';

import { getNodeType } from 'utils/serialUtils';
import { durationParseMap } from 'utils/const';
import { BParseDuration } from 'utils/bDuration';

export interface Dashboard {
  loading: boolean;
  error: string | null;

  items?: DashboardItem[];
}

export interface AccessLevelData {
  accessLevel: number;
  accessLevelStr: string;
  accessCode?: string;
}

interface AppState {
  drawer: {
    exists: boolean;
    open: boolean;
  };

  searchSiteTree: boolean;
  siteTreeForSearch?: any[];
  searchSiteTreeValue: string;
  siteTree: SiteTree;
  sites: Sites;
  siteTreeStatus: { [key: string]: SiteStatus };
  masterID: string;

  accessLevelData: {
    [key: string]: AccessLevelData;
  };
  accessLevelStatus: {
    error: string | null;
  };

  dashboard: Dashboard;
  siteGraphs: {
    loading: boolean;
    error: string | null;

    siteID?: string;
    data?: any;
  };
  installedNodes: GetNodesResponseItem[];
  controllerConfig: {
    loading: boolean;
    error: string | null;
    data?: ControllerConfigCard[];
  };
  siteAlarms: string[];
  siteInfo: Partial<SiteInfoM> & {
    loading: boolean;
    error: string | null;
  };

  signUpDialog: {
    open: boolean;
  };
  signInDialog: {
    open: boolean;
  };
  deleteAccountDialog: {
    open: boolean;
  };
  mfaEnrollDialog: {
    open: boolean;
  };
  mfaVerifyDialog: {
    open: boolean;

    verificationId: string;
    resolver: firebase.auth.MultiFactorResolver | null;
    phoneNumber: null;

    resolve: (() => void) | null;
    reject: ((error: Error) => void) | null;
  };
  signOutDialog: {
    open: boolean;
  };
  newSiteDialog: {
    open: boolean;

    // Parent site ID to create site under
    siteID: string | null;
  };
  newZoneDialog: {
    open: boolean;

    // Parent site ID to create site under
    siteID: string | null;
  };
  reenterPasswordDialog: {
    open: boolean;

    resolve: (() => void) | null;
    reject: ((error: Error) => void) | null;
  };
}

export const initialState: AppState = {
  drawer: {
    exists: false,
    open: true,
  },

  searchSiteTree: false,
  searchSiteTreeValue: '',
  siteTree: [],
  sites: {},
  siteTreeStatus: {},
  masterID: '0',

  accessLevelData: {},
  accessLevelStatus: {
    error: null,
  },

  dashboard: {
    loading: true,
    error: null,
  },
  siteGraphs: {
    loading: true,
    error: null,
  },
  controllerConfig: {
    loading: true,
    error: null,
  },
  installedNodes: [],
  siteAlarms: [],

  siteInfo: {
    loading: false,
    error: null,
  },

  signUpDialog: {
    open: false,
  },
  signInDialog: {
    open: false,
  },
  deleteAccountDialog: {
    open: false,
  },
  mfaEnrollDialog: {
    open: false,
  },
  mfaVerifyDialog: {
    open: false,

    verificationId: '',
    resolver: null,
    phoneNumber: null,

    resolve: null,
    reject: null,
  },
  signOutDialog: {
    open: false,
  },
  newSiteDialog: {
    open: false,
    // Parent site ID to create site under
    siteID: null,
  },
  newZoneDialog: {
    open: false,
    // Parent site ID to create site under
    siteID: null,
  },
  reenterPasswordDialog: {
    open: false,

    resolve: null,
    reject: null,
  },
};

function app(state: AppState, action: PayloadAction<any>) {
  switch (action.type) {
    case actions.handleSearchSiteTreeOn.type: {
      Object.assign(state, {
        searchSiteTree: true,
        siteTreeForSearch: action.payload.siteTree,
        searchSiteTreeValue: action.payload.keywordValue,
      });
      return;
    }

    case actions.handleSearchSiteTreeOff.type: {
      Object.assign(state, {
        searchSiteTree: false,
        siteTreeForSearch: undefined,
        searchSiteTreeValue: action.payload.value,
      });
      return;
    }

    case actions.getSiteTreeStatusBegin.type: {
      return;
    }

    case actions.getSiteTreeStatusSuccess.type: {
      const rows = action.payload.data || [];
      const { ids } = action.payload;
      let offset;

      // If there are more rows than sites we gave, this indicates there is a header row
      // Search for alarms column
      if (rows.length > ids.length) {
        offset = 1;
      } else {
        offset = 0;
      }

      // Loop through each site
      for (let i = 0; i < ids.length; ++i) {
        const id = ids[i];
        const row = rows[i + offset];

        if (row === 'good') {
          state.siteTreeStatus[id] = 'good';
        } else if (row === 'unauthorized') {
          state.siteTreeStatus[id] = 'unauthorized';
        } else {
          state.siteTreeStatus[id] = 'offline';
        }
      }
      return;
    }

    case actions.getSiteTreeStatusFailure.type: {
      return;
    }

    case actions.getSiteStatusSuccess.type: {
      // Update site tree indicator for site to keep in sync
      state.siteTreeStatus[action.payload.id] = action.payload.status;
      return;
    }

    case actions.getSiteStatusFailure.type: {
      const { id, error } = action.payload;

      if (id) {
        // Update site tree indicator for site to keep in sync
        if (error?.response.status === 401) {
          state.siteTreeStatus[action.payload.id] = 'unauthorized';
        } else if (error?.response.status === 404) {
          state.siteTreeStatus[action.payload.id] = 'offline';
        }
      }

      return;
    }

    case actions.deleteZoneSuccess.type: {
      const { sites, siteTree } = state;

      // Find index of site
      const index = siteTree.findIndex((site) => site.id === action.payload.id);
      const treeItem = siteTree[index];

      // Find index of next site at same depth (i.e. sibling), otherwise use length of site tree
      let nextSiblingIndex = siteTree.findIndex((site, index_) => index_ > index && site.parent === treeItem.parent);
      if (nextSiblingIndex === -1) {
        nextSiblingIndex = siteTree.length;
      }

      // Remove elements from site tree and remove from site map
      const removedItems = siteTree.splice(index, nextSiblingIndex - index);
      for (const item of removedItems) {
        delete sites[item.id];
      }

      // Decrement order for all siblings after this site
      for (let i = index; i < siteTree.length; ++i) {
        if (siteTree[i].parent === treeItem.parent) {
          siteTree[i].order -= 1;
        }
      }
      return;
    }

    case actions.getSiteTreeBegin.type:
      return;

    case actions.getSiteTreeSuccess.type: {
      // Reconstruct siteTree & site list from response received from server
      const siteTree = [];
      const sites: AnyRecord = {};

      for (const site of action.payload.data) {
        // Split site tree returned from backend into two parts:
        //    1. Tree structure (how to order the sites)
        //    2. Site data
        siteTree.push({
          id: site.id,
          parent: site.parent,
          order: site.order,
        });

        // Update site information
        // First object is default values for properties if unassigned
        // Second object are existing properties
        // Third object are values that should override what's in site
        sites[site.id] = Object.assign({
          collapsed: false,
          hidden: false,
        },
        sites[site.id],
        {
          parent: site.parent,
          nickname: site.nickname,
          serialNum: site.serialNum,
          authType: site.authType,
          authCode: site.authCode,
          authenticated: site.authenticated,
        });
      }

      state.siteTree = siteTree;
      state.sites = sites;

      // Get master list ID
      state.masterID = siteTree.length > 0 ? siteTree[0].id : state.masterID;
      return;
    }

    case actions.getSiteTreeFailure.type: {
      return;
    }

    case actions.updateSiteTreeBegin.type:
      return;

    case actions.updateSiteTreeSuccess.type: {
      const newSiteTree = action.payload.siteTree;
      state.siteTree = newSiteTree;

      for (const treeItem of newSiteTree) {
        const site = state.sites[treeItem.id];
        site.parent = treeItem.parent;
      }
      return;
    }

    case actions.updateSiteTreeFailure.type: {
      return;
    }

    case actions.getAlarmsBegin.type: {
      const clearPrevious = action.payload;

      if (clearPrevious) {
        state.siteAlarms = [];
      }
      return;
    }

    case actions.getAlarmsSuccess.type: {
      state.siteAlarms = action.payload.data;

      if (action.payload.id) {
        // Update site tree indicator for site to keep in sync
        if (state.siteAlarms.length > 0) {
          state.siteTreeStatus[action.payload.id] = 'alarm';
        } else {
          state.siteTreeStatus[action.payload.id] = 'good';
        }
      }
      return;
    }

    case actions.getAlarmsFailure.type: {
      const { id, error } = action.payload;

      if (id) {
        // Update site tree indicator for site to keep in sync
        if (error?.response.status === 401) {
          state.siteTreeStatus[action.payload.id] = 'unauthorized';
        } else if (error?.response.status === 404) {
          state.siteTreeStatus[action.payload.id] = 'offline';
        }
      }

      return;
    }

    case actions.clearAccessCodeStatusBegin.type: {
      state.accessLevelStatus = { error: null };
      return;
    }

    case actions.setAccessCodeBegin.type: {
      state.accessLevelStatus = { error: null };
      return;
    }

    case actions.setAccessCodeSuccess.type: {
      const { serialNum, accessCode, data } = action.payload;
      state.accessLevelData[serialNum] = { accessCode, ...data };
      return;
    }

    case actions.setAccessCodeFailure.type: {
      const error = action.payload;
      const errorMessage = error?.response?.data?.message || 'Invalid access code';

      state.accessLevelStatus = {
        error: errorMessage,
      };
      return;
    }

    case actions.deleteSiteSuccess.type: {
      const { siteTree } = state;
      const { sites } = state;
      const siteID = action.payload.id;

      // Find index of site in tree and remove it
      const index = siteTree.findIndex((site) => site.id === siteID);
      const treeItem = siteTree[index];
      siteTree.splice(index, 1);

      // Decrement order for all siblings after this site
      for (let i = index; i < siteTree.length; ++i) {
        if (siteTree[i].parent === treeItem.parent) {
          siteTree[i].order -= 1;
        }
      }

      // Update site map, remove unused site
      delete sites[siteID];
      return;
    }

    case actions.setDrawerExists.type: {
      state.drawer.exists = action.payload;
      return;
    }

    case actions.handleDrawerOpen.type: {
      state.drawer.open = true;
      return;
    }

    case actions.handleDrawerClose.type: {
      state.drawer.open = false;
      return;
    }

    case actions.getDashboardBegin.type: {
      const clearPrevious = action.payload;

      if (clearPrevious) {
        state.dashboard = {
          loading: true,
          error: null,
        };

        state.siteInfo = {
          loading: true,
          error: null,
        };
      } else {
        state.dashboard.error = null;
      }
      return;
    }

    case actions.getDashboardSuccess.type: {
      const data: DashboardItem[] = action.payload.data || [];

      for (const page of data) {
        if (page.type === 'table1' || page.type === 'table2') {
          for (const row of page.cells) {
            for (const cell of row) {
              if (cell) {
                if (cell.datatype?.[0] === 'duration') {
                  // Convert value to moment.js duration
                  cell.value = BParseDuration(cell.value, cell.units!);
                }

                if (cell.datatype?.[0] === 'date') {
                  const date = moment(cell.value);
                  if (!date.isValid()) {
                    cell.value = undefined;
                  }
                }
              }
            }
          }
        }
      }

      state.dashboard = {
        loading: false,
        error: null,
        items: data,
      };
      return;
    }

    case actions.getDashboardFailure.type: {
      state.dashboard = {
        loading: false,
        error: action.payload?.response?.data?.message || 'Something went wrong',
      };
      return;
    }

    case actions.getSiteGraphsBegin.type: {
      state.siteGraphs = {
        loading: true,
        error: null,
      };

      return;
    }

    case actions.getSiteGraphsSuccess.type: {
      const data = action.payload.response;
      const { siteID } = action.payload;

      state.siteGraphs = {
        loading: false,
        error: null,
        siteID,
        data,
      };

      return;
    }

    case actions.getSiteGraphsFailure.type: {
      state.siteGraphs = {
        loading: false,
        error: action.payload?.response?.data?.message || 'No data for this selection',
      };

      return;
    }

    case actions.getSiteGraphsDatesSuccess.type: {
      const { sites } = state;

      const { siteID } = action.payload;
      const { startTime } = action.payload;
      const { stopTime } = action.payload;
      const { duration } = action.payload;

      const site = sites[siteID];

      sites[siteID] = {
        ...site,
        siteGraphsDates: {
          startTime,
          stopTime,
          duration,
        },
      };

      return;
    }

    case actions.addZoneSuccess.type: {
      const { siteTree } = state;
      const { sites } = state;
      const newZone = action.payload.data;

      // Find index of parent zone
      const index = siteTree.findIndex((site) => site.id === newZone.parent);

      // Find index of next site at same depth (i.e. sibling), otherwise use length of site tree
      let nextSiblingIndex = siteTree.findIndex((site, index_) => index_ > index
        && site.parent === siteTree[index].parent);
      if (nextSiblingIndex === -1) {
        nextSiblingIndex = siteTree.length;
      }

      // Insert new site into site tree (only copy relevant info)
      siteTree.splice(nextSiblingIndex, 0, {
        id: newZone.id,
        parent: newZone.parent,
        order: newZone.order,
      });

      // Insert new site into map (only copy relevant info)
      sites[newZone.id] = {
        collapsed: false,
        hidden: false,
        parent: newZone.parent,
        nickname: newZone.nickname,
        serialNum: newZone.serialNum,
        authType: newZone.authType,
        authCode: newZone.authCode,
        authenticated: newZone.authenticated,
      };

      return;
    }

    case actions.updateZoneSuccess.type: {
      const { sites } = state;
      const newZone = action.payload.data;
      const { id } = newZone;
      const oldZone = sites[id];

      // Create site object containing relevant info
      const site = Object.assign({}, newZone, {
        collapsed: oldZone.collapsed,
        hidden: oldZone.hidden,
      });
      delete site.id;
      delete site.order;

      sites[id] = site;
      return;
    }

    case actions.newSiteSuccess.type: {
      const { siteTree } = state;
      const { sites } = state;
      const newSite = action.payload.data;

      // Find index of site
      const index = siteTree.findIndex((site) => site.id === newSite.parent);

      // Find index of next site at same depth (i.e. sibling), otherwise use length of site tree
      let nextSiblingIndex = siteTree.findIndex((site, index_) => index_ > index
        && site.parent === siteTree[index].parent);
      if (nextSiblingIndex === -1) {
        nextSiblingIndex = siteTree.length;
      }

      // Insert new site into site tree (only copy relevant info)
      siteTree.splice(nextSiblingIndex, 0, {
        id: newSite.id,
        parent: newSite.parent,
        order: newSite.order,
      });

      // Insert new site into map (only copy relevant info)
      sites[newSite.id] = {
        collapsed: false,
        hidden: false,
        parent: newSite.parent,
        nickname: newSite.nickname,
        serialNum: newSite.serialNum,
        authType: newSite.authType,
        authCode: newSite.authCode,
        authenticated: newSite.authenticated,
      };
      return;
    }

    case actions.addSites.type: {
      const { siteTree } = state;
      const { sites } = state;
      const newSites = action.payload.data;

      // Note: This function requires that the new sites are continuous in the tree and sorted by order

      // Find index of sites parent
      const index = siteTree.findIndex((site) => site.id === newSites[0].parent);

      // Find index of next site at same depth (i.e. sibling), otherwise use length of site tree
      let nextSiblingIndex = siteTree.findIndex((site, index_) => index_ > index
        && site.parent === siteTree[index].parent);
      if (nextSiblingIndex === -1) {
        nextSiblingIndex = siteTree.length;
      }

      // Insert new sites into site tree (only copy relevant info)
      siteTree.splice(nextSiblingIndex, 0, ...newSites.map((site: any) => ({
        id: site.id,
        parent: site.parent,
        order: site.order,
      })));

      // Insert new sites into map (only copy relevant info)
      for (const site of newSites) {
        sites[site.id] = {
          collapsed: false,
          hidden: false,
          parent: site.parent,
          nickname: site.nickname,
          serialNum: site.serialNum,
          authType: site.authType,
          authCode: site.authCode,
          authenticated: site.authenticated,
        };
      }
      return;
    }

    case actions.updateSiteSuccess.type: {
      const { sites } = state;
      const newSite = action.payload.data;
      const oldSite = sites[newSite.id];

      sites[newSite.id] = {
        collapsed: oldSite.collapsed,
        hidden: oldSite.hidden,
        parent: newSite.parent,
        nickname: newSite.nickname,
        serialNum: newSite.serialNum,
        authType: newSite.authType,
        authCode: newSite.authCode,
        authenticated: newSite.authenticated,
      };
      return;
    }

    case actions.getSiteInfoBegin.type: {
      const clearPrevious = action.payload;

      if (clearPrevious) {
        state.siteInfo = {
          loading: true,
          error: null,
        };
      } else {
        state.siteInfo.loading = true;
        state.siteInfo.error = null;
      }
      return;
    }

    case actions.getSiteInfoSuccess.type: {
      const siteInfo = action.payload.data;
      siteInfo.loading = false;

      if (siteInfo.timestamp) {
        siteInfo.timestamp = moment.utc(siteInfo.timestamp);
      }

      state.siteInfo = siteInfo;
      return;
    }

    case actions.getSiteInfoFailure.type: {
      const error = action.payload;
      const errorMessage = error?.response?.data?.message || 'Controller Not Found';

      state.siteInfo = {
        loading: false,
        error: errorMessage,
      };
      return;
    }

    case actions.toggleCollapseSiteTree.type: {
      const { sites, siteTree } = state;
      const { id }: { id: string } = action.payload;

      // Find item in site tree
      const treeIndex = siteTree.findIndex((site) => site.id === id);

      const site = sites[id];
      site.collapsed = !site.collapsed;
      const collapsedMap = {
        [id]: site.collapsed,
      };

      // Loop through all tree items starting from one past the selected zone
      for (const treeItem of siteTree.slice(treeIndex + 1)) {
        const parentCollapsed = collapsedMap[treeItem.parent!];

        // Parent not found in map, SHOULD only ever occur when we reach a sibling of the current zone, so exit
        if (parentCollapsed === undefined) {
          break;
        }

        // Grab site and update hidden status based on parent collapsed status
        const site2 = sites[treeItem.id];
        site2.hidden = parentCollapsed;

        // If site has a collapsed field, this means it should be a zone
        // Add hidden status in map
        if (site2.collapsed !== undefined) {
          collapsedMap[treeItem.id] = parentCollapsed || site2.collapsed;
        }
      }

      return;
    }

    case actions.openDialog.type: {
      const { dialog, ...extra }: { dialog: DialogTypes; extra: any } = action.payload;
      state[dialog].open = true;

      Object.assign(state[dialog], extra, {
        open: true,
      });
      return;
    }

    case actions.closeDialog.type: {
      const { dialog }: { dialog: DialogTypes } = action.payload;
      state[dialog].open = false;
      return;
    }

    case actions.closeAllDialogs.type: {
      state.signUpDialog.open = false;
      state.signInDialog.open = false;
      state.deleteAccountDialog.open = false;
      state.mfaEnrollDialog.open = false;
      state.signOutDialog.open = false;
      state.newSiteDialog.open = false;
      state.newZoneDialog.open = false;
      return;
    }

    case actions.getInstalledNodesSuccess.type: {
      state.installedNodes = action.payload.values;
      return;
    }

    case actions.updateInstalledNodeValue.type: {
      if (!state.installedNodes) {
        return;
      }

      const { nodeID, value } = action.payload;

      const nodeIndex = state.installedNodes.findIndex((node) => node.id === nodeID);
      state.installedNodes[nodeIndex].installed = value;
      return;
    }

    case actions.getControllerConfigSuccess.type: {
      const data_ = action.payload.data;
      const data = [];

      for (const card of data_) {
        const card_ = {
          title: card.title,
          nodeType: card.nodeType,
          groups: card.groups,
          headers: card.values.shift(),
          rows: card.values || [],
        };

        data.push(card_);
      }

      for (const card of data) {
        for (const row of card.rows) {
          for (const item of row.values) {
            if (item.datatype?.[0] === 'duration') {
              // Grab the format string to parse to moment.js duration
              const format = durationParseMap[item.units];
              // Convert the value to moment.js duration
              item.value = moment.duration(item.value, format as any);
            }
          }
        }
      }

      state.controllerConfig = {
        loading: false,
        error: null,
        data,
      };

      return;
    }

    case actions.updateControllerConfigValue.type: {
      if (!state.controllerConfig.data) {
        return;
      }

      const { nodeID, code, value } = action.payload;

      const nodeType = getNodeType(nodeID);

      // Allows updating across tabs, i.e. Node Name
      for (const card of state.controllerConfig.data) {
        if (card.nodeType === nodeType) {
          for (const row of card.rows) {
            if (row.nodeID === nodeID) {
              for (const item of row.values) {
                if (item.code && item.code === code) {
                  item.value = value;
                }
              }
            }
          }
        }
      }
      return;
    }

    default: {
      return;
    }
  }
}

export default withImmerReducer(app, initialState);
