import { useEffect } from 'react';

export default function(callback: () => void, deps: any[], destructorCallback?: () => void) {
  useEffect(() => {
    callback();

    return destructorCallback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
