import React, { Fragment } from 'react';
import { useSelector } from 'reduxx';
import { Redirect } from 'react-router-dom';

import clsx from 'clsx';

import { Typography, Paper, SvgIcon, makeStyles, Theme } from '@material-ui/core';

import { ReactComponent as HeartbeatIcon } from 'images/heartbeat-solid.svg';
import { ReactComponent as SmileIcon } from 'images/laugh-beam-regular.svg';
import { ReactComponent as EditIcon } from 'images/pencil-alt-solid.svg';
import { ReactComponent as LockIcon } from 'images/lock.svg';
import { ReactComponent as ReportIcon } from 'images/report.svg';
import { ReactComponent as NewsIcon } from 'images/news.svg';

import { ResponsiveImage } from 'components/extras/responsiveImage';

import styles from 'styles';

type HomeProps = {};

const useStyles = makeStyles<Theme, HomeProps>((theme) => ({
  ...styles(theme),
  jumbotron: {
    position: 'relative',
    height: 'calc(100vh - 64px)',
    minHeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  jumbotronHero: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'right',
    zIndex: -1,

    // Default color while image is loading
    backgroundColor: '#0095F9',
    backgroundImage: 'linear-gradient(180deg, #0095F9 0%, #48BEFC 50%, #00C7EE 75%)',
  },
  jumbotronCard: {
    maxWidth: 384,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 256,
    },

    background: 'rgba(249, 250, 253, 0.85)',
    borderRadius: theme.spacing(1),
  },
  jumbotronCardActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    marginTop: theme.spacing(2),
  },

  blockHeader: {
    fontSize: 36,
    fontWeight: 700,
    textAlign: 'center',

    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  block1: {
    backgroundColor: '#F9FAFD',
  },
  block2: {
    backgroundColor: '#EAF5FA',
  },
  blockDivider: {
    borderBottom: '2px solid #DFE1E5',
  },

  tileParent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    maxWidth: 1000,
    margin: 'auto',
  },
  tile: {
    padding: 32,
    flexBasis: '33%',

    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
    },
  },
  tileIcon: {
    width: 48,
    height: 48,
    marginBottom: theme.spacing(2),

    color: '#2196F3',

    display: 'block',
    margin: 'auto',
  },
  tileTitle: {
    fontSize: 28,
    fontWeight: 500,
    textAlign: 'center',

    paddingBottom: theme.spacing(2),
  },
  tileBody: {
    textAlign: 'justify',
  },

  distributorImageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

// Context created in order to dynamically load all responsive images
// Webpack unable to deduce what to package up when includes are dynamic
// Note: Anytime this context is used (e.g. ResponsiveImage), the path must be relative to this path,
// so ./profile-picture would actually be ../images/profile-picture
const imagesContext = require.context('../images', true, /^.*$/);

function Home(props: HomeProps) {
  const classes = useStyles(props);

  const masterID = useSelector((state) => state.app.masterID);
  const sites = useSelector((state) => state.app.sites);
  const user = useSelector((state) => state.user.user);
  const has2FA = useSelector((state) => state.user.has2FA);

  // User logged in but does not have 2FA enabled, redirect them to set it up
  if (user && !has2FA) {
    return <Redirect to="/auth/setup" />;
  } else if (user) {
    if (Object.keys(sites).length > 1) {
      return <Redirect to={`/app/site/${masterID}/dashboard`} />;
    } else {
      return <Redirect to="/app/no-sites" />;
    }
  }

  return (
    <Fragment>
      <div className={classes.jumbotron}>
        <ResponsiveImage
          context={imagesContext}
          src="./hero-%ww.%e"
          exts={['jpg', 'webp']}
          widths={[1920, 1440, 1080, 810, 608]}
          sizes="100vw"
          className={classes.jumbotronHero}
        />
        <Paper elevation={6} className={clsx(classes.paper, classes.m2, classes.p2, classes.jumbotronCard)}>
          <Typography className={clsx(classes.headingText4, classes.pb1)}>
            Welcome to FeedLink Live!
          </Typography>
          <Typography className={clsx(classes.bodyText1, classes.py1, classes.centerH3)}>
            If you’re looking for simple and secure access to your FeedLink controller, you’ve come
            to the right place!
          </Typography>
          <Typography className={clsx(classes.bodyText1, classes.py1, classes.centerH3)}>
            Sign up for an account to get started.
          </Typography>
          <Typography className={clsx(classes.bodyText1, classes.py1, classes.centerH3)}>
            Scroll down for more information.
          </Typography>
        </Paper>
      </div>

      <div className={clsx(classes.block1, classes.blockDivider)}>
        <Typography className={classes.blockHeader}>Features</Typography>
        <div className={classes.tileParent}>
          <div className={classes.tile}>
            <SvgIcon component={SmileIcon} viewBox="0 0 512 512" className={classes.tileIcon} />
            <Typography className={classes.tileTitle}>It&apos;s Easy</Typography>
            <Typography className={classes.tileBody}>
              PC, Mac, tablet, smartphone… it doesn’t matter! Use whichever you want. Use them all
              if you want!
            </Typography>
          </div>
          <div className={classes.tile}>
            <SvgIcon component={LockIcon} viewBox="0 0 512 512" className={classes.tileIcon} />
            <Typography className={classes.tileTitle}>Highly Secure</Typography>
            <Typography className={classes.tileBody}>
              We take internet security seriously. Your account is protected by 2 factor
              authentication and all messages are encrypted.
            </Typography>
          </div>
          <div className={classes.tile}>
            <SvgIcon component={HeartbeatIcon} viewBox="0 0 512 512" className={classes.tileIcon} />
            <Typography className={classes.tileTitle}>Monitor Status</Typography>
            <Typography className={classes.tileBody}>
              Real-time dashboards automatically updated with the latest readings and system info.
            </Typography>
          </div>
          <div className={classes.tile}>
            <SvgIcon component={EditIcon} viewBox="0 0 576 512" className={classes.tileIcon} />
            <Typography className={classes.tileTitle}>Make Changes</Typography>
            <Typography className={classes.tileBody}>
              Need to change a set point or an alarm point? No problem! (You will need your
              controller Access Code though)
            </Typography>
          </div>
          <div className={classes.tile}>
            <SvgIcon component={ReportIcon} viewBox="0 0 512 512" className={classes.tileIcon} />
            <Typography className={classes.tileTitle}>Watch Trends</Typography>
            <Typography className={classes.tileBody}>
              Review graphs that show how your equipment is performing over time
            </Typography>
          </div>
          <div className={classes.tile}>
            <SvgIcon component={NewsIcon} viewBox="0 0 512 512" className={classes.tileIcon} />
            <Typography className={classes.tileTitle}>Run Reports</Typography>
            <Typography className={classes.tileBody}>
              Reports summarize key performance metrics, such as readings, alarm patterns and
              parameter changes
            </Typography>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Home;
